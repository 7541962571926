import './style.scss';

interface Props {
  value?: any;
  onChange: any;
  children: JSX.Element | JSX.Element[];
  width?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
}

const Select = (props: Props) => {
  const { width, children, value, onChange, className = '', name, disabled } = props;

  const classNames = `select form-control cursor-pointer fw-normal ${className}`;

  return (
    <select className={classNames} value={value} onChange={onChange} disabled={disabled} style={{ width: width }} name={name}>
      {children}
    </select>
  );
};

export default Select;
