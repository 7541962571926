import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './style.scss';

interface Props {
  title: string;
  subtitle?: string;
  to?: string;
  textLink?: string;
  children?: any;
}
const HeaderContent = (props: Props) => {
  const { children, title, subtitle, textLink, to } = props;
  return (
    <div className="d-flex justify-content-between align-items-end mb-3">
      <div>
        <h3 className={`title fw-semibold mb-${subtitle ? '2' : '0'}`}>{title}</h3>
        <p className="sub-title fw-medium fs-6 lh-sm m-0">{subtitle}</p>

        {children}
      </div>

      {to && (
        <Link to={to} className="text-decoration-none d-flex align-items-center fw-medium link">
          <span className="me-3">{textLink}</span>
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      )}
    </div>
  );
};

export default HeaderContent;
