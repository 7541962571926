import {
  iAttributesOfAttributeSetIdItem,
  iConfigurableProductOptions,
  iCustomAttribute,
  iProductLink,
  iSearchOptimize,
} from '~/app/models';
import { addFilterGroup, addFilterItem, addFilters, searchCriteriaBuilder } from '~/app/utils';
import {
  getCrossSellSelected,
  getRelatedSelected,
  getUpSellSelected,
  onChangeDataAdvInventory,
  onChangeDataAdvPrice,
  onChangeDataAttrConfigSelected,
  onChangeDataValuesConfigSelected,
  onChangeSearchOptimize,
} from './redux/actions';

export function getListRelatedProduct(product_links: iProductLink[], dispatch: any, accessToken: string, currentStore: string) {
  const listSkuOfRelate = product_links.filter((item) => item.link_type === 'related').map((item) => item.linked_product_sku) || [];

  listSkuOfRelate.length > 0 &&
    dispatch(
      getRelatedSelected(
        searchCriteriaBuilder(listSkuOfRelate.length, 1, addFilterGroup(addFilters(addFilterItem('sku', listSkuOfRelate.join(','), 'in')))),
        accessToken,
        currentStore,
        product_links,
      ),
    );
}

export function getListUpSellProduct(product_links: iProductLink[], dispatch: any, accessToken: string, currentStore: string) {
  const listSkuOfUpSell = product_links.filter((item) => item.link_type === 'upsell').map((item) => item.linked_product_sku) || [];

  listSkuOfUpSell.length > 0 &&
    dispatch(
      getUpSellSelected(
        searchCriteriaBuilder(listSkuOfUpSell.length, 1, addFilterGroup(addFilters(addFilterItem('sku', listSkuOfUpSell.join(','), 'in')))),
        accessToken,
        currentStore,
        product_links,
      ),
    );
}

export function getListCrossSellProduct(product_links: iProductLink[], dispatch: any, accessToken: string, currentStore: string) {
  const listSkuOfCrossSell = product_links.filter((item) => item.link_type === 'crosssell').map((item) => item.linked_product_sku) || [];

  listSkuOfCrossSell.length > 0 &&
    dispatch(
      getCrossSellSelected(
        searchCriteriaBuilder(
          listSkuOfCrossSell.length,
          1,
          addFilterGroup(addFilters(addFilterItem('sku', listSkuOfCrossSell.join(','), 'in'))),
        ),
        accessToken,
        currentStore,
        product_links,
      ),
    );
}

export function handleCovertDataCustomAttr(
  dataRelatedAttrByAttrSet: iAttributesOfAttributeSetIdItem[],
  custom_attributes: iCustomAttribute[],
) {
  const listAttrName = [
    'status',
    'shipment_type',
    'page_layout',
    'gift_message_available',
    'options_container',
    'custom_layout_update_file',
    'custom_design',
    'custom_layout',
    'tax_class_id',
    'msrp_display_actual_price_type',
    'quantity_and_stock_status',
    'price_view',
    'visibility',
    'country_of_manufacture',
    'product_image_size',
    'product_page_type',
  ];

  const listAttrCode = dataRelatedAttrByAttrSet
    .filter((attr) => attr.frontend_input === 'select')
    .filter((attr) => !listAttrName.includes(attr.attribute_code))
    .map((attr) => attr.attribute_code);

  const listFilterAttrCateAndAttr = custom_attributes?.filter((item) => listAttrCode.includes(item.attribute_code));
  const listEntriesAttrCateAndAttr = listFilterAttrCateAndAttr?.map((item) => {
    const { attribute_code, value } = item;
    return [attribute_code, value || ''];
  });
  const newListAttrCateAndAttr = listEntriesAttrCateAndAttr ? Object.fromEntries(listEntriesAttrCateAndAttr) : {};
  return newListAttrCateAndAttr;
}

export function handleCovertDataAdvancedInventory(stock_item: any, dataAdvInventory: any, dispatch: any) {
  const {
    backorders,
    enable_qty_increments,
    is_decimal_divided,
    is_qty_decimal,
    manage_stock,
    max_sale_qty,
    min_qty,
    min_sale_qty,
    notify_stock_qty,
    qty_increments,
    use_config_backorders,
    use_config_enable_qty_inc,
    use_config_manage_stock,
    use_config_max_sale_qty,
    use_config_min_qty,
    use_config_min_sale_qty,
    use_config_notify_stock_qty,
    use_config_qty_increments,
  } = stock_item;

  dispatch(
    onChangeDataAdvInventory({
      ...dataAdvInventory,
      backorders,
      enable_qty_increments,
      is_decimal_divided,
      is_qty_decimal,
      manage_stock,
      max_sale_qty,
      min_qty,
      min_sale_qty,
      notify_stock_qty,
      qty_increments,
      use_config_backorders,
      use_config_enable_qty_inc,
      use_config_manage_stock,
      use_config_max_sale_qty,
      use_config_min_qty,
      use_config_min_sale_qty,
      use_config_notify_stock_qty,
      use_config_qty_increments,
    }),
  );
}

export function handleCovertDataAdvancedPrice(custom_attributes: iCustomAttribute[], dispatch: any) {
  const listName = ['special_price', 'cost', 'special_from_date', 'special_to_date'];
  const newList = custom_attributes
    ?.filter((item) => listName.includes(item.attribute_code))
    .map((item) => [item.attribute_code, item.value]);
  const newDataAdvPrice = Object.fromEntries(newList);
  const { special_price, cost, special_from_date, special_to_date } = newDataAdvPrice;

  const newSpecialPrice = special_price ? { special_price: (+special_price).toFixed(2) } : {};
  const newCost = cost ? { cost: (+cost).toFixed(2) } : {};
  const newSpecialFromDate = special_from_date ? { special_from_date } : {};
  const newSpecialToDate = special_to_date ? { special_to_date } : {};

  const payload = {
    ...newSpecialPrice,
    ...newCost,
    ...newSpecialFromDate,
    ...newSpecialToDate,
  };

  dispatch(onChangeDataAdvPrice(payload));
}

export function handleCovertDataSearchOptimize(dataSearchOptimize: iSearchOptimize, custom_attributes: iCustomAttribute[], dispatch: any) {
  const listName = ['meta_keyword', 'meta_title', 'url_key'];
  const newList = custom_attributes
    ?.filter((item) => listName.includes(item.attribute_code))
    .map((item) => [item.attribute_code, item.value]);
  const newDataSearchOptimize = Object.fromEntries(newList);
  dispatch(onChangeSearchOptimize({ ...dataSearchOptimize, ...newDataSearchOptimize }));
}

export function handleCovertConfigProductOption(
  dataRelatedAttrByAttrSet: iAttributesOfAttributeSetIdItem[],
  configurable_product_options: iConfigurableProductOptions[],
  dispatch: any,
) {
  const listLabelWithIdConfigOption = configurable_product_options.map((item) => `${item.label}-id${item.attribute_id}`);

  const listAttrContainConfigOption = dataRelatedAttrByAttrSet.filter((attr) =>
    listLabelWithIdConfigOption.includes(`${attr.default_frontend_label}-id${attr.attribute_id}`),
  );

  const newListAttrConfigOptionSelected = listAttrContainConfigOption.map((attr) => {
    const attrNameWithId = `${attr.default_frontend_label}-id${attr.attribute_id}`;
    const listValueConfigOption = configurable_product_options
      .find((item) => `${item.label}-id${item.attribute_id}` === attrNameWithId)
      ?.values.map((v) => `${v.value_index}`);
    const newListValue = attr.options.filter((item: any) => listValueConfigOption?.includes(item.value));

    return [attrNameWithId, newListValue];
  });

  dispatch(onChangeDataValuesConfigSelected(Object.fromEntries(newListAttrConfigOptionSelected)));
  dispatch(
    onChangeDataAttrConfigSelected(
      JSON.parse(JSON.stringify(listAttrContainConfigOption).replaceAll('attribute_id', 'id').replaceAll('default_frontend_label', 'name')),
    ),
  );

  return Object.fromEntries(newListAttrConfigOptionSelected);
}
