import { iPostAttachment, iPostMessage, iPostQuickAnswer, iSaveTicket } from '../models';
import axiosConfig from './axiosConfig';

const ticketApi = {
  getTicketListing: (searchURL: string, accessToken: string, store: string = 'all', controller: AbortController) => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_ticket/search?${searchURL}`;

    return axiosConfig.get(url, {
      signal: controller.signal,
    });
  },
  getAllTicket: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_ticket/search?${searchURL}`;
    return axiosConfig.get(url);
  },
  getQuickAnswer: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_quickanswer/search?${searchURL}`;
    return axiosConfig.get(url);
  },
  getTicketDetails: (ticketId: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_ticket/${ticketId}`;
    return axiosConfig.get(url);
  },
  getCategoryById: (categoryId: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_category/${categoryId}`;
    return axiosConfig.get(url);
  },
  getDepartmentById: (departmentId: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_department/${departmentId}`;
    return axiosConfig.get(url);
  },
  getMessageByTicketId: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_message/search?${searchURL}`;
    return axiosConfig.get(url);
  },
  getAttachmentOfMessageId: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/attachment/search?${searchURL}`;
    return axiosConfig.get(url);
  },

  postQuickAnswer: (payload: iPostQuickAnswer, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_quickanswer`;
    return axiosConfig.post(url, payload);
  },
  postMessage: (payload: iPostMessage, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_message`;
    return axiosConfig.post(url, payload);
  },
  postAttachmentOfMessageId: (payload: iPostAttachment, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/attachment`;
    return axiosConfig.post(url, payload);
  },

  putTicket: (ticketId: string, payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_ticket/save/${ticketId}`;
    return axiosConfig.put(url, payload);
  },

  saveTicket: (payload: iSaveTicket, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/lof-helpdesk/lof_helpdesk_ticket/save`;
    return axiosConfig.post(url, payload);
  },
};

export default ticketApi;
