import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import authReducer from '~/components/layout/FullWidthLayout/redux';
import orderReducer from '~/components/pages/Orders/redux';
import userReducer from '~/components/pages/Account/redux';
import productReducer from '~/components/pages/ProductsListing/redux';
import dashboardReducer from '~/components/pages/DashBoard/redux';
import createEditProductReducer from '~/components/pages/CreateEditProduct/redux';
import reportReducer from '~/components/pages/SalesReport/redux';
import ticketReducer from '~/components/pages/TicketListing/redux';

const reducer = combineReducers({
  authReducer,
  orderReducer,
  userReducer,
  productReducer,
  dashboardReducer,
  createEditProductReducer,
  reportReducer,
  ticketReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const persistor: any = persistStore(store);
