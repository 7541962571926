import qs from 'qs';
import { conditionType, directionType, filterItemInterface, filtersInterface, sortOrderItemInterface } from '../models';

export const addFilterItem = (field: string, value: string, condition_type: conditionType): filterItemInterface => ({
  field,
  value,
  condition_type,
});

export const addSortOrderItem = (field: string, direction?: directionType): sortOrderItemInterface => ({
  field,
  direction,
});

export const addFilters = (...filters: filterItemInterface[]): filtersInterface => {
  return {
    filters,
  };
};

export const addFilterGroup = (...filter_groups: filtersInterface[]) => filter_groups;

export const addSortOrder = (...sort_orders: sortOrderItemInterface[]) => sort_orders;

export const searchCriteriaBuilder = (
  pageSize: number,
  currentPage: number,
  filter_groups?: filtersInterface[],
  sort_orders?: sortOrderItemInterface[],
) =>
  qs.stringify(
    {
      searchCriteria: {
        filter_groups,
        sort_orders,
        pageSize,
        currentPage,
      },
    },
    { arrayFormat: 'indices' },
  );

export const reportSearchCriteria = <T>(payload: T) =>
  qs.stringify({
    searchCriteria: { ...payload },
  });

export const dashboardCriteria = <T>(payload: T) =>
  qs.stringify({
    criteria: { ...payload },
  });

export const exportCSV = <T>(payload: T) =>
  qs.stringify({
    ...payload,
  });
