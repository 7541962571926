import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { clearMessage, login } from '~/components/layout/FullWidthLayout/redux/actions';

import ButtonForm from '~/components/common/ButtonForm';
import FormWrapper from '~/components/common/FormWrapper';
import Loading from '~/components/common/Loading';
import FullWidthLayout from '~/components/layout/FullWidthLayout';
import LoginSchema from './Schema';

import './style.scss';
import { TextField } from '~/components/common/Input';

interface iValueForm {
  username: string;
  password: string;
}

const SignIn: FC = () => {
  const { status, message } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Remove message when reload page
    message && dispatch(clearMessage());
    resetForm();

    return () => {
      // Remove message when component unmounted
      message && dispatch(clearMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, resetForm } = useFormik<iValueForm>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(login(values, navigate));
    },
  });

  const renderListInput = () => {
    return (
      <>
        <TextField
          type="text"
          name="username"
          value={values['username']}
          label="Email"
          error={!!(errors['username'] && touched['username'])}
          textError={errors['username']}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setFieldTouched('username', false, false);
          }}
        />

        <TextField
          type="password"
          name="password"
          value={values['password']}
          label="Password"
          error={!!(errors['password'] && touched['password'])}
          textError={errors['password']}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setFieldTouched('password', false, false);
          }}
        />
      </>
    );
  };

  return (
    <FullWidthLayout pageTitle="Sign In">
      <form className="login d-flex flex-column align-items-center" onSubmit={handleSubmit}>
        <FormWrapper title="SIGN IN TO SELLER PORTAL">
          <h6 className="sub-title text-center fw-normal">
            <span>New here? </span>
            <Link to="/auth/sign-up" className="text-decoration-none link">
              Create new account
            </Link>
          </h6>

          {status === 'pending' ? (
            <div style={{ marginTop: '-16px' }}>
              <Loading />
            </div>
          ) : (
            <div className="input-list">{renderListInput()}</div>
          )}

          <>{status === 'rejected' && message && <p className="message m-0 mt-3 text-danger text-center">{message}</p>}</>

          <ButtonForm disabled={status === 'pending'}>CONTINUE</ButtonForm>

          <div className="text-center mt-3 lh-sm fs-6">
            <Link to="/auth/forgot-password" className="link fs-6 text-decoration-none d-inline-block fw-medium">
              Forgot Password?
            </Link>
          </div>
        </FormWrapper>
      </form>
    </FullWidthLayout>
  );
};

export default SignIn;
