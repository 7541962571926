import { createSlice } from '@reduxjs/toolkit';
import { Status, iInitState, iOrderDetails } from '~/app/models';
import { iAttachmentOfMessageId, iDataGetTicketListing, iInfoTicketDetails, iMessageOfTicketId, iQuickAnswer } from '~/app/models/ticket';

interface iState extends iInitState {
  statusGetTicketDetails: Status;
  statusGetMessageOfTicketId: Status;
  statusPostQuickAnswer: Status;
  statusPostMessage: Status;
  statusPutTicket: Status;
  statusSaveTicket: Status;
  queryString: string;
  controller: AbortController | null;
  qtyNewTicket: number | null;
  messagePostMessage: string;

  listQuickAnswer: iQuickAnswer[];

  dataTicketListing: iDataGetTicketListing;
  dataAllTicket: iDataGetTicketListing;
  dataOrderDetails: iOrderDetails;
  dataTicketDetails: iInfoTicketDetails;
  dataMessageOfTicketId: iMessageOfTicketId[];
  dataAttachment: iAttachmentOfMessageId[];
}

const initialState: iState = {
  status: '',
  statusGetTicketDetails: '',
  statusGetMessageOfTicketId: '',
  statusPostQuickAnswer: '',
  statusPostMessage: '',
  statusPutTicket: '',
  statusSaveTicket: '',

  queryString: '',
  controller: null,
  qtyNewTicket: null,
  messagePostMessage: '',

  listQuickAnswer: [],
  dataAllTicket: {} as iDataGetTicketListing,
  dataTicketListing: {} as iDataGetTicketListing,
  dataOrderDetails: {} as iOrderDetails,
  dataTicketDetails: {} as iInfoTicketDetails,
  dataMessageOfTicketId: [],
  dataAttachment: [],
};

const ticketSlice = createSlice({
  name: 'TICKET LIST',
  initialState,
  reducers: {
    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    // setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    // setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),

    // Asynchronous Actions
    getTicketListingFulfilled: (state, action) => ({ ...state, dataTicketListing: action.payload, status: 'fulfilled' }),
    getTicketListingRejected: (state) => ({ ...state, status: 'rejected' }),
    getTicketListingPending: (state) => ({ ...state, status: 'pending' }),

    getAllTicketFulfilled: (state, action) => ({ ...state, dataAllTicket: action.payload, status: 'fulfilled' }),
    getAllTicketRejected: (state) => ({ ...state, status: 'rejected' }),
    getAllTicketPending: (state) => ({ ...state, status: 'pending' }),

    resetQueryString: (state) => ({ ...state, queryString: '' }),

    getTicketDetailsFulfilled: (state, action) => ({
      ...state,
      statusGetTicketDetails: 'fulfilled',
      dataTicketDetails: { ...state.dataTicketDetails, ...action.payload },
    }),
    getTicketDetailsRejected: (state) => ({ ...state, statusGetTicketDetails: 'rejected' }),
    getTicketDetailsPending: (state) => ({ ...state, statusGetTicketDetails: 'pending' }),

    getListQuickAnswer: (state, action) => ({ ...state, listQuickAnswer: action.payload }),

    getOrderByIdFulfilled: (state, action) => ({ ...state, dataOrderDetails: action.payload }),

    getListMessageByTicketIdFulfilled: (state, action) => ({
      ...state,
      statusGetMessageOfTicketId: 'fulfilled',
      dataMessageOfTicketId: action.payload,
    }),
    getListMessageByTicketIdRejected: (state) => ({ ...state, statusGetMessageOfTicketId: 'rejected' }),
    getListMessageByTicketIdPending: (state) => ({ ...state, statusGetMessageOfTicketId: 'pending' }),
    getListAttachmentByMessageId: (state, action) => ({ ...state, dataAttachment: action.payload }),

    postQuickAnswerFulfilled: (state) => ({ ...state, statusPostQuickAnswer: 'fulfilled' }),
    postQuickAnswerRejected: (state) => ({ ...state, statusPostQuickAnswer: 'rejected' }),
    postQuickAnswerPending: (state) => ({ ...state, statusPostQuickAnswer: 'pending' }),

    postMessageFulfilled: (state) => ({ ...state, statusPostMessage: 'fulfilled' }),
    postMessageRejected: (state, action) => ({ ...state, statusPostMessage: 'rejected', messagePostMessage: action.payload }),
    postMessagePending: (state) => ({ ...state, statusPostMessage: 'pending' }),

    putTicketFulfilled: (state) => ({ ...state, statusPutTicket: 'fulfilled' }),
    putTicketRejected: (state) => ({ ...state, statusPutTicket: 'rejected' }),
    putTicketPending: (state) => ({ ...state, statusPutTicket: 'pending' }),

    saveTicketFulfilled: (state) => ({ ...state, statusSaveTicket: 'fulfilled' }),
    saveTicketRejected: (state) => ({ ...state, statusSaveTicket: 'rejected' }),
    saveTicketPending: (state) => ({ ...state, statusSaveTicket: 'pending' }),

    getQtyNewTicket: (state, action) => ({ ...state, qtyNewTicket: action.payload }),
  },
});

export const { ...rest } = ticketSlice.actions;
const ticketReducer = ticketSlice.reducer;

export default ticketReducer;
