import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks/hooks';
import './style.scss';

interface Props {
  children: JSX.Element[] | JSX.Element;
  pageTitle: string;
}

const FullWidthLayout: FC<Props> = (props: Props) => {
  const { children, pageTitle } = props;

  const { isLogin, lastPathName } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    document.title = `${pageTitle} - ${process.env.REACT_APP_PRE_PAGE_TITLE}`;
  }, [pageTitle]);

  return isLogin ? (
    <Navigate to={lastPathName ? lastPathName : '/'} replace />
  ) : (
    <div className="main-content min-vh-100 d-flex flex-column justify-content-center align-items-center">{children}</div>
  );
};

export default FullWidthLayout;
