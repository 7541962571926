import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';

import './style.scss';

export interface iOverviewData {
  title: string;
  subtitle: string;
  icon: IconDefinition;
  qty: number;
  bgc: string;
  status?: string;
  moveIconTop?: boolean;
  path: string;
}

const InfoTag = (props: iOverviewData) => {
  const { title, subtitle, qty, icon, bgc, status, moveIconTop, path } = props;

  return (
    <div className="overview-item px-4 text-white d-flex flex-column h-100" style={{ backgroundColor: bgc || '#000' }}>
      <div className="d-flex justify-content-between align-items-end">
        <h3 className="qty fw-semibold m-0">{status !== 'pending' ? qty : <Skeleton width={100} height={32} />}</h3>
        <div className={`icon ${moveIconTop ? 'move-top' : ''}`}>
          {status !== 'pending' ? <FontAwesomeIcon icon={icon} /> : <Skeleton width={40} height={40} />}
        </div>
      </div>
      <p className="sub-title fw-medium m-0">{status !== 'pending' ? subtitle : <Skeleton width={'45%'} height={16} />}</p>
      <Link to={path} className="title fw-semibold m-0">
        {status !== 'pending' ? title : <Skeleton width={'70%'} height={16} />}
      </Link>
    </div>
  );
};

export default InfoTag;
