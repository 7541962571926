import DefaultLayout from '~/components/layout/DefaultLayout';

import './style.scss';
import { NoRecord } from '../../Err';
import HelpDesk from './HelpDesk';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { getTicketDetails, getListQuickAnswer, resetData, getMessageByTicketId, putTicket, saveTicket } from '../redux/action';
import {
  addFilterGroup,
  addFilterItem,
  addFilters,
  addSortOrder,
  capitalizeFirstLetter,
  formatMoney,
  searchCriteriaBuilder,
} from '~/app/utils';
import { Link, useParams } from 'react-router-dom';

import Loading from '~/components/common/Loading';
import Swal from 'sweetalert2';
import { defaultMessageError, timeConvertNotShowHour } from '~/app/constants';
import Select from '~/components/common/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '~/components/common/Button';

const TicketDetails = () => {
  const dispatch = useAppDispatch();
  const {
    dataTicketDetails,
    statusGetTicketDetails,
    statusPostQuickAnswer,
    dataOrderDetails,
    statusPostMessage,
    messagePostMessage,
    statusPutTicket,
    queryString,
  } = useAppSelector((s) => s.ticketReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { increment_id, created_at, grand_total, state: statusOfOrder, items: listProductOrdered, state: stateOfOrder } = dataOrderDetails;
  const { customer_email, customer_name, category_name, department_name, status_id, is_read, product_id, ticket_id } = dataTicketDetails;

  const [statusOfTicket, setStatusOfTicket] = useState<string>('1');
  const listProductOrderedWithoutConfigurable = useMemo(() => {
    return listProductOrdered?.length
      ? listProductOrdered
          .filter((item: any) => item.product_type !== 'configurable')
          .filter((item) => {
            return product_id.includes(item.product_id.toString());
          })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProductOrdered]);

  const { ticketId = '0' } = useParams();

  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });

  useEffect(() => {
    if (is_read === '0') {
      const ticketPayload = {
        Ticket: {
          ticket_id: ticketId,
          is_read: '1',
        },
      };
      dispatch(saveTicket(ticketPayload, accessToken, currentStore, storeData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_read]);

  useEffect(() => {
    dispatch(
      getListQuickAnswer(
        searchCriteriaBuilder(Number.MAX_VALUE, 1, undefined, addSortOrder({ field: 'quickanswer_id', direction: 'DESC' })),
        accessToken,
        currentStore,
      ),
    );
    dispatch(getTicketDetails(ticketId, accessToken, currentStore));

    // Get message by id
    dispatch(
      getMessageByTicketId(
        searchCriteriaBuilder(Number.MAX_VALUE, 1, addFilterGroup(addFilters(addFilterItem('ticket_id', ticketId, 'eq')))),
        accessToken,
        currentStore,
      ),
    );

    return () => dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (status_id) setStatusOfTicket(status_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status_id]);

  useEffect(() => {
    if (statusPostQuickAnswer === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
      dispatch(getListQuickAnswer(searchCriteriaBuilder(Number.MAX_VALUE, 1), accessToken, currentStore));
    } else if (statusPostQuickAnswer === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPostQuickAnswer]);

  useEffect(() => {
    if (statusPostMessage === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (statusPostMessage === 'rejected') {
      swalToast.fire({
        title: messagePostMessage,
        icon: 'error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPostMessage]);

  useEffect(() => {
    if (statusPutTicket === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
      dispatch(getListQuickAnswer(searchCriteriaBuilder(Number.MAX_VALUE, 1), accessToken, currentStore));
    } else if (statusPutTicket === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPutTicket]);

  function handleChangeStatus(e: React.ChangeEvent<HTMLSelectElement>) {
    setStatusOfTicket(e.target.value);
  }

  function handleUpdateTicket() {
    if (statusOfTicket === status_id) return;

    const payload = {
      Ticket: {
        ticket_id: ticketId,
        status_id: statusOfTicket,
      },
    };
    dispatch(putTicket(ticketId, payload, accessToken, currentStore));
  }

  function renderInfo() {
    return (
      <div className="info wrapper d-flex flex-column bg-white px-4 py-3 gap-4">
        <div className="info-heading d-flex flex-column gap-2">
          <h3 className="mb-0 fs-6 fw-medium">TICKET ID #{ticket_id}</h3>
          {!!Object.keys(dataOrderDetails).length && (
            <>
              <h3 className="mb-0 fs-6 fw-medium">ORDER #{increment_id}</h3>
              <h4 className="mb-0 fs-14 text-dark-600 fw-normal">
                at {timeConvertNotShowHour(created_at)} ({formatMoney(grand_total, currentStore)}) -{' '}
                {capitalizeFirstLetter(statusOfOrder || stateOfOrder || '')}
              </h4>
            </>
          )}
        </div>

        <div className="info-body fs-14">
          <p className="m-0 fw-medium">Customer Email</p>
          <p className="m-0">{customer_email}</p>

          <p className="m-0 fw-medium">Customer Name</p>
          <p className="m-0 text-capitalize">{customer_name}</p>

          <p className="m-0 fw-medium">Category</p>
          <p className="m-0">{category_name}</p>

          {/* <p className="m-0 fw-medium">QTY Requested</p>
          <p className="m-0">{numeral(qty_requested).format('0,0.00')}</p>

          <p className="m-0 fw-medium">QTY Returned</p>
          <p className="m-0">{numeral(qty_returned).format('0,0.00')}</p> */}

          <p className="m-0 fw-medium">Department</p>
          <p className="m-0">{department_name}</p>

          <p className="m-0 fw-medium">Status</p>
          <div className="d-flex align-items-center gap-3">
            <Select className="status-select" onChange={handleChangeStatus} value={statusOfTicket || 1} width="fit-content">
              <option value="1">Open</option>
              <option value="0">Close</option>
              <option value="2">Processing</option>
              <option value="3">Done</option>
            </Select>

            <Button
              className="update-ticket d-flex align-items-center gap-2 fs-14"
              onClick={handleUpdateTicket}
              disabled={statusOfTicket === status_id || statusPutTicket === 'pending'}
            >
              {statusPutTicket === 'pending' ? (
                <>
                  Updating
                  <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
                </>
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function renderProductListing() {
    return (
      <div className="product-wrap wrapper bg-white px-4 py-3 mt-4 flex-grow-1 d-flex flex-column overflow-auto">
        <div className="product-heading">
          <h3 className="mb-4 fs-6 fw-medium">PRODUCT LISTING</h3>
        </div>

        <div className="product-list flex-grow-1">
          {listProductOrderedWithoutConfigurable.length ? (
            listProductOrderedWithoutConfigurable.map((item, index: number) => {
              const itemCheck = item.parent_item ? item.parent_item : item;

              const { name, sku, qty_ordered } = itemCheck;

              return (
                <div className="product-item d-flex flex-column gap-2 fs-14 py-3" key={index}>
                  <p className="m-0" dangerouslySetInnerHTML={{ __html: `${name} - SKU: ${sku}` }} />
                  <p className="m-0 text-dark-600 fst-italic fs-13px">Ordered QTY: {qty_ordered} items</p>
                </div>
              );
            })
          ) : (
            <table className="w-100">
              <tbody>
                <NoRecord tableHeaders={[]} />
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  function renderContent() {
    if (statusGetTicketDetails === 'pending') {
      return <Loading />;
    }

    if (statusGetTicketDetails === 'rejected') {
      return <p className="m-0 text-dark-600 text-center">The ticket that was requested doesn't exist. Verify the ticket and try again.</p>;
    }

    return (
      <div className="row h-100">
        <div className="col-12 col-xl-5 d-flex flex-column h-100">
          {renderInfo()}
          {renderProductListing()}
        </div>

        <div className="col-12 col-xl-7 mt-4 mt-xl-0 h-100">
          <HelpDesk />
        </div>
      </div>
    );
  }

  return (
    <DefaultLayout pageTitle="Ticket Details">
      <Link to={`/tickets/${queryString}`} className="text-decoration-none fw-medium link">
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="ms-3">Back To Ticket Listing</span>
      </Link>
      <div className="ticket-detail mt-4">{renderContent()}</div>
    </DefaultLayout>
  );
};

export default TicketDetails;
