import chartImg from '~/app/images/chart.png';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';

import './style.scss';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { useEffect, useMemo } from 'react';
import { getDataTotalSales } from './redux/actions';
import { formatMoney } from '~/app/utils';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const TotalSales = (props: Props) => {
  const { children } = props;

  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { dataTotalSales, statusTotalSales: status } = useAppSelector((s) => s.dashboardReducer);
  const { lifetime, average } = dataTotalSales;

  const totalSalesData = useMemo(
    () => [
      {
        title: 'Lifetime Sales',
        price: +lifetime || 0,
      },
      {
        title: 'Average Order',
        price: +average || 0,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lifetime, average],
  );

  useEffect(() => {
    dispatch(getDataTotalSales({ accessToken, currentStore }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  const renderData = () =>
    totalSalesData.map((item, index) => {
      const { title, price } = item;
      return (
        <div key={index} className="total-sales__item d-flex justify-content-between w-100">
          <div className="d-flex flex-column flex-grow-1">
            <h3 className="price text-primary fw-semibold">
              {status !== 'pending' ? formatMoney(+price, currentStore) : <Skeleton width={'70%'} height={32} className="price" />}
            </h3>
            <h4 className="m-0 title fw-medium">{status !== 'pending' ? title : <Skeleton width={'50%'} height={22} />}</h4>
          </div>
          {status !== 'pending' ? <img src={chartImg} alt="chart" className="chart-img" /> : <Skeleton width={'100%'} height={'100%'} />}
        </div>
      );
    });

  return (
    <div className="total-sales">
      {children}
      <div className="total-sales__list">{renderData()}</div>
    </div>
  );
};

export default TotalSales;
