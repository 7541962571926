import { ReactNode } from 'react';
import './style.scss';

interface Props {
  children: ReactNode;
}

const PopupWrap = ({ children }: Props) => {
  return (
    <div className="overlay d-flex align-items-center justify-content-center">
      <div className="content-wrap">{children}</div>
    </div>
  );
};

export default PopupWrap;
