import dashboardApi from '~/app/apis/dashboard.api';
import { iBestSellerApi, iPayloadOverviewDashboard, iProductSoldDashBoard } from '~/app/models/dashboard';
import * as types from '.';

const { rest: actions } = types;

export const getDataOverview = (payload: iPayloadOverviewDashboard) => async (dispatch: any) => {
  dispatch(actions.getDataOverviewPending());
  try {
    const res = await dashboardApi.getOverviewDashboard(payload);
    const { data } = res;
    dispatch(actions.getDataOverviewFulfilled(data));
  } catch (error) {
    dispatch(actions.getDataOverviewRejected());
  }
};

export const getDataTotalSales = (payload: iBestSellerApi) => async (dispatch: any) => {
  dispatch(actions.getDataTotalSalesPending());
  try {
    const res = await dashboardApi.getTotalSales(payload);
    const data = res.data;

    dispatch(actions.getDataTotalSalesFulfilled(data[0]));
  } catch (error) {
    dispatch(actions.getDataTotalSalesRejected());
  }
};

export const getDataSalesStatistics = (payload: iProductSoldDashBoard) => async (dispatch: any) => {
  dispatch(actions.getDataSalesStatisticsPending());
  try {
    const res = await dashboardApi.getSalesStatics(payload);
    res.data
      ? dispatch(actions.getDataSalesStatisticsFulfilled(res.data))
      : dispatch(actions.getDataSalesStatisticsRejected());
  } catch (error) {
    dispatch(actions.getDataSalesStatisticsRejected());
  }
};

export const getDataOrderReport = (payload: iProductSoldDashBoard) => async (dispatch: any) => {
  dispatch(actions.getDataOrderReportPending());
  try {
    const res = await dashboardApi.getOrderDashBoard(payload);
    const { data } = res;
    data ? dispatch(actions.getDataOrderReportFulfilled(data)) : dispatch(actions.getDataOrderReportRejected());
  } catch (error) {
    dispatch(actions.getDataOrderReportRejected());
  }
};

export const getDataBestSeller = (payload: iBestSellerApi) => async (dispatch: any) => {
  dispatch(actions.getBestSellesPending());

  try {
    const res = await dashboardApi.getBestSeller(payload);
    const { data } = res;
    data ? dispatch(actions.getBestSellesFulfilled(data)) : dispatch(actions.getBestSellesRejected());
  } catch (error) {
    dispatch(actions.getBestSellesRejected());
  }
};

export const getProductSold = (payload: iProductSoldDashBoard) => async (dispatch: any) => {
  dispatch(actions.getDataProductSoldPending());

  try {
    const res = await dashboardApi.getProductSold(payload);
    const { data } = res;

    data ? dispatch(actions.getDataProductSoldFulfilled(data)) : dispatch(actions.getDataProductSoldRejected());
  } catch (error) {
    dispatch(actions.getDataProductSoldRejected());
  }
};
