import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { convertStatus, dataInvoicedReportHead, keysName, reportTimeConvert, STATUS } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iQueries, iReport } from '~/app/models/report';
import { filterEmptyProperties, formatMoney } from '~/app/utils';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';
import { getInvoiceReport, reportReset, setQueries } from '../redux/actions';
import filterSchema from '../schema';
import { reportSearchCriteria } from '~/app/utils';
import moment from 'moment';
import { ResetFilterButton } from '~/components/common/Filter/filterInput';
import Select from '~/components/common/Select';
import { Input } from '~/components/common/Input';

interface props {}
interface iInitialValues {
  period: string;
  from: string;
  to: string;
  status: string;
}

export const InvoicedReport: React.FC<props> = (props: props) => {
  const dispatch = useAppDispatch();
  const type = 'invoice';
  const [isEnableFilterBoard, setIsEnableFilterBoard] = useState<boolean>(false);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);

  const { status: invoiceStatus, invoices, invoiceQueries } = useAppSelector((s) => s.reportReducer);
  const { items, total_count, total } = invoices;

  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const from = searchParams.get('from') || '';
  const to = searchParams.get('to') || '';
  const status = searchParams.get('status') || '';
  const isFilter = searchParams.get('isFilter') || '';
  const period = searchParams.get('period') || '';

  const initialValues: iInitialValues = {
    period,
    from,
    to,
    status,
  };

  const { values, handleChange, resetForm, handleSubmit, setFieldValue, errors } = useFormik({
    initialValues,
    validationSchema: filterSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const filteredObject = filterEmptyProperties(values);
      setSearchParams({
        ...filteredObject,
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        isFilter: 'true',
      });
      resetUrlData();
    },
  });

  // Change Page Size
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const data = {
      from,
      to,
      period,
      status,
    };
    const filteredObject = filterEmptyProperties(data);
    setSearchParams({ ...filteredObject, currentPage: '1', isFilter: (!!Object.keys(filteredObject).length).toString(), pageSize: value });
    resetUrlData();
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    const data = {
      from,
      to,
      period,
      status,
    };
    const filteredObject = filterEmptyProperties(data);
    setSearchParams({
      ...filteredObject,
      pageSize: pageSize.toString(),
      currentPage: currentPage.toString(),
      isFilter: 'true',
    });
    resetUrlData();
  };

  const resetUrlData = (): void => {
    const { search } = window.location;
    const payload: iQueries = {
      type,
      query: search,
    };

    dispatch(setQueries(payload));
  };

  const fetchData = () => {
    const data = {
      from,
      to,
      period,
      status,
    };
    const filteredObject = filterEmptyProperties(data);

    if (!Object.keys(filteredObject).length) {
      return;
    }

    const payload: iReport = {
      accessToken,
      currentStore,
      searchUrl: reportSearchCriteria({
        pageSize: +pageSize,
        currentPage: +currentPage,
        ...filteredObject,
      }),
    };
    dispatch(getInvoiceReport(payload));
  };

  useEffect(() => {
    if (isFilter) {
      resetUrlData();
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, invoiceQueries]);

  useEffect(() => {
    if (Object.keys(filterEmptyProperties(values)).length) {
      setSearchParams({
        ...values,
        isFilter: 'true',
        pageSize: pageSize.toString(),
        currentPage: currentPage.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(filterEmptyProperties(values)).length === 0) {
      dispatch(reportReset(type));
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const removeFilterSection = (key: string) => {
    if (['from', 'to'].includes(key)) {
      setFieldValue('from', '');
      setFieldValue('to', '');
      setSearchParams({
        ...values,
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        from: '',
        to: '',
        isFilter: 'true',
      });
    } else {
      setFieldValue(key, '');
      setSearchParams({ ...values, currentPage: currentPage.toString(), pageSize: pageSize.toString(), [key]: '', isFilter: 'true' });
    }
    resetUrlData();
  };

  const resetFilterSection = () => {
    !isEnableFilterBoard && setIsEnableFilterBoard(true);
    resetForm();
    setSearchParams({});
    setFieldValue('from', '');
    setFieldValue('to', '');
    setFieldValue('period', '');
    setFieldValue('status', '');
    dispatch(reportReset(type));
  };

  const convertReportStatus = (key: string) => {
    if (!key) return;
    return convertStatus[key];
  };

  const renderResetFilter = () => {
    return (
      <div className="text-end w-100 my-4 mt-xl-0">
        {invoiceStatus !== 'pending' && (
          <span className="me-3 ms-xl-4 fw-medium border-0 fs-6 w-70 fst-italic">Total {total_count} Record(s) Found</span>
        )}
        <span
          className="filters--button btn btn-primary apply text-white fw-medium border-0 fs-6 py-2 px-4"
          onClick={() => setIsEnableFilterBoard(!isEnableFilterBoard)}
        >
          Filters
        </span>
      </div>
    );
  };

  const renderFilterSelected = () => {
    const data = {
      period,
      from,
      to,
      status,
    };
    const filteredObject: any = filterEmptyProperties(data);
    const filterData: any = Object.keys(filteredObject);
    if (!filterData.length) {
      return null;
    }

    return (
      <div className="col-12 mb-5">
        <div className="d-flex align-items-center justify-content-between">
          <span className="p-3 fs-6 fw-medium">Active Filters</span>
          <ResetFilterButton onClick={() => resetFilterSection()} />
        </div>
        <div className="border-top border-bottom p-3 align-items-center">
          {filterData.map((key: string, i: number) => {
            return (
              <div className="filter-active justify-content-center align-items-center" key={i}>
                {key === 'from' && from && to && (
                  <>
                    <span className="fw-medium me-1">Filter Date:</span>
                    <span className="fw-light">
                      {moment(from).format('MM/DD/YYYY')} - {moment(to).format('MM/DD/YYYY')}
                    </span>
                    <FontAwesomeIcon
                      className="ms-2 btn btn-gray reset-filter-active fs-7 rounded-circle"
                      icon={faXmark}
                      onClick={() => removeFilterSection(key)}
                    />
                  </>
                )}

                {key === 'status' && status && (
                  <>
                    <span className="fw-medium me-1">{keysName[key]}:</span>
                    <span className="fw-light">{convertReportStatus(status)}</span>
                    <FontAwesomeIcon
                      className="ms-2 btn btn-gray reset-filter-active fs-7 rounded-circle"
                      icon={faXmark}
                      onClick={() => removeFilterSection(key)}
                    />
                  </>
                )}
                {key === 'period' && period && (
                  <div>
                    <span className="fw-medium me-1">{keysName[key]}:</span>
                    <span className="fw-light text-capitalize">{period}</span>
                    <FontAwesomeIcon
                      className="ms-2 btn btn-gray reset-filter-active fs-7 rounded-circle"
                      icon={faXmark}
                      onClick={() => removeFilterSection(key)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFilterBoard = () => {
    return (
      <div className={`bg-white filter-board${isEnableFilterBoard ? '' : ' d-none'} p-4 wrapper mb-5`}>
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark">Filters</h5>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-6">
                Period
              </label>
              <div className="col-lg-12 d-flex align-items-center">
                <Select name="period" className="w-100" onChange={handleChange} value={values.period}>
                  <option value="day">Day</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </Select>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-6">
                Start Date<span className="fw-semibold text-danger">*</span>
              </label>
              <Input type="date" className="w-100" name="from" value={values.from} onChange={handleChange} />
              <span className="fs-7 text-danger">{errors?.from}</span>
            </div>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-6">
                End Date<span className="fw-semibold text-danger">*</span>
              </label>
              <Input type="date" className="w-100" name="to" value={values.to} onChange={handleChange} />
              <span className="fs-7 text-danger">{errors?.to}</span>
            </div>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-6">
                Status
              </label>
              <div className="col-lg-12 d-flex align-items-center">
                <Select name="status" className="w-100" onChange={handleChange} value={values.status}>
                  {STATUS.map((stt, i: number) => (
                    <option value={stt.value} key={i}>
                      {stt.title}
                    </option>
                  ))}
                </Select>
              </div>
              <span className="fs-7 text-dark-600 fw-medium mt-2">
                Applies to Any of the Specified Order Statuses except canceled orders
              </span>
            </div>
          </div>
          <div className="action mb-0 text-end">
            <span className="btn-cancel me-3 cursor-pointer" onClick={() => setIsEnableFilterBoard(false)}>
              Cancel
            </span>
            <button
              type="button"
              className="btn-apply fw-semibold text-white btn btn-primary px-4 py-2 fw-medium border-0 ms-2 fs-6"
              onClick={() => handleSubmit()}
              disabled={invoiceStatus === 'pending'}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        className="bg-white order-table wrapper overflow-hidden"
        status={invoiceStatus || 'rejected'}
        dataTableHead={dataInvoicedReportHead}
      >
        <>
          {items?.length ? (
            items.map((item: any, i: number) => (
              <tr key={i}>
                <td className="td-item px-2 py-3 text-end white-space-pre w-10">{reportTimeConvert(item.period, period)}</td>
                <td className="td-item px-2 py-3 text-end">{item.orders_count}</td>
                <td className="td-item px-2 py-3 text-end">{Math.ceil(+item.orders_invoiced)}</td>
                <td className="td-item px-2 py-3 text-end">{formatMoney(+item.invoiced, currentStore)}</td>
                <td className="td-item px-2 py-3 text-end">{formatMoney(+item.invoiced_captured, currentStore)}</td>
                <td className="td-item px-2 py-3 text-end">{formatMoney(+item.invoiced_not_captured, currentStore)}</td>
              </tr>
            ))
          ) : (
            <NoRecord tableHeaders={dataInvoicedReportHead} />
          )}
          {items?.length ? (
            <tr>
              <td className="td-item bg-total fw-medium px-2 py-3 text-end w-10">TOTAL</td>
              <td className="td-item bg-total fw-medium px-2 py-3 text-end">{total[0].orders_count}</td>
              <td className="td-item bg-total fw-medium px-2 py-3 text-end">{total[0].orders_invoiced}</td>
              <td className="td-item bg-total fw-medium px-2 py-3 text-end">{formatMoney(+total[0].invoiced, currentStore)}</td>
              <td className="td-item bg-total fw-medium px-2 py-3 text-end">{formatMoney(+total[0].invoiced_captured, currentStore)}</td>
              <td className="td-item bg-total fw-medium px-2 py-3 text-end">
                {formatMoney(+total[0].invoiced_not_captured, currentStore)}
              </td>
            </tr>
          ) : (
            ''
          )}
        </>
      </Table>
    );
  };

  return (
    <>
      {renderResetFilter()}
      {renderFilterBoard()}
      {isFilter && renderFilterSelected()}
      {renderTable()}
      <Pagination
        currentPage={+currentPage}
        pageSize={+pageSize}
        status={invoiceStatus}
        totalCount={total_count}
        onChangePageEvent={onChangePage}
        onChangePageSizeEvent={onChangePageSizeEvent}
      />
    </>
  );
};
