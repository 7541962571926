import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { Status, iInitState } from '~/app/models';
interface iState extends iInitState {
  accessToken: string;
  message: string;
  isLogin: boolean;
  lastPathName: string;
  currentStore: string;
  websiteCurrency: string;
  stores: any[];
  storeStatus: Status;
  currentStoreName: string;
}

const initialState: iState = {
  status: '',
  storeStatus: '',
  accessToken: '',
  message: '',
  lastPathName: '',
  isLogin: false,
  currentStore: '',
  websiteCurrency: '',
  stores: [],
  currentStoreName: '',
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeWebCurrency: (state, action) => {
      state.websiteCurrency = action.payload;
    },
    changeStore: (state, action) => {
      state.currentStore = action.payload;
    },
    changeStoreName: (state, action) => ({ ...state, currentStoreName: action.payload }),
    changeAccessToken: (state, action) => ({ ...state, accessToken: action.payload }),
    loginPending: (state: any) => {
      state.status = 'pending';
    },
    loginRejected: (state: any, { payload }) => {
      state.status = 'rejected';
      state.message = payload;
    },
    loginFulfilled: (state: any, { payload }) => {
      // state.isLogin = true;
      state.status = 'fulfilled';
      state.accessToken = payload;
    },

    loginForceAllowed: (state) => {
      state.isLogin = true;
    },

    verifyTokenRejected: (state: any) => {
      state.accessToken = '';
      state.isLogin = false;
      state.status = '';
    },
    verifyTokenFulfilled: (state: any, { payload }) => {
      state.isLogin = true;
      state.accessToken = payload;
    },

    resetPwdPending: (state) => {
      state.status = 'pending';
    },
    resetPwdRejected: (state, { payload }) => {
      state.status = 'rejected';
      state.message = payload;
    },
    resetPwdFulfilled: (state, { payload }) => {
      state.status = 'fulfilled';
      state.message = payload;
    },

    checkEmailPending: (state) => {
      state.status = 'pending';
    },
    checkEmailRejected: (state, { payload }) => {
      state.status = 'rejected';
      state.message = payload;
    },
    checkEmailFulfilled: (state, { payload }) => {
      state.status = 'fulfilled';
      state.message = payload;
    },

    createUserPending: (state) => {
      state.status = 'pending';
    },
    createUserRejected: (state, { payload }) => {
      state.status = 'rejected';
      state.message = payload;
    },
    createUserFulfilled: (state) => {
      state.status = 'fulfilled';
    },

    deleteMessage: (state: any) => {
      state.message = '';
      state.status = '';
    },

    logout: (state) => {
      state.isLogin = false;
      state.accessToken = '';
      state.status = '';
    },
    changeLastPathName: (state, { payload }) => {
      state.lastPathName = payload;
    },

    fetchStoresRejected: (state) => ({ ...state, storeStatus: 'rejected' }),
    fetchStoresFulfilled: (state, action) => ({ ...state, storeStatus: 'fulfilled', stores: action.payload }),
    fetchStoresPending: (state) => ({ ...state, storeStatus: 'pending' }),

    resetPathname: (state) => ({ ...state, lastPathName: '' }),
  },
});

export const { ...rest } = authReducer.actions;

const pReducer = persistReducer(
  { storage, key: 'auth', whitelist: ['isLogin', 'accessToken', 'lastPathName', 'currentStore', 'currentStoreName'] },
  authReducer.reducer,
);

export default pReducer;
