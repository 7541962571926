import userApi from '~/app/apis/user.api';
import * as types from './index';
import { tokenExpired } from '~/components/layout/FullWidthLayout/redux/actions';

const { rest } = types;

export const getUser = (payload: any) => async (dispatch: any) => {
  dispatch(rest.getUserPending());

  try {
    const res = await userApi.getInfo(payload);
    const { data } = res;

    !data ? dispatch(rest.getUserRejected('Wrong Params')) : dispatch(rest.getUserFulfilled(data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      dispatch(tokenExpired());
    }

    const { message } = error?.response?.data || {};
    dispatch(rest.getUserRejected(message || 'Something went wrong. Please wait and try again.'));
  }
};

export const updateUser = (payload: any, accessToken: string) => async (dispatch: any) => {
  dispatch(rest.updateUserPending());

  try {
    const res = await userApi.updateInfo(payload, accessToken);
    const { data } = res;
    if (data) {
      dispatch(rest.updateUserFulfilled());
      // New data user
      dispatch(rest.getUserFulfilled(data));
    } else {
      dispatch(rest.updateUserRejected('Wrong Params'));
    }
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(rest.updateUserRejected(message || 'Something went wrong. Please wait and try again.'));
  } finally {
    setTimeout(() => {
      dispatch(rest.updateUserInitState());
    }, 3000);
  }
};

export const updateAvatarAndBanner =
  (
    payload: any,
    accessToken: string,
    setNewAvatar: React.Dispatch<React.SetStateAction<string>>,
    setNewBanner: React.Dispatch<React.SetStateAction<string>>,
  ) =>
  async (dispatch: any) => {
    dispatch(rest.updateUserAvatarPending());

    try {
      const res = await userApi.updateInfo(payload, accessToken);
      const { data } = res;

      // Reset image and banner
      setNewAvatar('');
      setNewBanner('');

      if (data) {
        dispatch(rest.updateUserAvatarFulfilled());
        // New data user
        dispatch(rest.getUserFulfilled(data));
      } else {
        dispatch(rest.updateUserAvatarRejected('Wrong Params'));
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(rest.updateUserAvatarRejected(message || 'Something went wrong. Please wait and try again.'));
    } finally {
      // 3000 ms = time popup show
      setTimeout(() => {
        dispatch(rest.updateUserAvatarInitState());
      }, 3000);
    }
  };

export const deleteAvatar =
  (payload: any, accessToken: string, setNewAvatar: React.Dispatch<React.SetStateAction<string>>) => async (dispatch: any) => {
    dispatch(rest.deleteAvatarPending());

    try {
      const res = await userApi.updateInfo(payload, accessToken);
      const { data } = res;

      // Reset new avatar
      setNewAvatar('');

      if (data) {
        dispatch(rest.deleteAvatarFulfilled());
        // New data user
        dispatch(rest.getUserFulfilled(data));
      } else {
        dispatch(rest.deleteAvatarRejected('Wrong Params'));
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(rest.deleteAvatarRejected(message || 'Something went wrong. Please wait and try again.'));
    } finally {
      // 3000 ms = time popup show
      setTimeout(() => {
        dispatch(rest.deleteAvatarInitState());
      }, 3000);
    }
  };

export const deleteBanner =
  (payload: any, accessToken: string, setNewBanner: React.Dispatch<React.SetStateAction<string>>) => async (dispatch: any) => {
    dispatch(rest.deleteBannerPending());

    try {
      const res = await userApi.updateInfo(payload, accessToken);
      const { data } = res;

      // Reset new banner
      setNewBanner('');

      if (data) {
        dispatch(rest.deleteBannerFulfilled());
        // New data user
        dispatch(rest.getUserFulfilled(data));
      } else {
        dispatch(rest.deleteBannerRejected('Wrong Params'));
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(rest.deleteBannerRejected(message || 'Something went wrong. Please wait and try again.'));
    } finally {
      // 3000 ms = time popup show
      setTimeout(() => {
        dispatch(rest.deleteBannerInitState());
      }, 3000);
    }
  };

export const updatePasswordUser = (payload: any, accessToken: string) => async (dispatch: any) => {
  dispatch(rest.updateUserPasswordPending());

  try {
    const res = await userApi.updateInfo(payload, accessToken);
    const { data } = res;
    !data ? dispatch(rest.updateUserPasswordRejected('Wrong Params')) : dispatch(rest.updateUserPasswordFulfilled());
    setTimeout(() => {
      dispatch(rest.updateUserPasswordInitState());
    }, 3000);
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(rest.updateUserPasswordRejected(message || 'Something went wrong. Please wait and try again.'));
    setTimeout(() => {
      dispatch(rest.updateUserPasswordInitState());
    }, 3000);
  }
};

export const getAllStores = (payload: any) => async (dispatch: any) => {
  dispatch(rest.getAllStoresPending());

  try {
    const res = await userApi.getAllStores(payload);
    const { data } = res;

    !data ? dispatch(rest.getAllStoresRejected('Wrong Params')) : dispatch(rest.getAllStoresFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(rest.getAllStoresRejected(message || 'Something went wrong. Please wait and try again.'));
  }
};

export const getAllWebsites = (payload: any) => async (dispatch: any) => {
  dispatch(rest.getAllWebsitesPending());

  try {
    const res = await userApi.getAllWebsites(payload);
    const { data } = res;
    !data ? dispatch(rest.getAllWebsitesRejected('Wrong Params')) : dispatch(rest.getAllWebsitesFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(rest.getAllWebsitesRejected(message || 'Something went wrong. Please wait and try again.'));
  }
};
