import { createSlice } from '@reduxjs/toolkit';
import { Status, iInitState } from '../../../../app/models';
interface iState extends iInitState {
  commentStatus: Status;
  statusUpdateStatusOrder: Status;

  message: string;
  data: any;
  dataDetails: any;
  memos: any;
  shipments: any;
  invoices: any;
  memoDetail: any;
  invoiceDetail: any;
  shipmentDetail: any;
  controller: any;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  isDESC: boolean;
  keySearch: string;
  filterData: {};
  queryString: string;
  commentHistories: [];
}

const initialState: iState = {
  status: 'rejected',
  statusUpdateStatusOrder: '',
  commentStatus: '',
  queryString: '',
  message: '',
  controller: null,
  urlData: {
    currentPage: 1,
    pageSize: 10,
  },
  commentHistories: [],
  isDESC: false,
  keySearch: 'increment_id',
  filterData: {
    isAdvancedFilter: 'true',
    keyword: '',
    purchaseDateTo: ' ',
    baseGrandTotalFrom: '',
    baseGrandTotalTo: '',
    totalRefundedFrom: '',
    totalRefundedTo: '',
    filterStatus: '',
    customerEmail: '',
    id: '',
  },
  data: {
    items: [],
    search_criteria: {},
  },
  dataDetails: {
    billing_address: {
      street: [],
    },
    items: [],
    status_histories: [],
    payment: {
      additional_information: [],
    },
  },
  invoices: {
    items: [],
    search_criteria: {},
  },
  memos: {
    items: [],
    search_criteria: {},
  },
  invoiceDetail: {
    items: [],
  },
  shipments: {
    items: [],
    search_criteria: {},
  },
  shipmentDetail: {
    items: [],
  },

  memoDetail: {
    items: [],
  },
};

const orderSlice = createSlice({
  name: 'ORDER',
  initialState,
  reducers: {
    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    getOrderDetailFulfilled: (state, action) => ({ ...state, dataDetails: action.payload, status: 'fulfilled' }),
    getOrderDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getOrderDetailPending: (state) => ({ ...state, status: 'pending' }),

    getInvoicesFulfilled: (state, action) => ({ ...state, invoices: action.payload, status: 'fulfilled' }),
    getInvoicesRejected: (state) => ({ ...state, status: 'rejected' }),
    getInvoicesPending: (state) => ({ ...state, status: 'pending' }),

    getInvoiceDetailFulfilled: (state, action) => ({ ...state, status: 'fulfilled', invoiceDetail: action.payload }),
    getInvoiceDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getInvoiceDetailPending: (state) => ({ ...state, status: 'pending' }),

    getMemosFulfilled: (state, action) => ({ ...state, status: 'fulfilled', memos: action.payload }),
    getMemosRejected: (state) => ({ ...state, status: 'rejected' }),
    getMemosPending: (state) => ({ ...state, status: 'pending' }),

    getMemosDetailFulfilled: (state, action) => ({ ...state, status: 'fulfilled', memoDetail: action.payload }),
    getMemosDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getMemosDetailPending: (state) => ({ ...state, status: 'pending' }),

    getShipmentFulfilled: (state, action) => ({ ...state, status: 'fulfilled', shipments: action.payload }),
    getShipmentRejected: (state) => ({ ...state, status: 'rejected' }),
    getShipmentPending: (state) => ({ ...state, status: 'pending' }),

    getShipmentDetailFulfilled: (state, action) => ({ ...state, status: 'fulfilled', shipmentDetail: action.payload }),
    getShipmentDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getShipmentDetailPending: (state) => ({ ...state, status: 'pending' }),

    postOrderCommentFulfilled: (state) => ({ ...state, statusUpdateStatusOrder: 'fulfilled' }),
    postOrderCommentRejected: (state) => ({ ...state, statusUpdateStatusOrder: 'rejected' }),
    postOrderCommentPending: (state) => ({ ...state, statusUpdateStatusOrder: 'pending' }),

    getNotesHistoryFulfilled: (state, action) => ({ ...state, commentHistories: action.payload, status: 'fulfilled' }),
    getNotesHistoryRejected: (state) => ({ ...state, status: 'rejected' }),
    getNotesHistoryPending: (state) => ({ ...state, status: 'pending' }),

    postStatusOrderFulfilled: (state) => ({ ...state, statusUpdateStatusOrder: 'fulfilled' }),
    postStatusOrderRejected: (state) => ({ ...state, statusUpdateStatusOrder: 'rejected' }),
    postStatusOrderPending: (state) => ({ ...state, statusUpdateStatusOrder: 'pending' }),

    // Synchonous Ation
    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    setFilterData: (state, action) => ({ ...state, filterData: action.payload }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),
    clearData: () => ({ ...initialState }),
  },
});

export const { ...rest } = orderSlice.actions;
const orderReducer = orderSlice.reducer;

export default orderReducer;
