import DefaultLayout from '~/components/layout/DefaultLayout';

interface Props {}

const NotFoundPage = (props: Props) => {
  return (
    <DefaultLayout pageTitle="">
      <div className="col-sm-12 col-sm-offset-1 text-center d-flex align-items-center justify-content-center flex-column">
        <h1 className="mb-2">404</h1>
        <div className="contain-box-404">
          <p className="">The page you are looking for is not avaible!</p>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
