import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useAppSelector } from '~/app/hooks/hooks';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

interface Props {
  handleChange: (e: any) => void;
}

const CKEditor5 = ({ handleChange }: Props) => {
  //   const dispatch = useAppDispatch();
  const { dataHeader } = useAppSelector((state) => state.createEditProductReducer);
  const { accessToken } = useAppSelector((s) => s.authReducer);
  const { data } = useAppSelector((s) => s.userReducer);
  const { id: idUser } = data;

  return (
    <CKEditor
      editor={DecoupledEditor}
      name="description"
      data={(dataHeader as any)['description'] || ''}
      config={{
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'outdent',
            'indent',
            '|',
            'fontFamily',
            'fontSize',
            'fontBackgroundColor',
            'fontColor',
            '|',
            'blockQuote',
            'insertTable',
            'CKFinder',
            'mediaEmbed',
            '|',
            'undo',
            'redo',
          ],
          removeItems: ['Image'],
        },
        shouldGroupWhenFull: true,
        ckfinder: {
          options: {
            resourceType: 'Images',
            pass: 'subfolder,token,licenseName,licenseKey',
            subfolder: idUser,
            token: accessToken,
            licenseName: process.env.REACT_APP_LICENSE_NAME,
            licenseKey: process.env.REACT_APP_LICENSE_KEY,
          },
        },
        mediaEmbed: { previewsInData: true },
      }}
      onReady={(editor: any) => {
        editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
        editor.editor = editor;
      }}
      onError={() => {}}
      onChange={(_: any, editor: any) => {
        const data = editor.getData();
        const event = {
          target: {
            name: 'description',
            value: data,
          },
        };
        handleChange(event);
      }}
    />
  );
};

export default CKEditor5;
