import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { COUNTRIES, timeConvert } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import dollar from '~/app/images/dolla-sign.svg';
import location from '~/app/images/location-icon.png';
import phone from '~/app/images/phone.svg';
import { iGetCreditMemosDetail, iGetInvoiceDetail, iGetShipmentDetail, iTableHead } from '~/app/models';
import { formatMoney } from '~/app/utils';
import AsyncSection from '~/components/common/AsyncSection';
import { Table } from '~/components/common/Table';
import { getInvoiceDetail, getMemosDetail, getShipmentsDetail } from '../redux/actions';
import Comment from './Comment';
import { listAtome, listPaypal } from './dataPaymentTable';
import { TableInvoice, TableMemo, TableOrdered, TableShipment } from './Tables';

type Props = {
  isInvoiceInfo?: boolean;
  isShippingInfo?: boolean;
  isMemosInfo?: boolean;
};

const SHIPMENT_METHOD_TABLE: iTableHead[] = [
  { name: 'Carrier', className: 'fs-14 fw-medium ps-4 py-3' },
  { name: 'Title', className: 'fs-14 fw-medium p-3' },
  { name: 'Number', className: 'fs-14 fw-medium text-end pe-4 py-3' },
];

const Information: FC<Props> = (props: Props) => {
  const { isInvoiceInfo = false, isShippingInfo = false, isMemosInfo = false } = props;
  const { orderId = 0, entity_id } = useParams();

  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { status, dataDetails, invoiceDetail, shipmentDetail, memoDetail } = useAppSelector((state) => state.orderReducer);
  const { billing_address, extension_attributes } = dataDetails;
  const { tracks } = shipmentDetail;

  const billAddress: any = ProcessAddress(billing_address, 'bill');
  const shipAddress: any = ProcessAddress(extension_attributes, 'ship');

  useEffect(() => {
    if (entity_id && isInvoiceInfo) {
      const payload: iGetInvoiceDetail = {
        entity_id,
        accessToken,
      };
      dispatch(getInvoiceDetail(payload, currentStore));
    }

    if (entity_id && isShippingInfo) {
      const payload: iGetShipmentDetail = {
        entity_id,
        accessToken,
      };
      dispatch(getShipmentsDetail(payload, currentStore));
    }

    if (entity_id && isMemosInfo) {
      const payload: iGetCreditMemosDetail = {
        entity_id,
        accessToken,
      };
      dispatch(getMemosDetail(payload, currentStore));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Process Bill Address
  function ProcessAddress(address: any, type: string) {
    const Address: any = { street: '', city: '', country: '' };

    if (typeof address === 'undefined') return Address;

    if (type === 'bill') {
      Address.street = !!address.street[0] ? address.street[0] : '';

      const city: any = [];
      if (!!address.city) city.push(address.city);
      if (!!address.postcode) city.push(address.postcode);
      if (!!city && city.length > 0) {
        Address.city = city.join(', ');
      }

      Address.country = !!address.country_id ? COUNTRIES[address.country_id] : '';
    } else {
      if (!!address && !!address.shipping_assignments && !!address.shipping_assignments[0].shipping.address) {
        const __address = { ...address.shipping_assignments[0].shipping.address };

        Address.street = !!__address.street ? __address.street[0] : '';
        const city: any = [];
        if (!!__address.city) city.push(__address.city);
        if (!!__address.postcode) city.push(__address.postcode);
        if (!!city && city.length > 0) {
          Address.city = city.join(', ');
        }

        Address.country = !!__address.country_id ? COUNTRIES[__address.country_id] : '';
      }
    }

    return Address;
  }

  const renderOrderInfo = () => {
    return (
      <div className="order-info bg-white px-4 py-3 wrapper h-100">
        <p className="order-info__title mb-4 fs-6 fw-semibold ">ORDER INFORMATION</p>
        <div className="row mb-3">
          <div className="col-sm-5">
            <span className="fs-14 mb-3 fw-medium">Customer Name</span>
          </div>
          <div className="col-sm-7">
            <span className="fs-14 mb-3">
              {dataDetails.customer_firstname} {dataDetails.customer_lastname}
            </span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-5">
            <span className="fs-14 mb-3 fw-medium">Email</span>
          </div>
          <div className="col-sm-7">
            <span className="fs-14 mb-3">{dataDetails.customer_email}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-5">
            <span className="fs-14 mb-3 fw-medium">Order Date</span>
          </div>
          <div className="col-sm-7">
            <span className="fs-14 mb-3">{timeConvert(dataDetails.created_at)}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-5">
            <span className="fs-14 mb-3 fw-medium">Purchased From</span>
          </div>
          <div className="col-sm-7">
            <span className="mb-3 fs-14 white-space-pre">{dataDetails.store_name}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className="info-address bg-white px-4 py-3 wrapper h-100">
        <p className="fs-6 fw-semibold mb-4">ADDRESS</p>
        <div className="row">
          <div className="col-sm-6">
            <div className="fs-14 fw-medium mb-2">
              <img className="me-2" src={dollar} alt="" /> Billing Address
            </div>
            <p className="info-address-item fs-14 fw-medium ps-4 mb-2">
              {billing_address.firstname} {billing_address.lastname}
            </p>
            <p className="info-address-item fs-14 ps-4 mb-2">{!!billAddress.street && billAddress.street}</p>
            <p className="info-address-item fs-14 ps-4 mb-2">{!!billAddress.city && billAddress.city}</p>
            <p className="info-address-item fs-14 ps-4 mb-2">{!!billAddress.country && billAddress.country}</p>
            <div className="fs-14 fw-medium mt-3 mb-2">
              <img className="me-2" src={phone} alt="" /> Phone Number
            </div>
            <p className="info-address-item fs-14 ps-4 mb-2">
              <a href={`tel:${billing_address.telephone}`} className="text-decoration-none text-dark fs-14">
                T: {billing_address.telephone}
              </a>
            </p>
          </div>
          <div className="col-sm-6">
            <div className="fs-14 fw-medium mb-2">
              <img className="me-2" src={location} alt="" />
              Shipping Address
            </div>
            <p className="info-address-item fw-medium fs-14 ps-4 mb-2">
              {`${extension_attributes?.shipping_assignments[0].shipping.address.firstname} ${extension_attributes?.shipping_assignments[0].shipping.address.lastname}`}
            </p>
            <p className="info-address-item fs-14 ps-4 mb-2">{!!shipAddress.street && shipAddress.street}</p>
            <p className="info-address-item fs-14 ps-4 mb-2">{!!shipAddress.city && shipAddress.city}</p>
            <p className="info-address-item fs-14 ps-4 mb-2">{!!shipAddress.country && shipAddress.country}</p>
            <div className="fs-14 fw-medium mt-3 mb-2">
              <img className="me-2" src={phone} alt="" /> Phone Number
            </div>
            <p className="info-address-item fs-14 ps-4 mb-2">
              <a
                href={`tel:${extension_attributes?.shipping_assignments[0].shipping.address.telephone}`}
                className="text-decoration-none text-dark"
              >
                T: {extension_attributes?.shipping_assignments[0].shipping.address.telephone}
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  function renderTablePayment() {
    switch (dataDetails.payment.method) {
      // Paypal
      case 'paypal_express':
        return (
          <table className="table-payment">
            <tbody>
              {listPaypal.map((item: any, i: number) => {
                const value =
                  item.key === 'last_trans_id'
                    ? dataDetails.payment[item.key]
                    : dataDetails.extension_attributes?.payment_additional_info.find((info: any) => info.key === item.key)?.value;

                return (
                  <tr key={i}>
                    <td className="px-2 py-3 fs-14">{item.title}</td>
                    <td className="px-2 py-3 fs-14 text-end">{value === 'null' || !value ? '' : value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      // Atome
      case 'atome_payment_gateway':
        return (
          <table className="table-payment">
            <tbody>
              {listAtome.map((item: any, i: number) => {
                const value = dataDetails.extension_attributes?.payment_additional_info.find((info: any) => info.key === item.key)?.value;

                return (
                  <tr key={i}>
                    <td className="px-2 py-3 fs-14">{item.title}</td>
                    <td className="px-2 py-3 fs-14 text-end">{value === 'null' || !value ? '' : value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      default:
        return null;
    }
  }

  const renderPaymentMethod = () => {
    const titlePaymentMethod = dataDetails.extension_attributes?.payment_additional_info.find(
      (item: any) => item.key === 'method_title',
    )?.value;
    return (
      status === 'fulfilled' && (
        <div className="info-payment wrapper px-4 py-3 bg-white h-100">
          <p className="mb-4 fs-6 fw-semibold">PAYMENT METHOD</p>
          <div className="d-flex flex-column gap-3">
            {titlePaymentMethod !== 'null' && titlePaymentMethod && <p className="fs-14 fw-normal m-0">{titlePaymentMethod}</p>}
            {renderTablePayment()}
            <p className="fs-14 fw-normal m-0">The order was placed using {dataDetails.order_currency_code}.</p>
          </div>
        </div>
      )
    );
  };

  const renderShippingMethod = () => {
    return (
      <div className="info-shipping wrapper px-4 py-3 bg-white h-100">
        <p className="mb-4 fs-6 fw-semibold">SHIPPING METHOD</p>
        <div className="d-flex align-items-center">
          <span className="fs-14 me-4">{dataDetails.shipping_description}</span>
          <span className="fs-14 fw-medidum">{formatMoney(dataDetails.shipping_amount, currentStore)}</span>
        </div>
      </div>
    );
  };

  function convertCarrier(carrier_code: string) {
    switch (carrier_code) {
      case 'custom':
        return 'Custom Value';
      case 'zto':
        return 'ZTO';
      case 'zot':
        return 'ZOT';
      case 'dhl':
        return 'DHL';
      case 'fedex':
        return 'Federal Express';
      case 'ups':
        return 'United Parcel Service';
      case 'usps':
        return 'United States Postal Service';
      default:
        throw new Error("Carrier isn't defined");
    }
  }

  const renderShipmentShippingMethod = () => {
    return (
      status === 'fulfilled' && (
        <>
          <div className="wrapper bg-white h-100">
            <div className="info-shipping  px-4 py-3">
              <p className="mb-4 fs-6 fw-semibold text-uppercase">SHIPPING METHOD</p>
              <div className="d-flex align-items-center mb-3">
                <span className="fs-14 me-4">{dataDetails.shipping_description} </span>
                <span className="fs-14 fw-medidum">{formatMoney(dataDetails.shipping_amount, currentStore)}</span>
              </div>
            </div>
            {tracks && tracks.length > 0 && (
              <Table status={status} dataTableHead={SHIPMENT_METHOD_TABLE} className="tracks-table">
                {tracks.map((item: any, i: number) => (
                  <tr key={i}>
                    <td className="ps-4 py-3 border-0 vertical-middle fs-14">{convertCarrier(item.carrier_code)}</td>
                    <td className="p-3 border-0 vertical-middle fs-14 text-capitalize">{item.title}</td>
                    <td className="pe-4 py-3 border-0 vertical-middle text-link text-end fs-14 text-capitalize">{item.track_number}</td>
                  </tr>
                ))}
              </Table>
            )}
          </div>
        </>
      )
    );
  };
  const renderTotal = () => {
    return (
      <div className="info-total bg-white px-4 py-3 wrapper">
        <div className="row">
          <div className="col-sm-6">
            <Comment pageType={'infomation'} orderId={orderId} />
          </div>
          <div className="col-sm-6">
            <p className="mb-4 fs-6 fw-semibold">ORDER TOTALS</p>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">Subtotal</span>
              <span className="fs-14 fw-semibold text-dark">{formatMoney(dataDetails.subtotal, currentStore)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">
                Discount {dataDetails.discount_description ? `(${dataDetails.discount_description})` : ''}
              </span>
              <span className="fs-14 fw-semibold text-dark">
                {dataDetails.base_discount_amount
                  ? '-' + formatMoney(Math.abs(dataDetails.base_discount_amount), currentStore)
                  : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">Tax</span>
              <span className="fs-14 fw-semibold text-dark">
                {formatMoney(dataDetails.base_tax_amount ? dataDetails.base_tax_amount : 0, currentStore)}
              </span>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">Shipping & Handling</span>
              <span className="fs-14 fw-semibold text-dark">{formatMoney(dataDetails.base_shipping_amount, currentStore)}</span>
            </div> */}
            <div className="separator-line-dark-200"></div>
            <div className="d-flex justify-content-between align-items-center my-3">
              <span className="fs-14 fw-medium">Grand Total</span>
              <span className="fs-14 fw-semibold text-danger">{formatMoney(dataDetails.grand_total, currentStore)}</span>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">Total Paid</span>
              <span className="fs-14 fw-semibold text-danger">
                {formatMoney(dataDetails.total_paid ? dataDetails.total_paid : 0, currentStore)}
              </span>
            </div> */}
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">Total Refuned</span>
              <span className="fs-14 fw-semibold text-danger">
                {formatMoney(dataDetails.total_refunded ? dataDetails.total_refunded : 0, currentStore)}
              </span>
            </div> */}
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="fs-14 fw-medium">Total Due</span>
              <span className="fs-14 fw-semibold text-danger">
                {formatMoney(dataDetails.base_total_due ? dataDetails.base_total_due : 0, currentStore)}
              </span>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderTotalInvoice = () => {
    return (
      <div className="info-total bg-white px-4 py-3 wrapper">
        <div className="row">
          <div className="col-sm-6">
            <Comment pageType="invoices" orderId={orderId} />
          </div>
          <div className="col-sm-6">
            <p className="mb-4 fs-6 fw-semibold text-uppercase mt-3 mt-lg-0">Invoice Totals</p>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Subtotal</span>
              <span className="fs-14 fw-semibold text-dark">{formatMoney(invoiceDetail.base_subtotal, currentStore)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">
                Discount {invoiceDetail.discount_description ? `(${invoiceDetail.discount_description})` : ''}
              </span>
              <span className="fs-14 fw-semibold text-dark">
                {invoiceDetail.base_discount_amount
                  ? '-' + formatMoney(Math.abs(invoiceDetail.base_discount_amount), currentStore)
                  : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Tax</span>
              <span className="fs-14 fw-semibold text-dark">{formatMoney(invoiceDetail.base_tax_amount, currentStore)}</span>
            </div>
            <div className={`d-flex justify-content-between align-items-center mb-4 ${invoiceDetail.base_shipping_amount ? '' : 'd-none'}`}>
              <span className="fs-14 fw-medium">Shipping & Handling</span>
              <span className="fs-14 fw-semibold text-dark">
                {invoiceDetail.base_shipping_amount
                  ? formatMoney(invoiceDetail.base_shipping_amount, currentStore)
                  : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="separator-line-dark-200"></div>
            <div className="d-flex justify-content-between align-items-center my-3 my-lg-4">
              <span className="fs-6 fw-semibold text-uppercase">Grand Total</span>
              <span className="fs-5 fw-semibold text-danger">{formatMoney(invoiceDetail.base_grand_total, currentStore)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTotalShipment = () => {
    return (
      <div className="info-total bg-white px-4 py-3 mt-4 mt-xl-0 wrapper h-100">
        <div className="row">
          <div className="col-12">
            <Comment orderId={orderId} pageType="shipment" />
          </div>
          <div className="col-sm-6"></div>
        </div>
      </div>
    );
  };

  const renderTotalCreditMemo = () => {
    return (
      <div className="info-total bg-white px-4 py-3 wrapper">
        <div className="row">
          <div className="col-sm-6">
            <Comment orderId={orderId} pageType="memo" />
          </div>
          <div className="col-sm-6">
            <p className="mb-4 fs-6 fw-semibold text-uppercase mt-3 mt-lg-0">Credit memo Totals</p>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Subtotal</span>
              <span className="fs-14 fw-semibold text-dark">{formatMoney(memoDetail.base_subtotal, currentStore)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">
                Discount {memoDetail.discount_description ? `(${memoDetail.discount_description})` : ''}
              </span>
              <span className="fs-14 fw-semibold text-dark">
                {memoDetail.base_discount_amount
                  ? '-' + formatMoney(Math.abs(memoDetail.base_discount_amount), currentStore)
                  : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Tax</span>
              <span className="fs-14 fw-semibold text-dark">
                {formatMoney(memoDetail.base_tax_amount ? memoDetail.base_tax_amount : 0, currentStore)}
              </span>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Shipping & Handling</span>
              <span className="fs-14 fw-semibold text-dark">
                {memoDetail.base_shipping_amount
                  ? formatMoney(memoDetail.base_shipping_amount, currentStore)
                  : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Adjustment Refund</span>
              <span className="fs-14 fw-semibold text-dark">
                {memoDetail.adjustment_positive ? formatMoney(memoDetail.adjustment_positive, currentStore) : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="fs-14 fw-medium">Adjustment Fee</span>
              <span className="fs-14 fw-semibold text-dark">
                {memoDetail.adjustment_negative ? formatMoney(memoDetail.adjustment_negative, currentStore) : formatMoney(0, currentStore)}
              </span>
            </div>
            <div className="separator-line-dark-200"></div>
            <div className="d-flex justify-content-between align-items-center my-3 my-lg-4">
              <span className="fs-6 fw-semibold text-uppercase">Grand Total</span>
              <span className="fs-5 fw-semibold text-danger">{formatMoney(memoDetail.base_grand_total, currentStore)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBacklink = () => {
    return (
      <div className="mb-4 ms-2">
        {isInvoiceInfo && (
          <Link className="text-decoration-none fs-6 fw-semibold text-primary" to={`/orders/${orderId}/invoice`}>
            <FontAwesomeIcon className="backlink-icon" icon={faChevronLeft} /> INVOICE #{invoiceDetail.increment_id}
          </Link>
        )}
        {isShippingInfo && (
          <Link className="text-decoration-none fs-6 fw-semibold text-primary text-uppercase" to={`/orders/${orderId}/shipments`}>
            <FontAwesomeIcon className="backlink-icon" icon={faChevronLeft} /> Shipment #{shipmentDetail.increment_id}
          </Link>
        )}
        {isMemosInfo && (
          <Link className="text-decoration-none fs-6 fw-semibold text-primary text-uppercase" to={`/orders/${orderId}/credit-memos`}>
            <FontAwesomeIcon className="backlink-icon" icon={faChevronLeft} /> Credit Memo #{memoDetail.increment_id}
          </Link>
        )}
      </div>
    );
  };

  return (
    <AsyncSection status={status} className="info-wrapper mt-4">
      {renderBacklink()}
      <div className="row">
        <div className="col-lg-12 col-xl-5">{renderOrderInfo()}</div>
        <div className="col-lg-12 col-xl-7 mt-3 mt-lg-3 mt-xl-0">{renderAddress()}</div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 col-xl-5">{renderPaymentMethod()}</div>
        <div className="col-lg-12 col-xl-7 mt-3 mt-lg-3 mt-xl-0">
          {isInvoiceInfo && renderShippingMethod()}
          {isShippingInfo && renderShipmentShippingMethod()}
          {!isShippingInfo && !isInvoiceInfo && renderShippingMethod()}
        </div>
      </div>
      <div className="row mt-4">
        {!isShippingInfo && (
          <div className="col-sm-12">
            {isInvoiceInfo && <TableInvoice />}
            {isMemosInfo && <TableMemo />}
            {!isInvoiceInfo && !isShippingInfo && !isMemosInfo && <TableOrdered currentStore={currentStore} />}
          </div>
        )}
        <div className="col-lg-12 col-xl-5 order-1 order-xl-0">{isShippingInfo && renderTotalShipment()}</div>
        <div className="col-lg-12 col-xl-7 order-0 order-xl-1">{isShippingInfo && <TableShipment />}</div>
      </div>
      <div className="row mt-4">
        {!isShippingInfo && (
          <div className="col-sm-12">
            {isInvoiceInfo && renderTotalInvoice()}
            {isMemosInfo && renderTotalCreditMemo()}
            {!isInvoiceInfo && !isShippingInfo && !isMemosInfo && renderTotal()}
          </div>
        )}
      </div>
    </AsyncSection>
  );
};

export default Information;
