import { faBagShopping, faChartLine, faChartSimple, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { addFilterGroup, addFilterItem, addFilters, getStoreId, searchCriteriaBuilder } from '~/app/utils';
import { getQtyNewTicket } from '~/components/pages/TicketListing/redux/action';
import { ReactComponent as TicketLogo } from '~/app/images/tickets.svg';

interface iNavigation {
  icon?: any;
  svg?: any;
  name: string;
  badge?: number;
  path: string;
  realName: string[];
}

interface Props {
  isDisplay: boolean;
}

const Navigation: React.FC<Props> = (props: Props) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const arrPathName = pathname.split('/').filter((item) => item !== '');

  const { isDisplay } = props;

  // Declare data from Reducers
  const { storeData } = useAppSelector((s) => s.userReducer);
  const { orderQueries } = useAppSelector((s) => s.reportReducer);
  const { queryString: orderQuery } = useAppSelector((s) => s.orderReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { queryString: ticketQuery, qtyNewTicket } = useAppSelector((s) => s.ticketReducer);

  // Declare useState
  const [storeId, setStoreId] = useState<number>();

  // Declare useEffect
  useEffect(() => {
    setStoreId(getStoreId(storeData, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    const closeBtn = document.querySelectorAll('#ckf-modal-close') as any;

    if (!closeBtn) return;
    closeBtn[0]?.click();
  }, [pathname]);

  useEffect(() => {
    // Declare unread ticket dispatch
    if (storeId) {
      dispatch(
        getQtyNewTicket(
          searchCriteriaBuilder(
            Number.MAX_VALUE,
            1,
            addFilterGroup(addFilters(addFilterItem('is_read', '0', 'eq')), addFilters(addFilterItem('store_id', `${storeId}`, 'eq'))),
          ),
          accessToken,
          currentStore,
        ),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore, storeId]);

  // Declare Navigation bar info
  const navigation = [
    { realName: ['dashboard'], icon: faChartSimple, name: 'Dashboard', path: `/dashboard` },
    { realName: ['products', 'product'], icon: faList, name: 'Products', path: `/products/${productQuery}` },
    // { realName: 'Attribute', icon: faToggleOn, name: 'Attributes', path: '' },
    { realName: ['orders'], icon: faBagShopping, name: 'Orders', path: `/orders/${orderQuery}` },
    { realName: ['sales-report'], icon: faChartLine, name: 'Reports', path: `/sales-report/orders-report${orderQueries}` },
    { realName: ['tickets'], svg: <TicketLogo />, name: 'Tickets', badge: qtyNewTicket, path: `/tickets/${ticketQuery}` },
  ] as iNavigation[];

  function checkActive(realName: string[]) {
    let active = false;
    active = realName.some((realName) => realName === arrPathName[0]);

    return active ? 'active' : '';
  }

  return (
    <nav className={`navigator d-flex justify-content-center ${isDisplay ? 'active' : 'd-none'}`}>
      <div className="navigator--items d-flex align-items-center">
        {navigation.map((item, i: number) => {
          return (
            <div key={i} className={`${checkActive(item.realName)} item-wrapper mx-4`}>
              <Link className="text-decoration-none text-dark fw-medium d-flex flex-column text-center" to={item.path}>
                {item.icon ? <FontAwesomeIcon icon={item.icon} className="mb-2 fs-4" /> : <div className="mb-2">{item.svg}</div>}
                <span>
                  {item.name}{' '}
                  {item.badge && item.badge > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {item.badge > 10 ? '10+' : item.badge}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </Link>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default Navigation;
