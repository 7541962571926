import { addFilterItem, addFilters } from './search';
import moment from 'moment';

export const builderCriteriaProduct = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(addFilters(addFilterItem('name', `%${data.searchTerm}%`, 'like'), addFilterItem('sku', `%${data.searchTerm}%`, 'like')));

  //Filter Section
  if (data.name) filter.push(addFilters(addFilterItem('name', `%${data.name}%`, 'like')));
  if (data.sku) filter.push(addFilters(addFilterItem('sku', `%${data.sku}%`, 'like')));

  //ID
  if (data.idFrom) {
    filter.push(addFilters(addFilterItem('entity_id', `${data.idFrom}`, 'from')));
  } else if (!data.idFrom && data.idTo) {
    filter.push(addFilters(addFilterItem('entity_id', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.idTo) filter.push(addFilters(addFilterItem('entity_id', `${data.idTo}`, 'to')));

  //PRICE
  if (data.priceFrom) {
    filter.push(addFilters(addFilterItem('price', `${data.priceFrom}`, 'from')));
  } else if (!data.priceFrom && data.priceTo) {
    filter.push(addFilters(addFilterItem('price', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.priceTo) filter.push(addFilters(addFilterItem('price', `${data.priceTo}`, 'to')));

  //QUANTITY
  if (data.qtyFrom) {
    filter.push(addFilters(addFilterItem('qty', `${data.qtyFrom}`, 'from')));
  } else if (!data.qtyFrom && data.qtyTo) {
    filter.push(addFilters(addFilterItem('qty', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.qtyTo) filter.push(addFilters(addFilterItem('qty', `${data.qtyTo}`, 'to')));

  //VISIBILITY
  if (data.visibility) filter.push(addFilters(addFilterItem('visibility', `%${data.visibility}%`, 'like')));

  //PRODUCT TYPE
  if (data.type) filter.push(addFilters(addFilterItem('type_id', `%${data.type}%`, 'like')));

  //PRODUCT STATUS
  if (data.status && parseInt(data.status) === 1) {
    filter.push(addFilters(addFilterItem('status', `%${data.status}%`, 'like'))); //ENABLE
  } else if (data.status && parseInt(data.status) !== 1) {
    filter.push(addFilters(addFilterItem('status', '1', 'neq'))); //DISABLE
  }
  return filter;
};

//Order Listing Filter
export const builderCriteriaOrder = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(
      addFilters(
        addFilterItem('increment_id', `%${data.searchTerm}%`, 'like'),
        addFilterItem('customer_email', `%${data.searchTerm}%`, 'like'),
      ),
    );

  //Filter Section
  if (data.id) filter.push(addFilters(addFilterItem('increment_id', `%${data.id}%`, 'like')));
  if (data.filterStatus) filter.push(addFilters(addFilterItem('status', `${data.filterStatus}`, 'eq')));
  if (data.customerEmail) filter.push(addFilters(addFilterItem('customer_email', `%${data.customerEmail}%`, 'like')));

  //Total Refunded
  if (data.totalRefundedFrom) {
    filter.push(addFilters(addFilterItem('total_refunded', `${data.totalRefundedFrom}`, 'from')));
  } else if (!data.totalRefundedFrom && data.totalRefundedTo) {
    filter.push(addFilters(addFilterItem('total_refunded', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.totalRefundedTo) filter.push(addFilters(addFilterItem('total_refunded', `${data.totalRefundedTo}`, 'to')));

  //Grand Total
  if (data.baseGrandTotalFrom) {
    filter.push(addFilters(addFilterItem('base_grand_total', `${data.baseGrandTotalFrom}`, 'from')));
  } else if (!data.baseGrandTotalFrom && data.baseGrandTotalTo) {
    filter.push(addFilters(addFilterItem('base_grand_total', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.baseGrandTotalTo) filter.push(addFilters(addFilterItem('base_grand_total', `${data.baseGrandTotalTo}`, 'to')));

  //Purchase Date
  if (data.purchaseDateFrom) {
    filter.push(addFilters(addFilterItem('created_at', `${data.purchaseDateFrom}`, 'from')));
  } else if (!data.purchaseDateFrom && data.purchaseDateTo) {
    filter.push(addFilters(addFilterItem('created_at', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.purchaseDateTo)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.purchaseDateTo).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));

  return filter;
};

export const builderCriteriaTicket = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm) filter.push(addFilters(addFilterItem('customer_name', `%${data.searchTerm}%`, 'like')));

  //Filter Section
  if (data.customer_name) filter.push(addFilters(addFilterItem('customer_name', `%${data.customer_name}%`, 'like')));
  if (data.priority_id) filter.push(addFilters(addFilterItem('priority_id', `${data.priority_id}`, 'eq')));
  if (data.created_at_from) filter.push(addFilters(addFilterItem('created_at', `${data.created_at_from}`, 'from')));
  if (data.created_at_to)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.created_at_to).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));

  if (data.status_id < 0) {
    filter.push(addFilters(addFilterItem('status_id', data.status_id, 'gt')));
  } else {
    filter.push(addFilters(addFilterItem('status_id', data.status_id, 'eq')));
  }

  return filter;
};
