import { createSlice } from '@reduxjs/toolkit';
import { Status, iInitState } from '~/app/models';

interface iState extends iInitState {
  isSignIn: boolean;
  data: any;
  message: string;
  status: Status;
  updateStatus: Status;
  deleteAvatarStatus: Status;
  deleteBannerStatus: Status;
  updateAvatarAndBannerStatus: Status;
  updatePasswordStatus: Status;
  storeData: any;
  websiteData: any;
}

const initialState: iState = {
  status: '',
  updateStatus: '',
  deleteAvatarStatus: '',
  deleteBannerStatus: '',
  updateAvatarAndBannerStatus: '',
  updatePasswordStatus: '',
  isSignIn: false,
  data: {},
  storeData: {},
  websiteData: {},
  message: '',
};

const userSlide = createSlice({
  name: 'User Reducers',
  initialState,
  reducers: {
    getUserFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getUserRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getUserPending: (state) => ({ ...state, status: 'pending' }),

    updateUserFulfilled: (state) => ({ ...state, updateStatus: 'fulfilled' }),
    updateUserRejected: (state, action) => ({ ...state, message: action.payload, updateStatus: 'rejected' }),
    updateUserPending: (state) => ({ ...state, updateStatus: 'pending' }),
    updateUserInitState: (state) => ({ ...state, updateStatus: '' }),

    updateUserAvatarFulfilled: (state) => ({ ...state, updateAvatarAndBannerStatus: 'fulfilled' }),
    updateUserAvatarRejected: (state, action) => ({ ...state, message: action.payload, updateAvatarAndBannerStatus: 'rejected' }),
    updateUserAvatarPending: (state) => ({ ...state, updateAvatarAndBannerStatus: 'pending' }),
    updateUserAvatarInitState: (state) => ({ ...state, updateAvatarAndBannerStatus: '' }),

    deleteAvatarFulfilled: (state) => ({ ...state, deleteAvatarStatus: 'fulfilled' }),
    deleteAvatarRejected: (state, action) => ({ ...state, message: action.payload, deleteAvatarStatus: 'rejected' }),
    deleteAvatarPending: (state) => ({ ...state, deleteAvatarStatus: 'pending' }),
    deleteAvatarInitState: (state) => ({ ...state, deleteAvatarStatus: '' }),

    deleteBannerFulfilled: (state) => ({ ...state, deleteBannerStatus: 'fulfilled' }),
    deleteBannerRejected: (state, action) => ({ ...state, message: action.payload, deleteBannerStatus: 'rejected' }),
    deleteBannerPending: (state) => ({ ...state, deleteBannerStatus: 'pending' }),
    deleteBannerInitState: (state) => ({ ...state, deleteBannerStatus: '' }),

    updateUserPasswordFulfilled: (state) => ({ ...state, updatePasswordStatus: 'fulfilled' }),
    updateUserPasswordRejected: (state, action) => ({ ...state, message: action.payload, updatePasswordStatus: 'rejected' }),
    updateUserPasswordPending: (state) => ({ ...state, updatePasswordStatus: 'pending' }),
    updateUserPasswordInitState: (state) => ({ ...state, updatePasswordStatus: '' }),

    getAllStoresFulfilled: (state, action) => ({ ...state, storeData: action.payload, status: 'fulfilled' }),
    getAllStoresRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getAllStoresPending: (state) => ({ ...state, status: 'pending' }),

    getAllWebsitesFulfilled: (state, action) => ({ ...state, websiteData: action.payload, status: 'fulfilled' }),
    getAllWebsitesRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getAllWebsitesPending: (state) => ({ ...state, status: 'pending' }),
  },
});

export const { ...rest } = userSlide.actions;
const userReducer = userSlide.reducer;

export default userReducer;
