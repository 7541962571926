export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertStringToNumber = (string: string = '', defaultNumber: number = 0): number => {
  if (!string) return defaultNumber;

  if (isNaN(+string)) return defaultNumber;

  return +string;
};
