import { faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SettingsProfileSchema } from '../Schema';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Loading from '~/components/common/Loading';
import { updateUser } from '../../redux/action';
import { FC, useEffect } from 'react';
import Button from '~/components/common/Button';

type FormSettingValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  facebook_link: string;
  twitter_link: string;
  tiktok_link: string;
  address: string;
  description: string;
};

const Settings: FC = () => {
  const {
    data: dataGetUser,
    status,
    message,
    updateStatus,
    updateAvatarAndBannerStatus,
    updatePasswordStatus,
  } = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.authReducer);

  const {
    setValue,
    register: registerSettings,
    formState: { errors: errorsSettings },
    handleSubmit: handleSubmitSettings,
    reset,
  } = useForm<FormSettingValues>({
    resolver: yupResolver(SettingsProfileSchema),
  });

  useEffect(() => {
    if (
      (updateAvatarAndBannerStatus === 'fulfilled' && updateStatus !== 'fulfilled') ||
      (updatePasswordStatus === 'fulfilled' && updateStatus !== 'fulfilled')
    ) {
      const { first_name, last_name, email, phone_number, address, description, tiktok_link, twitter_link, facebook_link } = dataGetUser;

      reset({
        first_name,
        last_name,
        email,
        phone_number,
        address,
        description,
        tiktok_link,
        twitter_link,
        facebook_link,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updatePasswordStatus, updateAvatarAndBannerStatus]);

  useEffect(() => {
    const { first_name, last_name, email, phone_number, address, description, tiktok_link, twitter_link, facebook_link } = dataGetUser;

    setValue('first_name', first_name || '');
    setValue('last_name', last_name || '');
    setValue('email', email || '');
    setValue('phone_number', phone_number || '');
    setValue('address', address || '');
    setValue('tiktok_link', tiktok_link || '');
    setValue('twitter_link', twitter_link || '');
    setValue('facebook_link', facebook_link || '');
    setValue('description', description || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetUser]);

  const onSubmit = async (data: any) => {
    const dataPayload = {
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        address: data.address,
        tiktok_link: data.tiktok_link,
        twitter_link: data.twitter_link,
        facebook_link: data.facebook_link,
        description: data.description,
      },
    };
    dispatch(updateUser(dataPayload, accessToken));
  };

  const renderHeader = () => {
    return (
      // <div className="pt-4 pb-3 mx-4 border-bottom d-flex justify-content-between align-items-center">
      //   <div>
      //     <Link className="account-tab fs-6 fw-medium text-muted text-decoration-none me-4" to="../overview">
      //       Overview
      //     </Link>
      //     <Link
      //       className="account-tab-setting fs-6 fw-medium active text-decoration-none border-bottom border-3 border-primary pt-4 pb-3"
      //       to=""
      //     >
      //       Settings
      //     </Link>
      //   </div>
      // </div>
      <h3 className="fs-5 fw-semibold mb-3 mt-4 mt-xl-0">Settings</h3>
    );
  };

  const renderSubmit = () => {
    return (
      <div className="d-flex justify-content-end mt-4">
        <Button className="py-12 px-4" disabled={updateStatus === 'pending'} type="submit">
          {updateStatus === 'pending' ? (
            <>
              Saving
              <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
            </>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    );
  };
  const renderNameInput = () => {
    return (
      <div className="row mb-3">
        <label htmlFor="first_name" className="col-12 col-md-3 fw-medium text-dark fs-6 cursor-pointer">
          Vendor Name
        </label>
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-6">
              <input
                type="text"
                id="first_name"
                placeholder="First Name"
                className={`form-control form-control-solid col-6 fs-6 text-truncate ${errorsSettings.first_name ? 'is-invalid' : ''}`}
                {...registerSettings('first_name')}
                defaultValue={dataGetUser.first_name}
              />
              <div className="invalid-feedback">{errorsSettings.first_name?.message}</div>
            </div>
            <div className="col-6">
              <input
                type="text"
                placeholder="Last Name"
                className={`form-control form-control-solid col-6 fs-6 text-truncate ${errorsSettings.last_name ? 'is-invalid' : ''}`}
                {...registerSettings('last_name')}
                defaultValue={dataGetUser.last_name}
              />
              <div className="invalid-feedback">{errorsSettings.last_name?.message}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContactPhoneInput = () => {
    const regex = /^[0-9\-+()#_]+$/; //only numbers, hyphens, plus signs, underscores, pound signs, and parentheses

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const char = event.currentTarget.value + event.key;
      const isValidChar = regex.test(char);
      if (!isValidChar) {
        event.preventDefault();
      }
    };

    return (
      <div className="row mb-3">
        <label htmlFor="contact-phone" className="col-12 col-md-3 fw-medium text-dark fs-6 cursor-pointer">
          Contact Phone
        </label>
        <div className="col-12 col-md-8">
          <input
            id="contact-phone"
            type="text"
            className={`form-control form-control-solid fs-6 w-100 ${errorsSettings.phone_number ? 'is-invalid' : ''}`}
            {...registerSettings('phone_number')}
            defaultValue={dataGetUser.phone_number}
            {...{ onKeyPress: handleKeyPress }}
          />
          <div className="invalid-feedback">{errorsSettings.phone_number?.message}</div>
        </div>
      </div>
    );
  };

  const renderContactEmailInput = () => {
    return (
      <div className="row mb-3">
        <label htmlFor="contact-email" className="col-12 col-md-3 fw-medium text-dark fs-6">
          Contact Email
        </label>
        <div className="col-12 col-md-8">
          <input
            id="contact-email"
            type="text"
            className={`form-control form-control-solid fs-6 w-100 ${errorsSettings.email ? 'is-invalid' : ''}`}
            {...registerSettings('email')}
            defaultValue={dataGetUser.email}
          />
          <div className="invalid-feedback">{errorsSettings.email?.message}</div>
        </div>
      </div>
    );
  };

  const renderSocialMedia = (lable: string, name: any) => {
    return (
      <div className="row mb-3">
        <label htmlFor={name} className="col-12 col-md-3 fw-medium text-dark fs-6 cursor-pointer">
          {lable}
        </label>
        <div className="col-12 col-md-8">
          <input
            id={name}
            type="text"
            className={`form-control form-control-solid fs-6 w-100 ${
              errorsSettings[name as keyof typeof errorsSettings] ? 'is-invalid' : ''
            }`}
            {...registerSettings(name)}
            defaultValue={dataGetUser.name || ''}
          />
          <div className="invalid-feedback">{errorsSettings[name as keyof typeof errorsSettings]?.message as any}</div>
        </div>
      </div>
    );
  };

  const renderAddressInput = () => {
    return (
      <div className="row mb-3">
        <label htmlFor="address" className="col-12 col-md-3 fw-medium text-dark cursor-pointer fs-6">
          Address
        </label>
        <div className="col-12 col-md-8">
          <input
            id="address"
            type="text"
            className={`form-control form-control-solid fs-6 w-100 ${errorsSettings.address ? 'is-invalid' : ''}`}
            {...registerSettings('address')}
            defaultValue={dataGetUser.address}
          />
          <div className="invalid-feedback">{errorsSettings.address?.message}</div>
        </div>
      </div>
    );
  };

  function renderDescription() {
    return (
      <div className="row mb-3">
        <label htmlFor="description" className="col-12 col-md-3 fw-medium text-dark cursor-pointer fs-6">
          Description
        </label>
        <div className="col-12 col-md-8">
          <textarea
            id="description"
            className={`form-control form-control-solid form-control--description w-100 fs-6 ${
              errorsSettings.description ? 'is-invalid' : ''
            }`}
            {...registerSettings('description')}
            defaultValue={dataGetUser.description}
          ></textarea>
        </div>
      </div>
    );
  }

  return (
    <>
      {renderHeader()}
      <div className="card bg-white mt-4 mt-xl-0 shadow-sm border-0 p-4">
        <form onSubmit={handleSubmitSettings(onSubmit)}>
          {/* Render Header Form Section */}
          {status === 'pending' && (
            <div className="bg-white h-100">
              <div className="card-body d-flex flex-column align-items-center justify-content-center mt-2">
                <Loading />
              </div>
            </div>
          )}
          {status === 'fulfilled' && (
            <div className="card-body p-0">
              {/* Render Name Input Section */}
              {renderNameInput()}
              {/* Render Contact Email Input Section */}
              {renderContactEmailInput()}
              {/* Render Contact Phone Input Section */}
              {renderContactPhoneInput()}
              {/* Render Address Input */}
              {renderAddressInput()}
              {/* Render Description */}
              {renderDescription()}
              {/* Render Social Media Input Section */}
              {renderSocialMedia('Facebook', 'facebook_link')}
              {renderSocialMedia('Twitter', 'twitter_link')}
              {renderSocialMedia('Tiktok', 'tiktok_link')}
            </div>
          )}
          {renderSubmit()}
          {status === 'rejected' && (
            <div className="card-body px-4 d-flex align-items-center justify-content-center text-danger">
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
              {message}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default Settings;
