import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import Button from '~/components/common/Button';
import { PopupWrap } from '~/components/common/Popup';
import { addNewQuickAnswerSchema } from '../Schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Select from '~/components/common/Select';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { postQuickAnswer } from '../redux/action';

interface Props {
  closePopup: () => void;
}

const AddNewQuickAnswer = ({ closePopup }: Props) => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { statusPostQuickAnswer } = useAppSelector((s) => s.ticketReducer);

  const [active, setActive] = useState<'0' | '1'>('1');
  // const [navActiveAdd, setNavActiveAdd] = useState<boolean>(true);

  const contentRef = useRef<HTMLDivElement>(null);

  useClickOutside(contentRef, () => closePopup());

  const addNewQuickAnswerFormik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: addNewQuickAnswerSchema,
    validateOnChange: false,
    onSubmit: handlePostQuickAnswer,
  });
  const { values, errors, resetForm, setFieldError, handleSubmit, handleChange } = addNewQuickAnswerFormik;

  function handlePostQuickAnswer(values: any) {
    const { title, content } = values;

    const payload = {
      Quickanswer: {
        title: title.trim(),
        content: content.trim(),
        is_active: active,
      },
    };
    dispatch(postQuickAnswer(payload, accessToken, currentStore, resetForm, closePopup));
  }

  // function handleChangeNavActive(activeAdd: boolean) {
  //   setNavActiveAdd(activeAdd);
  // }

  return (
    <PopupWrap>
      <div className="content-popup wrapper bg-white p-4 d-flex flex-column gap-4" ref={contentRef}>
        <div className="heading d-flex align-items-center justify-content-between fs-3 fw-medium">
          <h3 className="title fw-medium m-0">Add New Quick Answer</h3>
          <FontAwesomeIcon icon={faXmark} className="close-icon cursor-pointer" onClick={closePopup} />
        </div>

        {/* <nav className="nav d-flex align-items-center fs-6 fw-medium cursor-pointer">
          <p className={`nav-item pb-2 m-0 w-fit-content ${navActiveAdd ? 'active' : ''}`} onClick={() => handleChangeNavActive(true)}>
            Add New Quick Answer
          </p>
          <p
            className={`nav-item pb-2 m-0 flex-grow-1 text-center ${!navActiveAdd ? 'active' : ''}`}
            onClick={() => handleChangeNavActive(false)}
          >
            Settings
          </p>
        </nav> */}

        <div className="body d-flex flex-column gap-4">
          <div className="d-flex flex-column">
            <label htmlFor="active" className="form-label cursor-pointer fs-6 fw-medium">
              Active
            </label>

            <Select className="select-active" value={active} onChange={(e: any) => setActive(e.target.value)} width="100%">
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </Select>
          </div>

          <div>
            <label htmlFor="title" className="form-label cursor-pointer fs-6 fw-medium">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={values.title}
              onChange={(e) => {
                errors.title && setFieldError('title', undefined);
                handleChange(e);
              }}
            />
            {errors.title && <p className="mb-0 mt-2 text-danger fs-14">{errors.title}</p>}
          </div>

          <div>
            <label htmlFor="content" className="form-label cursor-pointer fs-6 fw-medium">
              Content
            </label>
            <textarea
              className="form-control content-quick-answer"
              id="content"
              name="content"
              value={values.content}
              onChange={(e) => {
                errors.content && setFieldError('content', undefined);
                handleChange(e);
              }}
            ></textarea>
            {errors.content && <p className="mb-0 mt-2 text-danger fs-14">{errors.content}</p>}

            <p className="m-0 mt-2 note-add-quick-answer">
              You can use variables: [ticket_customer_name], [ticket_customer_email], [ticket_code], [user_firstname], [user_lastname],
              [user_email]
            </p>
          </div>
        </div>

        <div className="footer">
          <Button outline onClick={closePopup}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={statusPostQuickAnswer === 'pending'}>
            Save
          </Button>
        </div>
      </div>
    </PopupWrap>
  );
};

export default AddNewQuickAnswer;
