import * as yup from 'yup';

const pwdRule = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}$/;

const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      pwdRule,
      `Must contain 8 characters, one uppercase, one lowercase, one number and one special case character.`,
    )
    .required('New password is required'),
  confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirm password must match')
    .required('Confirm password is required'),
});

export default ResetPasswordSchema;
