import React from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { onChangeDataAdvPrice } from '../redux/actions';
import TableAdvPrice from './TableAdvPrice';
import { Input } from '~/components/common/Input';

const AdvPrice = ({ formik }: any) => {
  const dispatch = useAppDispatch();
  const { dataAdvPrice } = useAppSelector((state) => state.createEditProductReducer);
  const { special_price, special_from_date, cost, special_to_date } = dataAdvPrice;
  const { errors, touched } = formik;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangeDataAdvPrice({ ...dataAdvPrice, [e.target.name]: e.target.value }));
  };

  function renderCalendar(name: string, value: string, title: string) {
    return (
      <div className="d-flex flex-column">
        <label htmlFor={name} className="title fs-6 fw-normal m-0 mb-3 cursor-pointer">
          {title}
        </label>

        <div>
          <Input
            type="date"
            id={name}
            name={name}
            value={value?.split(' ')[0] || ''}
            onChange={(e) => {
              handleChange(e);
              formik.handleChange(e);
            }}
          />

          {errors[name as keyof typeof errors] && (
            <p className="text-danger fs-14 m-0 mt-2 message text-start">{errors[name as keyof typeof errors]}</p>
          )}
        </div>
      </div>
    );
  }

  function renderInput(name: string, value: any, title: string) {
    return (
      <div className="d-flex flex-column">
        <label htmlFor={name} className="title fs-6 fw-normal m-0 mb-3 cursor-pointer">
          {title}
        </label>

        <div>
          <Input
            type="number"
            id={name}
            name={name}
            value={value || ''}
            onChange={(e) => {
              handleChange(e);
              formik.setFieldTouched(name, false, false);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />

          {errors[name as keyof typeof errors] && touched[name as keyof typeof errors] && (
            <p className="text-danger fs-14 m-0 mt-2 message text-start">{errors[name as keyof typeof errors]}</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-adv-price">
        {renderInput('special_price', special_price, 'Special Price')}
        {renderCalendar('special_from_date', special_from_date || '', 'Start Date')}
        {renderInput('cost', cost, 'Cost')}
        {renderCalendar('special_to_date', special_to_date || '', 'End Date')}
      </div>
      <div className="mt-4">
        <h3 className="fs-6 fw-medium m-0 mb-3">Customer Group Price</h3>
        <TableAdvPrice formik={formik} />
      </div>
    </>
  );
};

export default AdvPrice;
