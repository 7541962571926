import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirstLetter } from '~/app/utils';

export const formatFilterActive = (label: string, value: any, resetHandler: any) =>
  value && (
    <div className="filter-active" key={label}>
      <span className="fw-medium me-1 text-capitalize">{label}: </span>
      <div>
        <span className="fw-light me-1">{value}</span>
        <FontAwesomeIcon className="btn btn-gray reset-filter-active fs-7 rounded-circle" icon={faXmark} onClick={resetHandler} />
      </div>
    </div>
  );

export const formatFilterStatus = (value: string) => {
  // if (value === 'fraud') {
  //   return 'Suspected Fraud';
  // } else if (value === 'holded') {
  //   return 'On Hold';
  // } else if (value === 'payment_review') {
  //   return 'Payment Review';
  // } else if (value === 'paypal_canceled_reversal') {
  //   return 'Paypal Canceled Reversal';
  // } else if (value === 'paypal_reversed') {
  //   return 'Paypal Reversed';
  // } else if (value === 'pending_payment') {
  //   return 'Pending Payment';
  // } else if (value === 'pending_paypal') {
  //   return 'Pending Paypal';
  // } else if (value === 'processing') {
  //   return 'Processing';
  // } else if (value === 'pending') {
  //   return 'Pending';
  // } else if (value === 'complete') {
  //   return 'Complete';
  // } else if (value === 'closed') {
  //   return 'Closed';
  // } else if (value === 'canceled') {
  //   return 'Canceled';
  if (value === 'new') {
    return 'New';
  } else if (value === 'preparing') {
    return 'Preparing';
  } else if (value === 'shipped') {
    return 'Shipped';
  } else if (value === 'completed') {
    return 'Completed';
  } else if (value === 'declined') {
    return 'Declined';
  } else if (value === 'canceled') {
    return 'Canceled';
  } else if (value === 'pending_payment') {
    return 'Pending Payment';
  } else {
    return capitalizeFirstLetter(value);
  }
};

export const formatStatus = (status: string) => {
  if (!status) return;
  let statusFlag = 'warning';
  if (['closed', 'canceled', 'cancelled', 'declined'].includes(status)) statusFlag = 'danger';

  if (['processing', 'complete', 'completed', 'new'].includes(status)) statusFlag = 'success';

  return <span className={`table-status table-status-${statusFlag} text-capitalize fw-medium`}>{formatFilterStatus(status)}</span>;
};

export const formatEnableStatus = (status: number) => {
  if (status === 1) {
    return <span className="text-success text-capitalize fw-medium">Enabled</span>;
  } else {
    return <span className="text-danger text-capitalize fw-medium">Disabled</span>;
  }
};

export const formatType = (type: string) => {
  if (type === 'simple') {
    return <span className="text-capitalize fw-light">simple product</span>;
  } else if (type === 'configurable') {
    return <span className="text-capitalize fw-light">configurable product</span>;
  } else if (type === 'grouped') {
    return <span className="text-capitalize fw-light">grouped product</span>;
  } else if (type === 'bundle') {
    return <span className="text-capitalize fw-light">bundle product</span>;
  } else if (type === 'virtual') {
    return <span className="text-capitalize fw-light">virtual product</span>;
  } else if (type === 'downloadable') {
    return <span className="text-capitalize fw-light">downloadable product</span>;
  }
};

export const formatVisibility = (type: number) => {
  if (type === 1) {
    return <span className="text-capitalize fw-light">not visible individually</span>;
  } else if (type === 2) {
    return <span className="text-capitalize fw-light">catalog</span>;
  } else if (type === 3) {
    return <span className="text-capitalize fw-light">search</span>;
  } else if (type === 4) {
    return (
      <span className="text-capitalize fw-light">
        catalog, <br /> search
      </span>
    );
  }
};

export const formatFilterVisibility = (type: string) => {
  if (type === '1') {
    return <span className="text-capitalize fw-light">not visible individually</span>;
  } else if (type === '2') {
    return <span className="text-capitalize fw-light">catalog</span>;
  } else if (type === '3') {
    return <span className="text-capitalize fw-light">search</span>;
  } else if (type === '4') {
    return <span className="text-capitalize fw-light">catalog, search</span>;
  }
};

export const formatFilterEnableStatus = (status: string) => {
  if (status === '1') {
    return 'Enabled';
  }
  if (status === '2') {
    return 'Disabled';
  }
};

export const formatFilterEnableStatusTicket = (status: string) => {
  if (status === '1') {
    return 'Enabled';
  }
  if (status === '0') {
    return 'Disabled';
  }
};

export const formatFilterPriority = (priority_id: string) => {
  if (priority_id === '0') {
    return <span className="text-capitalize fw-light">Low</span>;
  } else if (priority_id === '1') {
    return <span className="text-capitalize fw-light">Medium</span>;
  } else if (priority_id === '2') {
    return <span className="text-capitalize fw-light">High</span>;
  } else if (priority_id === '3') {
    return <span className="text-capitalize fw-light">Ugent</span>;
  }
};
