import { iCreateUser, iForgotPwd, iLogin, iResetPwd } from '../models';
import axiosConfig, { config } from './axiosConfig';

const authApi = {
  login: (payload: iLogin) => {
    return axiosConfig.post('all/V1/addin/vendor/token', payload);
  },
  verifyToken: (accessToken: string) => {
    return axiosConfig.get('all/V1/addin/user/me', config(accessToken));
  },
  forgotPwd: (payload: iForgotPwd) => {
    return axiosConfig.post('all/V1/addin/user/forgotpassword', payload);
  },
  resetPwd: (payload: iResetPwd) => {
    return axiosConfig.post('all/V1/addin/user/resetpassword', payload);
  },
  createUser: (payload: iCreateUser) => {
    return axiosConfig.post('all/V1/addin/user/register', payload);
  },
  fetchStores: () => {
    return axiosConfig.get('all/V1/store/websites');
  },
};

export default authApi;
