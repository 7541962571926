import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { iCreateUser } from '~/app/models';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { clearMessage, createUser, fetchStores } from '~/components/layout/FullWidthLayout/redux/actions';
import ButtonForm from '~/components/common/ButtonForm';
import FormWrapper from '~/components/common/FormWrapper';
import Loading from '~/components/common/Loading';
import FullWidthLayout from '~/components/layout/FullWidthLayout';
import CreateAccountSuccess from './CreateAccountSuccess';
import SignUpSchema from './Schema';
import TermsAndConditions from './TermsAndConditions';

import './style.scss';
import { TextField } from '~/components/common/Input';

const SignUp: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkAccept, setCheckAccept] = useState<boolean>(false);

  const { status, message, stores } = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    // Remove message when reload page
    status === 'rejected' && message && dispatch(clearMessage());
    resetForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchStores());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, resetForm } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      password_confirmation: '',
      website_id: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      const payload: iCreateUser = {
        data: values,
      };

      dispatch(createUser(payload));
    },
  });

  const dataInput = [
    {
      name: 'firstname',
      label: 'First Name *',
      type: 'text' as const,
      value: values.firstname,
      textError: errors.firstname,
      error: errors.firstname && touched.firstname,
      w50: true,
    },
    {
      name: 'lastname',
      label: 'Last Name *',
      type: 'text' as const,
      value: values.lastname,
      textError: errors.lastname,
      error: errors.lastname && touched.lastname,
      w50: true,
    },
    {
      name: 'website_id',
    },
    {
      name: 'email',
      label: 'Email *',
      type: 'text' as const,
      value: values.email,
      textError: errors.email,
      error: errors.email && touched.email,
    },
    {
      name: 'password',
      label: 'Password *',
      type: 'password' as const,
      value: values.password,
      textError: errors.password,
      error: errors.password && touched.password,
    },
    {
      name: 'password_confirmation',
      label: 'Confirm Password *',
      type: 'password' as const,
      value: values.password_confirmation,
      textError: errors.password_confirmation,
      error: errors.password_confirmation && touched.password_confirmation,
    },
  ];

  const filteredStores = stores.filter((store) => store.name !== 'Admin');
  const renderListInput = () => {
    return dataInput.map((input: any) => {
      const { name, label = '', type = 'text', value = '', error, textError, w50 } = input;

      if (name === 'website_id') {
        return (
          <div className="ms-0 input-list row custom-gap" key={name}>
            <select
              id="store"
              name="website_id"
              onChange={handleChange}
              className={`px-4 py-3 store-select fs-6 ${values.website_id ? 'text-dark' : 'text-dark-600'} ${
                errors.website_id && touched.website_id ? 'error' : ''
              }`}
            >
              <option value="">Choose a store</option>
              {filteredStores.length &&
                filteredStores.map((store: any) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
            </select>
            {errors.website_id && touched.website_id && <p className="input-field__text-error text-danger m-0 ps-0">{errors.website_id}</p>}
          </div>
        );
      }

      return (
        <div className={`col-sm-${w50 ? '6' : '12'}`} key={name}>
          <TextField
            type={type}
            name={name}
            value={value}
            label={label}
            error={!!error}
            textError={textError}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              setFieldTouched(name, false, false);
            }}
          />
        </div>
      );
    });
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };
  const renderMessage = () => {
    return status === 'rejected' && message && <p className="message m-0 mt-3 text-danger text-center">{message}</p>;
  };
  return (
    <FullWidthLayout pageTitle="Sign Up">
      <form className="sign-up d-flex flex-column align-items-center" onSubmit={handleSubmit}>
        {status === 'fulfilled' ? (
          <CreateAccountSuccess />
        ) : (
          <FormWrapper title="Create New Account" backLogin>
            <h6 className="sub-title text-center fw-normal">
              <span>Already have an account, </span>
              <Link to="/auth/sign-in" className="text-decoration-none link">
                click here to login.
              </Link>
            </h6>

            {status === 'pending' ? (
              <div style={{ marginTop: '-16px' }}>
                <Loading />
              </div>
            ) : (
              <>
                <div className="input-list row custom-gap">{renderListInput()}</div>

                {renderMessage()}

                <div className="terms d-flex align-items-center mt-3">
                  <input
                    type="checkbox"
                    name=""
                    id="terms"
                    checked={checkAccept}
                    onChange={() => setCheckAccept(!checkAccept)}
                    className="cursor-pointer"
                  />
                  <label htmlFor="terms" className="cursor-pointer">
                    <span>I agree to the </span>
                    <span className="link" onClick={handleToggleModal}>
                      terms and conditions.{' '}
                    </span>
                    <span className="text-danger">*</span>
                  </label>
                </div>
              </>
            )}

            <ButtonForm disabled={status === 'pending' || !checkAccept}>CONTINUE</ButtonForm>
          </FormWrapper>
        )}
      </form>

      <>{showModal && <TermsAndConditions toggleModal={handleToggleModal} />}</>
    </FullWidthLayout>
  );
};

export default SignUp;
