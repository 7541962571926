import axiosConfig from './axiosConfig';

const userApi = {
  getInfo: (payload: any, store: string = 'all') => {
    const url = `${store}/V1/addin/user/me`;
    return axiosConfig.get(url);
  },

  updateInfo: (payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/addin/user/update`;
    return axiosConfig.post(url, payload);
  },

  deleteAvatar: (payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/addin/user/delete/avata`;
    return axiosConfig.post(url, payload);
  },
  getAllStores: (payload: any) => {
    const url = 'all/V1/store/storeViews';
    return axiosConfig.get(url);
  },
  getAllWebsites: (payload: any) => {
    const url = 'all/V1/store/websites';
    return axiosConfig.get(url);
  },
};

export default userApi;
