import moment from 'moment';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { convertGetPastDay, CURRENT_MONTH, CURRENT_YEAR, getPastDay, LAST_DAY_OF_MONTH, Moth } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { iBestSellerApi } from '~/app/models/dashboard';
import { formatMoney } from '~/app/utils';
import { NoRecord } from '../Err';
import { getDataBestSeller } from './redux/actions';

import './style.scss';
const HEADER_DATA: iTableHead[] = [
  {
    name: 'Product Name',
    className: 'text-start w-75',
  },
  {
    name: 'Price',
    className: 'text-end w-12-5',
  },
  {
    name: 'Qty',
    className: 'text-end w-12-5',
  },
];
interface Props {
  children: JSX.Element | JSX.Element[];
  id: any;
  onChangeStore: (id: number) => void;
}

const BestSeller = (props: Props) => {
  const { children, id, onChangeStore } = props;

  const dispatch = useAppDispatch();

  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { bestSales, statusBestSeller } = useAppSelector((s) => s.dashboardReducer);

  const { items } = bestSales;

  const currentDate = convertGetPastDay(getPastDay(1)[0]);
  const aWeekAgo = convertGetPastDay(getPastDay(8)[7]);

  useEffect(() => {
    // 7 Days
    if (id === 1) {
      const payload: iBestSellerApi = {
        accessToken,
        currentStore,
        to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
        from: moment(new Date(aWeekAgo)).format('YYYY-MM-DD'),
      };

      dispatch(getDataBestSeller(payload));
    }
    // Last Month
    if (id === 2) {
      const payload: iBestSellerApi = {
        accessToken,
        currentStore,
        to: `${CURRENT_YEAR}-${CURRENT_MONTH}-${LAST_DAY_OF_MONTH(+CURRENT_MONTH as Moth)}`,
        from: `${CURRENT_YEAR}-${CURRENT_MONTH}-01`,
      };
      dispatch(getDataBestSeller(payload));
    }
    // This Year
    if (id === 3) {
      const payload: iBestSellerApi = {
        accessToken,
        currentStore,
        to: `${CURRENT_YEAR}-12-31`,
        from: `${CURRENT_YEAR}-01-01`,
      };
      dispatch(getDataBestSeller(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, accessToken]);

  useEffect(() => {
    const payload: iBestSellerApi = {
      accessToken,
      currentStore,
      to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
      from: moment(new Date(aWeekAgo)).format('YYYY-MM-DD'),
    };
    onChangeStore(1);
    dispatch(getDataBestSeller(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          {HEADER_DATA.map((item, index) => {
            const { className, name } = item;
            return (
              <th className={`${className} fw-semibold`} key={index}>
                {name}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  function renderTableBody() {
    return (
      <tbody>
        {items.length > 0 ? (
          items.map((item: any, i: number) => {
            const { product_name, product_price, qty_ordered } = item;

            return (
              <tr key={i}>
                <td>{product_name}</td>
                <td className="text-end">{formatMoney(+product_price, currentStore)}</td>
                <td className="text-end">{+qty_ordered}</td>
              </tr>
            );
          })
        ) : (
          <NoRecord tableHeaders={HEADER_DATA} />
        )}
      </tbody>
    );
  }

  function renderSkeleton() {
    const skeletonArr = new Array(8);
    // Assign 1 to use the Array.map method
    skeletonArr.fill(1);

    return skeletonArr.map((_, index) => (
      <div key={index} className="pb-3 mb-1">
        <Skeleton height={48} width={`${Math.floor(Math.random() * 70) + 30}%`} />
      </div>
    ));
  }

  return (
    <div className="best-seller d-flex flex-column h-100">
      {children}

      <div className="content-wrap bg-white flex-grow-1 py-4">
        <table className="w-100 px-4" id="best-seller-table">
          {renderTableHeader()}
          {statusBestSeller !== 'pending' ? (
            renderTableBody()
          ) : (
            <tbody>
              <tr>
                <td>{renderSkeleton()}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default BestSeller;
