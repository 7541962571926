export const STATUS: { value: string; title: string }[] = [
  { value: '0', title: 'Any' },
  { value: 'canceled', title: 'Canceled' },
  { value: 'closed', title: 'Closed' },
  { value: 'complete', title: 'Complete' },
  { value: 'fraud', title: 'Suspected Fraud' },
  { value: 'holded', title: 'On Hold' },
  { value: 'payment_review', title: 'Payment Review' },
  { value: 'paypal_canceled_reversal', title: 'PayPal Canceled Reversal' },
  { value: 'paypal_reversed', title: 'PayPal Reversed' },
  { value: 'processing', title: 'Processing' },
];

export const convertStatus: { [key: string]: string } = {
  canceled: 'Canceled',
  '0': 'Any',
  closed: 'Closed',
  complete: 'Complete',
  fraud: 'Suspected Fraud',
  holded: 'On Hold',
  payment_review: 'Payment Review',
  paypal_canceled_reversal: 'PayPal Canceled Reversal',
  paypal_reversed: 'PayPal Reversed',
  processing: 'Processing',
};

export const keysName: { [key: string]: string } = {
  from: 'Filter Date',
  period: 'Period Type',
  status: 'Status',
  to: 'To',
};

