import { createSlice } from '@reduxjs/toolkit';
import { iInitState } from '~/app/models';

interface iState extends iInitState {
  data: any;
  dataDetails: any;
  message: string;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  queryString: string;
  isDESC: boolean;
  keySearch: string;
  controller: any;
}

const initialState: iState = {
  status: '',
  controller: null,
  data: {
    items: [],
    search_criteria: {},
  },
  urlData: {
    pageSize: 10,
    currentPage: 1,
  },
  dataDetails: {},
  message: '',
  isDESC: false,
  keySearch: 'entity_id',
  queryString: '',
};

const productSlice = createSlice({
  name: 'PRODUCT LIST',
  initialState,
  reducers: {
    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),
    changeController: (state, action) => ({ ...state, controller: action.payload }),
  },
});

export const { ...rest } = productSlice.actions;
const productReducer = productSlice.reducer;

export default productReducer;
