import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

// Auth
import Auth from '~/components/pages/Auth';
import SignIn from '~/components/pages/Auth/SignIn';
import SignUp from '~/components/pages/Auth/SignUp';
import ForgotPassword from '~/components/pages/Auth/ForgotPassword';
import ResetPassword from '~/components/pages/Auth/ResetPassword';

import PrivateRoute from '~/components/common/PrivateRoute';
import DashBoard from '~/components/pages/DashBoard';
import Order from '~/components/pages/Orders';
// import Overview from '~/components/pages/Account/Overview';
import Setting from '~/components/pages/Account/Settings';
import Account from '~/components/pages/Account';
import NotFoundPage from '~/components/pages/NotFoundPage';
import OrderDetails from '~/components/pages/Orders/Details';
// import Shipment from '~/components/pages/Orders/Details/Shipment';
import Information from '~/components/pages/Orders/Details/Information';
// import Invoice from '~/components/pages/Orders/Details/Invoice';
// import CreditMemo from '~/components/pages/Orders/Details/CreditMemo';
import SalesReport from '~/components/pages/SalesReport';
import ProductsListing from '~/components/pages/ProductsListing';
import Transit from '~/components/pages/Auth/Transit';
import CreateEditProduct from '~/components/pages/CreateEditProduct';
import History from '~/components/pages/Orders/Details/History';

import GeneralReport from '~/components/pages/SalesReport/GeneralReport';
import TicketListing from '~/components/pages/TicketListing';
import TicketDetails from '~/components/pages/TicketListing/Details';
import { useAppSelector } from '~/app/hooks/hooks';

const AppRoutes: React.FC = () => {
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);

  return (
    <Suspense>
      <Routes>
        {/* Public router */}
        <Route path="/auth" element={<Auth />}>
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="transit" element={<Transit />} />
        </Route>

        {/* Private router */}
        <Route element={<PrivateRoute />}>
          <Route index element={<Navigate to={'dashboard'} />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="tickets" element={<TicketListing />} />
          <Route path="tickets/:ticketId" element={<TicketDetails />} />
          <Route path="orders/" element={<Order />} />
          <Route path="orders/:orderId" element={<OrderDetails />}>
            <Route index element={<Navigate to={'information'} />} />
            <Route path="information" element={<Information />} />
            {/* <Route path="shipments" element={<Shipment />} />
            <Route path="shipments/:entity_id" element={<Information isShippingInfo={true} />} />
            <Route path="invoice" element={<Invoice />} />
            <Route path="invoice/:entity_id" element={<Information isInvoiceInfo={true} />} />
            <Route path="credit-memos" element={<CreditMemo />} />
            <Route path="credit-memos/:entity_id" element={<Information isMemosInfo={true} />} /> */}
            <Route path="history" element={<History />} />
          </Route>
          <Route path="/profile" element={<Account />}>
            <Route index element={<Navigate to="settings" />} />
            {/* <Route path="overview" element={<Overview />} /> */}
            <Route path="settings" element={<Setting />} />
          </Route>
          <Route path="/sales-report" element={<SalesReport />}>
            <Route index element={<Navigate to={'orders-report'} />} />
            <Route path="orders-report" element={<GeneralReport type="order" />} />
            {/* <Route path="customers-report" element={<GeneralReport type="customer" />} /> */}
            {/* <Route path="invoices-report" element={<GeneralReport type="invoice" />} /> */}
            <Route path="products-report" element={<GeneralReport type="product" />} />
            {/* <Route path="refunds-report" element={<GeneralReport type="refund" />} /> */}
          </Route>
          <Route path="/products" element={<ProductsListing />} />
          <Route path="product" element={<Navigate to={`/products/${productQuery}`} />} />
          <Route path="/product/create" element={<Outlet />}>
            <Route index element={<CreateEditProduct type="simple" action="create" />} />
            <Route path="simple" element={<CreateEditProduct type="simple" action="create" />} />
            <Route path="config" element={<CreateEditProduct type="config" action="create" />} />
          </Route>
          <Route path="/product/edit/:idProduct" element={<CreateEditProduct action="edit" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
