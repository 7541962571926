import { iBestSellerApi, iProductSoldDashBoard, iTotalSalesDashBoard } from '../models/dashboard';
import axiosConfig from './axiosConfig';

const dashboardApi = {
  getOverviewDashboard: (payload: any) => {
    const { currentStore = 'all', to } = payload;
    const url = `${currentStore}/V1/report/reportseller?&criteria%5Bto%5D=${to}`;
    return axiosConfig.get(url);
  },
  getTotalSales: (payload: iTotalSalesDashBoard) => {
    const { currentStore = 'all' } = payload;
    const url = `${currentStore}/V1/report/totalsale`;
    return axiosConfig.get(url);
  },
  getBestSeller: (payload: iBestSellerApi) => {
    const { from, to, currentStore = 'all', pageSize = 10 } = payload;
    const url = `${currentStore}/V1/products/bestsellers?searchCriteria%5BpageSize%5D=${pageSize}&searchCriteria%5Bfrom%5D=${from}&searchCriteria%5Bto%5D=${to}
    `;
    return axiosConfig.get(url);
  },
  getProductSold: (payload: iProductSoldDashBoard) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/productsold?${searchUrl}`;
    return axiosConfig.get(url);
  },
  getSalesStatics: (payload: iProductSoldDashBoard) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/salesstatis?${searchUrl}`;
    return axiosConfig.get(url);
  },
  getOrderDashBoard: (payload: iProductSoldDashBoard) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/orderdashboard?${searchUrl}`;
    return axiosConfig.get(url);
  },
};
export default dashboardApi;
