import { useAppSelector } from '~/app/hooks/hooks';
import {
  IDateRangeFilterProps,
  INumberFilterProps,
  ITextFilterProps,
  Props,
  SelectFilterProps,
  WebsiteFilterProps,
  IDateInputProps,
  ResetFilter,
} from '~/app/models';
import { Input } from '../Input';
import Select from '../Select';

const NumberFromToFilter: React.FC<INumberFilterProps> = ({ filterClass, label, name, value, onChange }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-price-filter ps-1">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12 d-flex align-items-center">
          <span className="col-2 text-dark-600 fw-normal fs-14">from</span>
          <Input className="w-100" type="number" value={value[`${name}From`]} name={`${name}From`} onChange={onChange} onlyInteger />
        </div>
        <div className="col-lg-12 d-flex align-items-center mt-3">
          <span className="col-2 text-dark-600 fw-normal fs-14">to</span>
          <Input className="w-100" type="number" value={value[`${name}To`]} name={`${name}To`} onChange={onChange} onlyInteger />
        </div>
      </div>
    </div>
  );
};

const TextFilter: React.FC<ITextFilterProps> = ({ label, name, value, onChange, filterClass }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-name-filter ps-1">
        <label htmlFor={name} className="w-fit-content fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12">
          <Input className="w-100" type="text" name={name} value={value} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

const NumberFilter: React.FC<ITextFilterProps> = ({ label, name, value, onChange, filterClass }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-name-filter ps-1">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12">
          <Input className="w-100" type="number" value={value} name={name} onChange={onChange} onlyInteger />
        </div>
      </div>
    </div>
  );
};

const SelectFilter: React.FC<SelectFilterProps> = ({ label, name, options, value, onChange, filterClass }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-status-filter">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12">
          <Select className="w-100" name={name} value={value} onChange={onChange}>
            <>
              {options.length > 0 &&
                options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </>
          </Select>
        </div>
      </div>
    </div>
  );
};

const DateRangeFilter: React.FC<IDateRangeFilterProps> = ({ filterClass, label, name, value, onChange }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-price-filter ps-1">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12 d-flex align-items-center">
          <span className="col-2 text-dark-600 fw-normal fs-14">from</span>
          <Input className="w-100" type="date" name={`${name}From`} value={value[`${name}From`]} onChange={onChange} />
        </div>
        <div className="col-lg-12 d-flex align-items-center mt-3">
          <span className="col-2 text-dark-600 fw-normal fs-14">to</span>
          <Input className="w-100" type="date" name={`${name}To`} value={value[`${name}To`]} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

const DateInput: React.FC<IDateInputProps> = ({ filterClass, label, name, value, onChange }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <label htmlFor="" className="w-fit-content fw-medium text-dark pb-2 fs-14">
        {label}
      </label>
      <div className="row flex-column gap-3 flex-lg-row gap-lg-0">
        <div className="col-6">
          <div className="row align-items-center">
            <span className="text-lg-end fs-14 text-dark-600 col-3 col-xxl-2">from</span>
            <div className="col-9 col-xxl-10">
              <Input type="date" name={`${name}_from`} className="flex-grow-1" value={value[`${name}_from`] || ''} onChange={onChange} />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row align-items-center">
            <span className="text-lg-end fs-14 text-dark-600 col-3 col-xxl-2">to</span>
            <div className="col-9 col-xxl-10">
              <Input type="date" name={`${name}to`} className="flex-grow-1" value={value[`${name}to`] || ''} onChange={onChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterWebsite: React.FC<WebsiteFilterProps> = ({ filterClass, value, onChange }) => {
  const { data: userData } = useAppSelector((s) => s.userReducer);
  const { website_ids } = userData;
  const websiteList = JSON.parse(website_ids || '{}');
  const convertWebsiteList = Object.keys(websiteList).map((key) => websiteList[key]);

  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-type-filter ps-1">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          Website
        </label>
        <div className="col-lg-12">
          <Select name="website" onChange={(e: any) => onChange(e)} value={value}>
            <option value=""></option>
            <>
              {convertWebsiteList.length > 0 &&
                convertWebsiteList.map((item: any, i: number) => {
                  return (
                    <option value={i + 1} key={i}>
                      {item.name}
                    </option>
                  );
                })}
            </>
          </Select>
        </div>
      </div>
    </div>
  );
};

const FilterButtons: React.FC<Props> = ({ onSubmit, status, setIsEnableFilter }) => {
  return (
    <div className="action mt-3 me-2 text-end">
      <span className="btn-cancel cursor-pointer me-3" onClick={() => setIsEnableFilter(false)}>
        Cancel
      </span>
      <button className="btn btn-primary px-4 py-2 fw-medium border-0 ms-2 fs-6" onClick={onSubmit} disabled={status === 'pending'}>
        Apply
      </button>
    </div>
  );
};

const ResetFilterButton: React.FC<ResetFilter> = ({ onClick }) => {
  return (
    <button className="btn px-3 py-2 fw-medium border-0 fs-6 reset-filter" onClick={onClick}>
      Reset Filters
    </button>
  );
};

export {
  NumberFromToFilter,
  NumberFilter,
  TextFilter,
  SelectFilter,
  DateRangeFilter,
  DateInput,
  FilterWebsite,
  FilterButtons,
  ResetFilterButton,
};
