import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

type Props = {};

export const ErrorPage = (props: Props) => {
  return (
    <div className="text-center bg-white d-flex flex-column align-items-center p-4 gap-4">
      <p className="m-0 fs-6 fw-medium text-danger">Sorry we got some Error</p>
      <Link className="text-decoration-none fs-6 d-flex align-items-center fw-medium gap-12 link" to="/dashboard">
        <FontAwesomeIcon icon={faArrowLeft} />
        Back to Dashboard
      </Link>
    </div>
  );
};
