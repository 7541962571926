import { faArrowLeft, faArrowRight, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import CustomCheckBox from '~/components/common/CustomCheckBox';
import {
  allowedToLogin,
  actionChangeStore,
  logOutAction,
  actionChangeWebCurrency,
  actionChangeStoreName,
} from '~/components/layout/FullWidthLayout/redux/actions';
import { getUser } from '../../Account/redux/action';
import img from '~/app/images/transit1.png';

import './style.scss';
import { resetOrderQueryString } from '../../Orders/redux/actions';
import { resetProductQueryString } from '../../ProductsListing/redux/action';
import { reportReset, typeReportReset } from '../../SalesReport/redux/actions';
interface Props {
  isLogin: boolean;
  toggleModal?: any;
}

interface iStore {
  code: string;
  name: string;
}

interface iStoreWebsite {
  code: string;
  name: string;
  stores: iStore[];
}

const TransitPopup = (props: Props) => {
  const { isLogin, toggleModal } = props;
  const dispatch = useAppDispatch();
  const { currentStore, lastPathName, accessToken, currentStoreName } = useAppSelector((s) => s.authReducer);
  const { data } = useAppSelector((s) => s.userReducer);
  const { website_ids } = data;
  const [store, setStore] = useState<string>(currentStore);
  const [storeName, setStoreName] = useState<string>(currentStoreName);

  const websiteList = JSON.parse(website_ids || '{}');
  // convert object into object to list object
  const convertWebsiteList: iStoreWebsite[] = Object.keys(websiteList).map((key) => websiteList[key]);

  const onChangeHandler = (e: React.ChangeEvent<{ value: string }>, item: iStore) => {
    const { value } = e.target;

    if (!isLogin) {
      setStore(value);
      setStoreName(value);
    }

    dispatch(actionChangeWebCurrency(item.code));
    setStore(value);
    setStoreName(item.name);
  };

  const { pathname } = useLocation();

  const arrPathName = pathname.split('/');

  useEffect(() => {
    if (!isLogin && !Object.keys(data).length) {
      const payload = { accessToken };
      dispatch(getUser(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const onClickHandler = () => {
    if (store) {
      dispatch(actionChangeStore(store));
      dispatch(actionChangeStoreName(storeName));
      dispatch(allowedToLogin());
      navigate(lastPathName);
    }
  };

  const backToLogin = () => {
    dispatch(logOutAction(navigate));
    dispatch(actionChangeStore(''));
    localStorage.clear();
  };

  const continueEvent = () => {
    if (arrPathName.includes('profile')) {
      navigate(pathname);
    } else {
      navigate(`${arrPathName[1] ? '/' + arrPathName[1] : '/dashboard'}`);
    }
    dispatch(actionChangeStore(store));
    dispatch(actionChangeStoreName(storeName));
    dispatch(resetOrderQueryString());
    dispatch(resetProductQueryString());
    toggleModal();

    const reportResetArr: typeReportReset[] = ['customer', 'order', 'refund', 'product', 'invoice'];
    reportResetArr.forEach((item) => dispatch(reportReset(item)));
  };

  return (
    <div className="transit d-flex bg-white justify-content-start align-items-center overflow-hidden">
      <div className="transit-item h-100 d-flex justify-content-between flex-column h-100">
        <div className="transit-header">
          <p className="fs-4 fw-semibold text-uppercase mb-0">CHOOSE YOUR STORE</p>
        </div>
        <div className="transit-content d-flex align-items-start flex-column flex-wrap">
          {convertWebsiteList.length > 0 ? (
            convertWebsiteList.map((item: any, i: number) => {
              if (item.stores.length <= 1) {
                return (
                  <CustomCheckBox
                    className="mb-2"
                    circle
                    key={i}
                    checked={store === item.stores[0].code}
                    name={item.name}
                    label={item.name}
                    value={item.stores[0].code}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => onChangeHandler(e, item)}
                    checkBoxClass="fs-6 me-4"
                  />
                );
              } else {
                return item.stores.map((value: any, index: number) => {
                  return (
                    <CustomCheckBox
                      className="mb-2"
                      circle
                      key={index}
                      checked={store === value.code}
                      name={value.name}
                      label={value.name}
                      value={value.code}
                      onChange={(e: React.ChangeEvent<{ value: string }>) => onChangeHandler(e, value)}
                      checkBoxClass="fs-6 pe-4"
                    />
                  );
                });
              }
            })
          ) : (
            <span className="fs-14">Your account hasn't been activated yet</span>
          )}
        </div>
        {!isLogin ? (
          <div className="">
            <button className="border-0 submit-button bg-primary fs-14 fw-semibold " onClick={backToLogin}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back To Login
            </button>
            <button
              className={`border-0 submit-button bg-primary fs-14 fw-semibold ms-3 ${store ? '' : 'd-none'}`}
              onClick={onClickHandler}
            >
              <FontAwesomeIcon icon={faArrowRight} />
              Submit
            </button>
          </div>
        ) : (
          <button className={`border-0 submit-button bg-primary fs-14 fw-semibold w-100`} onClick={continueEvent}>
            <FontAwesomeIcon icon={faArrowRight} />
            Continue
          </button>
        )}
      </div>
      <img src={img} alt="" />
      {isLogin && (
        <span className="esc-btn cursor-pointer" onClick={toggleModal}>
          <FontAwesomeIcon icon={faX} />
        </span>
      )}
    </div>
  );
};

export default TransitPopup;
