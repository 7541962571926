import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';

import { iForgotPwd } from '~/app/models';
import ButtonForm from '~/components/common/ButtonForm';
import FormWrapper from '~/components/common/FormWrapper';
import Loading from '~/components/common/Loading';
import FullWidthLayout from '~/components/layout/FullWidthLayout';
import { clearMessage, forgotPwdByEmail } from '~/components/layout/FullWidthLayout/redux/actions';
import ForgotPasswordSchema from './Schema';

import './style.scss';
import { TextField } from '~/components/common/Input';

const ForgotPassword: FC = () => {
  const { status, message } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    // Remove message when reload page
    message && dispatch(clearMessage());
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, resetForm } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      const payload: iForgotPwd = {
        users: [values],
      };

      dispatch(forgotPwdByEmail(payload));
    },
  });

  const dataInput = [
    {
      name: 'email',
      label: 'Email',
      type: 'text' as const,
      value: values.email,
      textError: errors.email,
      error: errors.email && touched.email,
    },
  ];

  const renderListInput = () => {
    return dataInput.map((input) => {
      const { name, label, type, value, error, textError } = input;

      return (
        <TextField
          key={name}
          type={type}
          name={name}
          value={value}
          label={label}
          error={!!error}
          textError={textError}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setFieldTouched(name, false, false);
          }}
        />
      );
    });
  };

  const renderMessage = () => {
    if (message && status === 'rejected') {
      return <p className={`message m-0 mt-3 text-center text-danger`}>{message}</p>;
    }

    return message && status === 'fulfilled' && <p className={`message m-0 mt-3 text-center text-success`}>{message}</p>;
  };

  return (
    <FullWidthLayout pageTitle="Forgot Password">
      <form className="forgot-password d-flex flex-column align-items-center" onSubmit={handleSubmit}>
        <FormWrapper title="FORGOT PASSWORD" backLogin>
          <h6 className="sub-title text-center fw-normal mb-3">
            <span>Enter your email to reset your password.</span>
          </h6>

          {status === 'pending' ? <Loading /> : <div className="input-list">{renderListInput()}</div>}

          <>{renderMessage()}</>

          <ButtonForm disabled={status === 'pending' || status === 'fulfilled'}>CONTINUE</ButtonForm>
        </FormWrapper>
      </form>
    </FullWidthLayout>
  );
};

export default ForgotPassword;
