import { faChevronDown, faChevronUp, faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Button from '~/components/common/Button';
import Step1 from './Step1';
import Step2 from './Step2';
import * as yup from 'yup';

import './style.scss';
import { onChangeDataAttr, onChangeListProductAssociated, onChangeProductGenerate } from '../../redux/actions';
import Table from './Table';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import Pagination from '~/components/common/Pagination';
import TableProductAssociate from './TableProductAssociate';

interface iPagination {
  pageSize: number;
  currentPage: number;
}

const Configurations = ({ formik: formikHeader }: any) => {
  const dispatch = useAppDispatch();
  const { dataValuesConfigSelected, dataProductGenerate, dataAttrList, dataRelatedAttrByAttrSet, listProductAssociated } = useAppSelector(
    (state) => state.createEditProductReducer,
  );

  const [showPopup, setShowPopup] = useState<boolean | undefined>();
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [backStep, setBackStep] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [showListAssociatedProduct, setShowListAssociatedProduct] = useState<boolean>(true);
  const [isClickCreateConfigurations, setIsClickCreateConfigurations] = useState<boolean>(false);
  const [listProductGenerateCurrentPage, setListProductGenerateCurrentPage] = useState<any[]>([]);
  const [pagination, setPagination] = useState<iPagination>({
    pageSize: 10,
    currentPage: 1,
  });

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: yup.object().shape(
      Object.fromEntries(
        dataProductGenerate
          .map((item: any) => {
            return [
              [`name${item.id}`, yup.string().required('This field is required').max(255, 'This field maximum 255 characters')],
              [
                `sku${item.id}`,
                yup.string().required('This field is required').max(64, 'This field maximum 64 characters'),
                // .matches(
                //   /^[^:/.#!?&@%+~]*$/,
                //   "SKU can't contain the following characters: '~', '!', '@', '#', '%', '&', '+', ':', '.', '?', '/' or '\\'",
                // ),
              ],
              [
                `price${item.id}`,
                yup
                  .number()
                  .required('This field is required')
                  .min(0, 'Please enter a number 0 or greater, without comma in this field.')
                  .typeError('Please enter a number 0 or greater, without comma in this field.'),
              ],
              [
                `qty${item.id}`,
                yup
                  .number()
                  .min(0, 'Please enter a number 0 or greater, without comma in this field.')
                  .integer('Please enter an integer, without comma in this field.')
                  .typeError('Please enter a number 0 or greater, without comma in this field.'),
              ],
              // eslint-disable-next-line no-sparse-arrays
              [
                `weight${item.id}`,
                yup
                  .number()
                  .min(0, 'Please enter a number 0 or greater, without comma in this field.')
                  .typeError('Please enter a number 0 or greater, without comma in this field.'),
                ,
              ],
            ];
          })
          .flat(),
      ),
    ),
    onSubmit: () => {
      setShowPopup(false);
    },
  });

  useEffect(() => {
    if (backStep) {
      setBackStep(false);
      Object.keys(formik.errors).length === 0 && setStep((prev) => prev - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backStep]);

  useEffect(() => {
    isClickCreateConfigurations && setIsClickCreateConfigurations(false);

    if (isClickCreateConfigurations) {
      const fieldRequiredError = Object.entries(formikHeader.errors).filter((item) => ['price', 'name', 'sku'].includes(item[0]));

      if (fieldRequiredError.length > 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        setStep(1);
        setShowPopup(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickCreateConfigurations]);

  useEffect(() => {
    const sliceFromIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const sliceToIndex = sliceFromIndex + pagination.pageSize;
    const listProductGeneralCurrentPage = dataProductGenerate.slice(sliceFromIndex, sliceToIndex);

    setListProductGenerateCurrentPage(listProductGeneralCurrentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dataProductGenerate]);

  useEffect(() => {
    const newData = dataProductGenerate
      .map((item: any) => {
        return [
          [`name${item.id}`, item[`name${item.id}`]],
          [`sku${item.id}`, item[`sku${item.id}`]],
          [`price${item.id}`, item[`price${item.id}`]],
          [`qty${item.id}`, item[`qty${item.id}`]],
          [`weight${item.id}`, item[`weight${item.id}`]],
        ];
      })
      .flat();

    const listProduct = dataProductGenerate.map((product: any) => JSON.parse(JSON.stringify(product).replaceAll(`${product.id}":`, '":')));

    let listRemove: any[] = [];
    listProduct.forEach((product: any) => {
      listRemove = [...listRemove, ...Object.keys(product.attributes)];
    });
    listRemove = listRemove.filter((item: any, index: any) => listRemove.indexOf(item) === index);
    listRemove = dataRelatedAttrByAttrSet
      .filter((item: any) => listRemove.includes(item.default_frontend_label))
      .map((item: any) => item.attribute_code);
    const newListAttr = Object.fromEntries(Object.entries(dataAttrList).filter((item: any) => !listRemove.includes(item[0])));
    dispatch(onChangeDataAttr(newListAttr));

    formik.setValues(Object.fromEntries(newData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductGenerate]);

  useEffect(() => {
    // This useEffect using case step 1 -> step 2 and click close (remove if any fields invalid product)
    if (closePopup) {
      const listIdProductError = new Set([
        ...Object.keys(formik.errors)
          .join(', ')
          .replaceAll('name', '')
          .replaceAll('price', '')
          .replaceAll('sku', '')
          .replaceAll('qty', '')
          .replaceAll('weight', '')
          .split(', '),
      ]);

      if (Array.from(listIdProductError).filter(Boolean).length) {
        const newListProduct = dataProductGenerate.filter((p: any) => !Array.from(listIdProductError).includes(p.id));
        dispatch(onChangeProductGenerate(newListProduct));
      }

      setShowPopup(false);
      setClosePopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup]);

  useEffect(() => {
    const listNameAttrSelected = Object.keys(dataValuesConfigSelected);
    const listLabelAttrSelect = Object.values(dataValuesConfigSelected)
      .flat()
      .map((option: any) => option.label);

    const newListAssociated = listProductAssociated.filter((item: any) => {
      const attributes = item.attributes;

      const checkLengthEqual = listNameAttrSelected.length === Object.keys(attributes).length;
      if (!checkLengthEqual) {
        return false;
      }

      const checkNameAttr = Object.keys(attributes).every((key: string) => listNameAttrSelected.includes(key));

      if (checkNameAttr) {
        const checkValueAttr = Object.values(attributes)
          .flat()
          .every((option: any) => listLabelAttrSelect.includes(option.label));
        return checkValueAttr ? true : false;
      } else {
        return false;
      }
    });

    dispatch(onChangeListProductAssociated(newListAssociated));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup, step]);

  const popupRef = useRef<HTMLDivElement>(null);

  useClickOutside(popupRef, () => {
    step === 1 && showPopup && setShowPopup(false);
    step === 2 && formik.handleSubmit();
  });

  const tableHeader = [
    { className: 'bg-thead text-dark text-center', name: 'Image' },
    { className: 'bg-thead text-dark', name: 'Name' },
    { className: 'bg-thead text-dark', name: 'SKU' },
    { className: 'bg-thead text-dark text-end', name: 'Price' },
    { className: 'bg-thead text-dark text-end', name: 'Quantity' },
    { className: 'bg-thead text-dark text-end', name: 'Weight' },
    { className: 'bg-thead text-dark', name: 'Attributes' },
    { className: 'bg-thead text-dark text-center', name: 'Action' },
  ];

  const handleRemoveProduct = (id: string) => {
    dispatch(onChangeProductGenerate(dataProductGenerate.filter((item: any) => item.id !== id)));
  };

  async function handleClickCreateConfigurations() {
    await formikHeader.setFieldTouched('name', true, true);
    await formikHeader.setFieldTouched('sku', true, true);
    await formikHeader.setFieldTouched('price', true, true);

    setIsClickCreateConfigurations(true);
  }

  const renderPopup = () => {
    return (
      <div className="popup d-flex justify-content-center align-items-center">
        <div className="popup-content wrapper d-flex flex-column p-0" ref={popupRef}>
          <div className="d-flex justify-content-between pb-4 heading">
            <div className="d-flex align-items-center gap-3">
              <Button
                onClick={() => {
                  setBackStep(true);
                  formik.validateForm();
                }}
                disabled={step === 1}
              >
                Previous
              </Button>
              <Button
                onClick={() => setStep((prev) => prev + 1)}
                disabled={step === 2 || Object.values(dataValuesConfigSelected).flat().length < 1}
              >
                Next
              </Button>
            </div>
            <button
              className="btn-close"
              onClick={() => {
                formik.validateForm();

                setTimeout(() => {
                  setClosePopup(true);
                }, 0);
              }}
              type="button"
            >
              <FontAwesomeIcon icon={faClose} className="close-icon cursor-pointer" />
            </button>
          </div>
          <div className={`config-wrap position-relative d-flex flex-column flex-grow-1 ${step === 2 ? 'popup-custom-class' : ''}`}>
            {step === 1 ? <Step1 /> : <Step2 formik={formik} />}
          </div>
        </div>
      </div>
    );
  };

  const renderListProductWillCreate = () => {
    return (
      <Table tableHeader={tableHeader}>
        {listProductGenerateCurrentPage.map((item: any) => {
          const image = item[`image${item.id}`]?.file;
          const name = item[`name${item.id}`];
          const sku = item[`sku${item.id}`];
          const price = item[`price${item.id}`];
          const qty = item[`qty${item.id}`];
          const weight = item[`weight${item.id}`];

          const listAttr = Object.entries(item[`attributes${item.id}`]).map((item: any, index: number) => {
            const newLabel = item[0].replace(/-id\d+$/g, '');

            return (
              <span className="fs-14" key={index}>
                <span className="fw-medium">{newLabel}: </span>
                {item[1].label}
              </span>
            );
          });

          return (
            <tr key={item.id}>
              <td>
                <div className="d-flex justify-content-center align-items-center image-wrap rounded border mx-auto">
                  <img src={image ? image : require('~/app/images/thumbnail.png')} alt={image ? name : 'no thumbnail'} />
                </div>
              </td>
              <td>
                <p className="name-product m-0 w-auto">{name}</p>
              </td>
              <td>
                <p className="sku-product m-0 w-auto">{sku}</p>
              </td>
              <td className="text-end">
                <p>{price}</p>
              </td>
              <td className="text-end">
                <p>{qty || 0}</p>
              </td>
              <td className="text-end">
                <p>{weight || 0}</p>
              </td>
              <td>
                <div className="d-flex flex-column gap-2">{listAttr}</div>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={faTrash} className="trash cursor-pointer" onClick={() => handleRemoveProduct(item.id)} />
              </td>
            </tr>
          );
        })}
      </Table>
    );
  };

  return (
    <div className="wrapper configurations d-flex flex-column gap-4">
      <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between w-100 gap-4">
        <p className="m-0 fs-14 describe flex-grow-1">
          Configurable products allow customers to choose options (Ex: shirt color). You need to create a simple product for each
          configuration (Ex: a product for each color).
        </p>
        <Button className="flex-shrink-0 ms-auto text-truncate fw-medium" onClick={handleClickCreateConfigurations} type="button">
          Create Configurations
        </Button>
      </div>

      {listProductAssociated.length > 0 && (
        <div className="">
          <h3
            className="fs-6 text-primary cursor-pointer d-flex align-items-center m-0"
            onClick={() => setShowListAssociatedProduct((prev) => !prev)}
          >
            <FontAwesomeIcon icon={showListAssociatedProduct ? faChevronDown : faChevronUp} className="me-2" size="1x" />
            Associated Products
          </h3>
          {showListAssociatedProduct && (
            <>
              <p className="mt-3 mb-2 fs-14 fw-medium">You created these products for this configuration.</p>
              <TableProductAssociate />
            </>
          )}
        </div>
      )}

      {dataProductGenerate.length > 0 && (
        <div>
          <h3 className="fs-6 text-primary m-0">New Product Review</h3>
          <p className="mt-3 mb-2 fs-14 fw-medium">Here are the products you're about to create.</p>
          {renderListProductWillCreate()}
          <Pagination
            className="pagination-configuration"
            currentPage={pagination.currentPage}
            pageSize={pagination.pageSize}
            onChangePageEvent={(e) => setPagination((prev) => ({ ...prev, currentPage: e }))}
            totalCount={dataProductGenerate.length}
            status="fulfilled"
            onChangePageSizeEvent={(e) => {
              setPagination((prev) => ({ ...prev, pageSize: +e.target.value || 10, currentPage: 1 }));
            }}
          />
        </div>
      )}
      {showPopup && renderPopup()}
    </div>
  );
};

export default Configurations;
