import * as yup from 'yup';

const pwdRule = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}$/;
const emailRule = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const SignUpSchema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required')
    .matches(emailRule, "Your email doesn't appear to be valid, please double check the spelling of your email."),
  password: yup
    .string()
    .matches(pwdRule, 'Must contain 8 characters, one uppercase, one lowercase, one number and one special character.')
    .required('Password is required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirm password must match')
    .required('Confirm password is required'),
  website_id: yup.string().required('Store is required'),
});

export default SignUpSchema;
