import logo from './../../../app/images/logo.png';
import arrLeft from './../../../app/images/arr-left.png';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

import './style.scss';

interface Props {
  children: ReactNode;
  title: string;
  backLogin?: boolean;
}

const FormWrapper = (props: Props) => {
  const { title, children, backLogin } = props;

  const navigate = useNavigate();

  const handleBackLogin = () => {
    navigate('/auth/sign-in');
  };

  return (
    <>
      <img src={logo} alt="logo" className="mb-4" />

      <div className="form-wrap p-4">
        {backLogin && <img src={arrLeft} alt="back" className="back-login cursor-pointer" onClick={handleBackLogin} />}

        {backLogin && <div className="separator"></div>}

        <h3 className={'title m-0 mb-3 text-center text-uppercase fw-semibold'}>{title}</h3>

        {children}
      </div>
    </>
  );
};

export default FormWrapper;
