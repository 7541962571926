import { HTMLInputTypeAttribute, useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
import './style.scss';

export interface iPropsAuthInput {
  name: string;
  label: string;
  type?: HTMLInputTypeAttribute;
  error?: boolean;
  textError?: string;
  value: string;
  shouldShowButtonPassword?: boolean;
  onChange: (event: ChangeEvent) => void;
  onBlur: (event: ChangeEvent) => void;
}

const TextField = (props: iPropsAuthInput) => {
  const { name, label, type = 'text', error = '', value, textError, shouldShowButtonPassword = true, onChange, onBlur } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="form-input__wrap">
        <input
          className={`form-input-control w-100 ${error ? 'input--error' : ''} ${
            shouldShowButtonPassword && type === 'password' ? 'has-icon' : ''
          }`}
          type={shouldShowButtonPassword && type === 'password' ? (showPassword ? 'text' : 'password') : type}
          placeholder=" "
          value={value}
          name={name}
          autoComplete="on"
          onChange={onChange}
          onBlur={onBlur}
        />

        <label className="form-input-label user-select-none pe-none">{label}</label>

        {shouldShowButtonPassword && type === 'password' && value.length > 0 && (
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className="input--icon" onClick={toggleShowPassword} />
        )}
      </div>

      {error && <div className="input-message--error text-danger mt-2">{textError}</div>}
    </div>
  );
};

export default TextField;
