import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ButtonForm from '~/components/common/ButtonForm';
import FormWrapper from '~/components/common/FormWrapper';
import successImg from '~/app/images/success.png';
import { useAppDispatch } from '~/app/hooks/hooks';
import { clearMessage } from '~/components/layout/FullWidthLayout/redux/actions';

import './style.scss';

const CreateAccountSuccess = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      // Reset status when component unmounted
      dispatch(clearMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <FormWrapper title="CREATE NEW ACCOUNT" backLogin>
      <div className="d-flex justify-content-center">
        <img src={successImg} alt="create account success" />
      </div>
      <p className="fs-6 lh-sm text-center m-0 mt-3 fw-medium">
        Your account has been created successfully, please wait the administrator approve.
      </p>

      <Link to="/auth/sign-in">
        <ButtonForm type="button">LOGIN</ButtonForm>
      </Link>
    </FormWrapper>
  );
};

export default CreateAccountSuccess;
