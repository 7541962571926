import React from 'react';
import { CURRENT_YEAR } from '~/app/constants';

const Footer: React.FC = () => {
  return (
    <div className="fs-7 text-end py-3 text-dark-600 mt-5">
      {CURRENT_YEAR} &copy; <span className="fw-medium text-dark">Addin {process.env.REACT_APP_LOCALIZATION}</span>
    </div>
  );
};

export default Footer;
