import { iTableHead } from '../models';

export const SIDEBAR = [
  { title: 'All', quantity: 7, value: '' },
  { title: 'Processing', quantity: 7, value: 'processing' },
  { title: 'Pending', quantity: 7, value: 'pending' },
  { title: 'Draft', quantity: 7, value: 'draft' },
  { title: 'Approved', quantity: 7, value: 'approved' },
];

export const visibility = ['Not Visible Individually', 'Catalog', 'Search', 'Catalog, Search'];

export const dataTableHead: iTableHead[] = [
  { name: '#ID', className: 'p-0 pb-4 ' },
  { name: 'Product Name', className: 'p-0 pb-4 w-25' },
  { name: 'Type', className: 'p-0 pb-4 text-center' },
  { name: 'SKU', className: 'p-0 pb-4 text-center' },
  { name: 'Price', className: 'p-0 pb-4 text-center' },
  { name: 'Status', className: 'p-0 pb-4 text-center' },
  { name: 'Visibility', className: 'p-0 pb-4 text-center' },
  { name: 'Actions', className: 'p-0 pb-4 text-center' },
];

export const dataOrderHead: iTableHead[] = [
  { name: 'ID', className: 'bg-thead thead-item px-2 py-3 text-start cursor-pointer', key: 'increment_id' },
  { name: 'Purchase Point', className: 'bg-thead thead-item px-2 py-3' },
  { name: 'Customer Email', className: 'bg-thead thead-item px-2 py-3 cursor-pointer', key: 'customer_email' },
  { name: 'Customer Name', className: 'bg-thead thead-item px-2 py-3' },
  { name: 'Grand Total', className: 'bg-thead thead-item px-2 py-3 text-end cursor-pointer', key: 'base_grand_total' },
  // { name: 'Total Refunded', className: 'bg-thead thead-item px-2 py-3 text-end cursor-pointer', key: 'base_total_refunded' },
  { name: 'Payment Method', className: 'bg-thead thead-item py-3 px-2' },
  { name: 'Purchase Date', className: 'bg-thead thead-item py-3 px-2 text-end cursor-pointer', key: 'created_at' },
  { name: 'Status', className: 'bg-thead thead-item px-2 py-3 text-center cursor-pointer', key: 'status' },
  { name: 'Action', className: 'bg-thead thead-item px-2 py-3 text-center' },
];

export const dataProductHead: iTableHead[] = [
  { name: 'ID', className: 'bg-thead thead-item px-2 py-3 text-center cursor-pointer w-5', key: 'entity_id' },
  { name: 'Product Name', className: 'bg-thead thead-item px-2 py-3 cursor-pointer w-30', key: 'name' },
  { name: 'Type', className: 'bg-thead thead-item px-2 py-3 cursor-pointer', key: 'type_id' },
  { name: 'SKU', className: 'bg-thead thead-item px-2 py-3 cursor-pointer', key: 'sku' },
  { name: 'Price', className: 'bg-thead thead-item px-2 py-3 text-end' },
  { name: 'Quantity', className: 'bg-thead thead-item px-2 py-3 text-end w-5' },
  { name: 'Salable Quantity', className: 'bg-thead thead-item py-3 px-2 text-end w-10' },
  { name: 'Visibility', className: 'bg-thead thead-item px-2 py-3 w-5' },
  { name: 'Websites', className: 'bg-thead thead-item px-2 py-3' },
  { name: 'Status', className: 'bg-thead thead-item px-1 py-3 text-center cursor-pointer', key: 'status' },
  { name: 'Action', className: 'bg-thead thead-item px-2 py-3 text-center' },
];

export const dataSaleReportHead: iTableHead[] = [
  { name: 'Interval', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Orders', className: 'bg-thead thead-item py-3 text-end ' },
  // { name: 'Sales Items', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Sales Total', className: 'bg-thead thead-item py-3 text-end' },
  // { name: 'Invoiced', className: 'bg-thead thead-item py-3 text-end' },
  // { name: 'Refunded', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Sales Tax', className: 'bg-thead thead-item py-3 text-end' },
  // { name: 'Sales Shipping', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Sales Discount', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Canceled', className: 'bg-thead thead-item py-3 text-end' },
];

export const dataCustomerReportHead: iTableHead[] = [
  { name: 'Interval', className: 'bg-thead thead-item py-3 text-end w-10' },
  { name: 'Customer Name', className: 'bg-thead thead-item py-3 ps-2 text-start' },
  { name: 'Email', className: 'bg-thead thead-item py-3 ps-2 text-start' },
  { name: 'Orders', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Total', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Average', className: 'bg-thead thead-item py-3 text-end' },
];

export const dataInvoicedReportHead: iTableHead[] = [
  { name: 'Interval', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Orders', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Invoiced Orders', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Total Invoiced', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Paid Invoices', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Unpaid Invoices', className: 'bg-thead thead-item py-3 text-end' },
];

export const dataRefundedReportHead: iTableHead[] = [
  { name: 'Interval', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Refunded Orders', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Total Refunded', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Online Refunds', className: 'bg-thead thead-item py-3 text-end' },
  { name: 'Offline Refunds', className: 'bg-thead thead-item py-3 text-end' },
];

export const dataProductReportHead: iTableHead[] = [
  { name: 'Product', className: 'bg-thead thead-item py-3' },
  { name: 'SKU', className: 'bg-thead thead-item py-3 text-start' },
  { name: 'Ordered Quantity', className: 'bg-thead thead-item py-3 text-end' },
];

export const dataStatus = [
  { title: 'In Stock', className: 'pills pills-success' },
  { title: 'Out of Stock', className: 'pills pills-danger' },
];

export const PAGES: number[] = [10, 20, 30, 50, 100, 200];
