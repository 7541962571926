import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iOrderCommnent } from '~/app/models';
import CustomCheckBox from '~/components/common/CustomCheckBox';
import * as yup from 'yup';
import { invoiceComment, memoComment, postOrderComment, shipmentComment } from '../redux/actions';
import { useParams } from 'react-router-dom';
type Props = {
  pageType: string;
  orderId: number | string;
};

const Comment: React.FC<Props> = (props: Props) => {
  const { pageType, orderId } = props;
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { dataDetails, commentStatus } = useAppSelector((state) => state.orderReducer);

  const dispatch = useAppDispatch();

  const schema = yup.object().shape({
    comment: yup.string().required('Comment is required'),
  });

  const { entity_id = 0 } = useParams();

  const inititalComment: iOrderCommnent = {
    comment: '',
    accessToken,
    id: +orderId,
    is_customer_notified: false,
    is_visible_on_front: false,
    status: dataDetails.status,
    entity_id: +entity_id,
    parent_id: +orderId,
  };

  const { values, handleChange, handleSubmit, errors, resetForm, setFieldValue } = useFormik({
    initialValues: inititalComment,
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (pageType === 'infomation') dispatch(postOrderComment(values, currentStore));
      if (pageType === 'invoices') dispatch(invoiceComment(values, currentStore));
      if (pageType === 'shipment') dispatch(shipmentComment(values, currentStore));
      if (pageType === 'memo') dispatch(memoComment(values, currentStore));
      resetForm();
    },
  });

  useEffect(() => {
    setFieldValue('status', dataDetails.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetails]);

  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-4 fs-6 fw-semibold">NOTES</p>
      <label className="fs-14 fw-medium d-block" htmlFor="note-textarea">
        Comment
      </label>
      <textarea
        className="note-textarea w-100 p-2 mt-2 mb-1 fs-14"
        name="comment"
        id="note-textarea"
        value={values.comment}
        onChange={handleChange}
      ></textarea>
      {Object.keys(errors).length > 0 ? <p className="fs-7 text-danger fw-medium">{errors.comment}</p> : ''}
      <div className="d-flex">
        <CustomCheckBox
          checked={values.is_customer_notified}
          name="is_customer_notified"
          label={'Notify Customer by Email'}
          onChange={handleChange}
          value={values.is_customer_notified}
          checkBoxClass={'fs-14 mt-12 fw-medium'}
        />
      </div>
      <div className="d-flex justify-content-start">
        <CustomCheckBox
          checked={values.is_visible_on_front}
          name="is_visible_on_front"
          label={'Visible on Storefront'}
          onChange={handleChange}
          value={values.is_visible_on_front}
          checkBoxClass={'fs-14 mt-12 fw-medium'}
        />
      </div>
      <button type="submit" disabled={commentStatus === 'pending'} className="btn btn-primary rounded px-5 py-2 fs-6 fw-semibold mt-3">
        Submit
      </button>
    </form>
  );
};

export default Comment;
