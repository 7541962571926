import { useEffect } from 'react';
import { convertGetPastDay, getPastDay } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iProductSoldDashBoard } from '~/app/models/dashboard';
import { dashboardCriteria } from '~/app/utils';
import Chart from '~/components/common/Chart';
import { ChartLoading } from '~/components/common/skeletons';
import { getProductSold } from './redux/actions';
import moment from 'moment';

import './style.scss';
import last12Moths from '~/app/utils/last12Moths';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const ProductSold = (props: Props) => {
  const { children } = props;
  const dispatch = useAppDispatch();

  const { dataProductSold, statusProductSold } = useAppSelector((s) => s.dashboardReducer);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);

  const currentDate = convertGetPastDay(getPastDay(1)[0]);
  const aYearAgo = convertGetPastDay(getPastDay(366)[365]);

  useEffect(() => {
    const payload: iProductSoldDashBoard = {
      accessToken,
      currentStore,
      searchUrl: dashboardCriteria({
        to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
        from: moment(new Date(aYearAgo)).format('YYYY-MM-DD'),
      }),
    };
    dispatch(getProductSold(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, accessToken]);

  const chartData = {
    labels: last12Moths?.map((item) => {
      const month = item.split(' ')[0];
      return `${month.slice(0, 3)}`;
    }),
    datasets: [
      {
        label: 'Total',
        data: last12Moths?.map((item) => {
          const check = item.split(' ').join('-');
          const data = dataProductSold.find((item) => item.order_date === check);
          return data ? data.qty_ordered : 0;
        }),

        // Config
        backgroundColor: ['#180767'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            const index = context[0].dataIndex;
            const element = last12Moths[index];
            return element;
          },
        },
        titleAlign: 'center',
        titleMarginBottom: 12,
        padding: 16,
        boxPadding: 8,
      },
    },
  };

  return (
    <div className="product-sold d-flex flex-column flex-grow-1 mb-auto">
      {children}

      <div className="content-wrap bg-white p-4 d-flex justify-content-center align-items-end flex-grow-1">
        {statusProductSold !== 'pending' ? <Chart type="Bar" chartData={chartData} options={chartOptions} /> : <ChartLoading />}
      </div>
    </div>
  );
};

export default ProductSold;
