/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { MultiSelect } from '~/components/common/MultiSelect';
import { onChangeDataAttrConfigSelected, onChangeDataValuesConfigSelected } from '../../redux/actions';
import { Input } from '~/components/common/Input';

interface iOption {
  label: string;
  value: string;
}

const Step1 = () => {
  const dispatch = useAppDispatch();
  const {
    dataValuesConfigSelected,
    dataAttrConfigSelected,
    statusGetProductDetail,
    listAttrOfProductConfig,
    statusGetAttrProductConfig,
    dataRelatedAttrByAttrSet,
  } = useAppSelector((state) => state.createEditProductReducer);

  const [listAttrConfig, setListAttrConfig] = useState<any[]>([]);
  const [listAttrConfigSelected, setListAttrConfigSelected] = useState<any>(dataAttrConfigSelected);
  const [query, setQuery] = useState<string>('');
  const [search, setSearch] = useState<{ [key: string]: string }>({});

  function filterAttrOfProductConfigExistAttrSet() {
    const list = [
      'status',
      'shipment_type',
      'page_layout',
      'gift_message_available',
      'options_container',
      'custom_layout_update_file',
      'custom_design',
      'custom_layout',
      'tax_class_id',
      'msrp_display_actual_price_type',
      'quantity_and_stock_status',
      'price_view',
      'visibility',
      'country_of_manufacture',
      'product_image_size',
      'product_page_type',
    ];

    const listAttrIdOfAttrSet = dataRelatedAttrByAttrSet
      .filter((attr: any) => attr.frontend_input === 'select' && !list.includes(attr.attribute_code))
      .map((attr: any) => attr.attribute_id);

    const listAttrOfProductConfigExistAttrSet = listAttrOfProductConfig.filter((attr) => listAttrIdOfAttrSet.includes(attr.attribute_id));
    return listAttrOfProductConfigExistAttrSet;
  }

  const listAttrOfProductConfigExistAttrSet = useMemo(() => {
    return filterAttrOfProductConfigExistAttrSet();
  }, [dataRelatedAttrByAttrSet, listAttrOfProductConfig, statusGetProductDetail]);

  useEffect(() => {
    if (listAttrOfProductConfig.length) {
      const listAttrConfig = JSON.parse(
        JSON.stringify(listAttrOfProductConfigExistAttrSet).replaceAll('attribute_id', 'id').replaceAll('default_frontend_label', 'name'),
      );

      // const listIdAttr = listAttrConfig.map((attr: any) => attr.id);
      // const listNameWithId = listAttrConfig.map((attr: any) => `${attr.name}-id${attr.id}`);
      // const newListSelected = listAttrConfigSelected?.filter((attr: any) => listIdAttr.includes(attr.id)) || [];
      // const newDataValuesConfigSelectedArr = Object.entries(dataValuesConfigSelected).filter((item) => listNameWithId.includes(item[0]));
      // const newDataValuesConfigSelectedObj = Object.fromEntries(newDataValuesConfigSelectedArr);

      // dispatch(onChangeDataValuesConfigSelected(newDataValuesConfigSelectedObj));
      // setListAttrConfigSelected(newListSelected);
      setListAttrConfig(listAttrConfig);
    }
  }, [listAttrOfProductConfig, dataRelatedAttrByAttrSet]);

  useEffect(() => {
    if (listAttrConfigSelected) {
      const listNameWithIdAttrSelected = listAttrConfigSelected.map((item: any) => `${item.name}-id${item.id}`);
      const listValuesSelected = Object.entries(dataValuesConfigSelected).filter((item) => listNameWithIdAttrSelected.includes(item[0]));

      dispatch(onChangeDataValuesConfigSelected(Object.fromEntries(listValuesSelected)));
      dispatch(onChangeDataAttrConfigSelected(listAttrConfigSelected));
    }
  }, [listAttrConfigSelected]);

  function handleChange(nameAttr: any, idAttr: number, attrClicked: any) {
    const newNameAttr = `${nameAttr}-id${idAttr}`;

    if (dataValuesConfigSelected[newNameAttr]) {
      const attrFind = dataValuesConfigSelected[newNameAttr]?.find((item: any) => item.label === attrClicked.label);

      const newListSelect = attrFind
        ? dataValuesConfigSelected[newNameAttr].filter((item: any) => item.label !== attrFind.label)
        : [...dataValuesConfigSelected[newNameAttr], attrClicked].sort((f: any, s: any) => {
            if (f.label > s.label) return 1;
            if (f.label < s.label) return -1;
            return 0;
          });

      const newData = Object.fromEntries(
        Object.entries({
          ...dataValuesConfigSelected,
          [newNameAttr]: newListSelect,
        })
          .filter((item: any) => item[1].length > 0)
          .sort((f: any, s: any) => {
            const fItemName = f[0];
            const sItemName = s[0];
            if (fItemName > sItemName) return 1;
            if (fItemName < sItemName) return -1;
            return 0;
          }),
      );

      dispatch(onChangeDataValuesConfigSelected(newData));
      return;
    }

    dispatch(
      onChangeDataValuesConfigSelected({
        ...dataValuesConfigSelected,
        [newNameAttr]: [attrClicked],
      }),
    );
  }

  function handleSelectAll(idAttr: number, nameAttr: string) {
    const itemByNameAttr = listAttrConfig.find((attr) => attr.id === idAttr);

    if (itemByNameAttr) {
      const listOption = itemByNameAttr.options.filter((option: any) => !!option.value);

      if (listOption.length) {
        const listOptionSort = listOption.sort((f: any, s: any) => {
          if (f.label > s.label) return 1;
          if (f.label < s.label) return -1;
          return 0;
        });

        const newData = { ...dataValuesConfigSelected, [`${nameAttr}-id${idAttr}`]: listOptionSort };
        dispatch(onChangeDataValuesConfigSelected(newData));
      }
    }
  }

  function handleDeselectAll(idAttr: number, nameAttr: string) {
    const data = { ...dataValuesConfigSelected };
    delete data[`${nameAttr}-id${idAttr}`];
    dispatch(onChangeDataValuesConfigSelected(data));
  }

  function handleChangeValueSearch(valueChange: { [key: string]: string }) {
    setSearch((prev) => ({ ...prev, ...valueChange }));
  }

  return (
    <div className="content-step1">
      <h3 className="title m-0 fw-normal">Select Attributes</h3>
      <div className="multi-select-attr flex-grow-1">
        <MultiSelect
          removeSearchWhenSelected
          status={statusGetAttrProductConfig}
          options={
            query ? listAttrConfig.filter((item) => item.name.toLowerCase().trim().includes(query.toLowerCase().trim())) : listAttrConfig
          }
          listSelected={listAttrConfigSelected || []}
          setListSelected={setListAttrConfigSelected}
          query={query}
          setQuery={setQuery}
          sortBy="attribute_code"
        />
      </div>
      <div className="attr-value">
        {listAttrConfigSelected?.map((item: any, index: number) => {
          const valueSearch = search[item.id] || '';

          return (
            <div className="" key={index}>
              <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
                <div className="d-flex align-items-center">
                  <h3 className="attr-name title m-0 me-4 fw-normal">{item.name}</h3>
                  <Input
                    className="form-control-custom-search-input w-fit-content"
                    type="search"
                    placeholder="Search here..."
                    value={valueSearch}
                    onChange={(e) => handleChangeValueSearch({ [item.id]: e.target.value })}
                  />
                </div>

                <div className="d-flex align-items-center">
                  <h4 className="m-0 fs-14 link" onClick={() => handleSelectAll(item.id, item.name)}>
                    Select All
                  </h4>
                  <span className="pointer-event-none user-select-none mx-2 text-dark-600">|</span>
                  <h4 className="m-0 fs-14 link" onClick={() => handleDeselectAll(item.id, item.name)}>
                    Deselect All
                  </h4>
                </div>
              </div>
              <div className="list-child-attr overflow-x-auto p-4">
                {item.options
                  .filter((option: iOption) =>
                    valueSearch
                      ? option.label.toLowerCase().includes(valueSearch.toLowerCase()) && option.value !== ''
                      : option.value !== '',
                  )
                  .sort((f: any, s: any) => {
                    if (f.label > s.label) return 1;
                    if (f.label < s.label) return -1;
                    return 0;
                  })
                  .map((option: iOption, index: number) => (
                    <div className="d-flex align-items-center checkbox-custom" key={index}>
                      <input
                        className="form-check-input m-0 cursor-pointer"
                        type="checkbox"
                        name={option.label}
                        id={option.value}
                        checked={
                          dataValuesConfigSelected[`${item.name}-id${item.id}`]?.map((item: any) => item.label)?.includes(option.label) ||
                          false
                        }
                        onChange={() => handleChange(item.name, item.id, option)}
                      />

                      <label htmlFor={option.value} className="fs-6 fw-normal ms-2 cursor-pointer">
                        {option.label}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Step1;
