export const COUNTRIES: { [key: string]: string } = {
  MY: 'Malaysia',
  SG: 'Singapore',
  TH: 'Thailand',
  VN: 'VietNam',
  HK: 'Hongkong',
  PH: 'Philippines',
  ID: 'Indonesia',
  TW: 'Taiwan',
  US: 'United States',
};

export const STORES: { [key: string]: string } = {
  sg_en: 'Singapore',
  my_en: 'Malaysia',
  hk_en: 'Hongkong',
  hk_zh: 'Hongkong',
  vn_vn: 'Vietnam',
  vn_en: 'Vietnam',
  tw_en: 'Taiwan EN',
  tw_zh: 'Taiwan ZH',
  id_en: 'Indonesia',
  th_en: 'Thailand',
  ph_en: 'Philippines',
};

export const defaultMessageError = 'Something went wrong. Please wait and try again.';
