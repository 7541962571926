import {
  iGetAllOrder,
  iGetCreditMemosDetail,
  iGetDataById,
  iGetInvoiceDetail,
  iGetOrderData,
  iGetShipmentDetail,
  iOrderCommnent,
} from '~/app/models';
import orderApi from '../../../../app/apis/order.api';
import * as types from './index';
import { defaultMessageError } from '~/app/constants';

const { rest } = types;

export const getAllOrders = (payload: iGetAllOrder, cancelController: AbortController) => async (dispatch: any) => {
  cancelController && cancelController.abort();
  dispatch(rest.getDataPending());
  const controller = new AbortController();
  dispatch(rest.changeController(controller));

  try {
    const res = await orderApi.getAllOrders(payload, controller);
    const { data } = res;
    !data ? dispatch(rest.getDataRejected(defaultMessageError)) : dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if (error.message === 'canceled') return;

    dispatch(rest.getDataRejected(''));
  }
};

export const getDataById =
  (payload: iGetDataById, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getOrderDetailPending());

    try {
      const res = await orderApi.getOrderDetails(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getOrderDetailRejected()) : dispatch(rest.getOrderDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getOrderDetailRejected());
    }
  };

export const getInvoices =
  (payload: iGetOrderData, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getInvoicesPending());

    try {
      const res = await orderApi.getInvoicesByOrderId(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getInvoicesRejected()) : dispatch(rest.getInvoicesFulfilled(data));
    } catch (error) {
      dispatch(rest.getInvoicesRejected());
    }
  };

export const getInvoiceDetail =
  (payload: iGetInvoiceDetail, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getInvoiceDetailPending());

    try {
      const res = await orderApi.getInvoiveDetail(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getInvoiceDetailRejected()) : dispatch(rest.getInvoiceDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getInvoiceDetailRejected());
    }
  };

export const getMemos =
  (payload: iGetOrderData, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getMemosPending());
    try {
      const res = await orderApi.getCreditMemos(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getMemosRejected()) : dispatch(rest.getMemosFulfilled(data));
    } catch (error) {
      dispatch(rest.getMemosRejected());
    }
  };

export const getMemosDetail =
  (payload: iGetCreditMemosDetail, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getMemosDetailPending());

    try {
      const res = await orderApi.getCreditMemosDetail(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getMemosDetailRejected()) : dispatch(rest.getMemosDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getMemosDetailRejected());
    }
  };

export const getShipments =
  (payload: iGetOrderData, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getShipmentPending());
    try {
      const res = await orderApi.getShipments(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getShipmentRejected()) : dispatch(rest.getShipmentFulfilled(data));
    } catch (error) {
      dispatch(rest.getShipmentRejected());
    }
  };

export const getShipmentsDetail =
  (payload: iGetShipmentDetail, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getShipmentDetailPending());

    try {
      const res = await orderApi.getShipmentsDetail(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getShipmentDetailRejected()) : dispatch(rest.getShipmentDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getShipmentDetailRejected());
    }
  };

export const postOrderComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postOrderComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const invoiceComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postInvoiceComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const memoComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postMemoComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const shipmentComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postShipmentComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const getNotesHistoty = (payload: { id: number; accessToken: string }) => async (dispatch: any) => {
  dispatch(rest.getNotesHistoryPending());
  try {
    const res: any = await orderApi.getNotesHistoty(payload);
    const { data } = res;
    data ? dispatch(rest.getNotesHistoryFulfilled(data)) : dispatch(rest.getNotesHistoryRejected());
  } catch (error) {
    dispatch(rest.getNotesHistoryRejected());
  }
};

export const postStatusOrder =
  (orderId: string, payload: any, accessToken: string, currentStore: string, toggleFormUpdateStatus: () => void) =>
  async (dispatch: any) => {
    dispatch(rest.postStatusOrderPending());
    try {
      await orderApi.postStatusOrder(orderId, payload, accessToken, currentStore);

      // Close popup
      toggleFormUpdateStatus();
      dispatch(rest.postStatusOrderFulfilled());
    } catch (error) {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};

export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setFilterData = (filterData: any) => (dispatch: any) => {
  dispatch(rest.setFilterData(filterData));
};

export const setQueryString = (queryString: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(queryString));
};
export const resetOrderQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};

export const clearData = () => (dispatch: any) => {
  dispatch(rest.clearData());
};
