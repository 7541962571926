import { ReactNode } from 'react';

type colTableHeader = {
  className: string;
  name: string;
};

type Props = {
  tableHeader: colTableHeader[];
  children: ReactNode;
};

const Table = ({ tableHeader, children }: Props) => {
  function renderHeader() {
    return (
      <thead>
        <tr>
          {tableHeader.map((col, i: number) => (
            <th className={col.className} key={i}>
              <span>{col.name}</span>
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  return (
    <table id="table-config" className="w-100">
      {renderHeader()}
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
