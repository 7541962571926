import { ChangeEvent, FC, FocusEvent } from 'react';

interface Props {
  id?: string;
  name?: string;
  value: string | number | undefined;
  className?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<Props> = ({ className = '', id = '', name = '', value = '', onChange, onBlur }) => {
  return (
    <textarea
      className={`form-control form-control--custom form-control--custom-textarea ${className}`}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextArea;
