import { FC } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { formatMoney } from '~/app/utils';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';

const MEMOS_DETAIL: iTableHead[] = [
  { name: 'Product', className: 'ps-0 fs-14 w-35 fw-semibold' },
  { name: 'Price', className: 'fs-14 fw-semibold text-end' },
  { name: 'Qty', className: 'fs-14 fw-semibold text-end' },
  { name: 'Subtotal', className: 'fs-14 fw-semibold text-end' },
  { name: 'Tax Amount', className: 'fs-14 fw-semibold text-end' },
  { name: 'Discount', className: 'fs-14 fw-semibold text-end' },
  { name: 'Row Total', className: 'fs-14 fw-semibold text-end' },
];
export const TableMemo: FC = () => {
  const { status, memoDetail, dataDetails } = useAppSelector((state) => state.orderReducer);
  const { currentStore } = useAppSelector((state) => state.authReducer);
  const { items } = memoDetail;
  const arr = items.map((item: any) => item.order_item_id);

  const itemWithOutConfigurable = dataDetails.items.filter(
    (item: any) => arr.includes(item.item_id) && item.product_type !== 'configurable',
  );

  const getItemData = (id: number, parent_item?: any) => {
    const item = items.filter((item: any) => item.order_item_id === id);
    return {
      qty: item[0].qty || 0,
      row_total: item[0].row_total || 0,
      price: item[0].price || 0,
      discount_amount: item[0].base_discount_amount || 0,
      base_tax_amount: item[0].base_tax_amount || 0,
      name: item[0].name,
      sku: item[0].sku,
      parent_item,
    };
  };

  const renderDetails = (item: any) => {
    const { parent_item } = item;
    return item.parent_item ? renderTd(getItemData(item.parent_item.item_id, parent_item)) : renderTd(getItemData(item.item_id));
  };

  const convertDeliveryCode = (data: string[]) => {
    if (!data) return undefined;
    const deliveryString = JSON.parse(JSON.stringify(data));

    const result = deliveryString.map((item: any) => JSON.parse(item));

    return result;
  };

  const renderTd = (item: any) => {
    const options = convertDeliveryCode(item?.parent_item?.extension_attributes?.deliverycode);

    return (
      <>
        <td className="ps-0 fs-14 vertical-middle px-2">
          <p className="mb-2 fw-medium" dangerouslySetInnerHTML={{ __html: item.name }} />
          <p className="mb-2 sku-product">SKU: {item.sku}</p>
          {options
            ? options.map((option: any, i: number) => {
                return (
                  <p key={i} className="mb-0 option-product">
                    {option.label}: {option.value}
                  </p>
                );
              })
            : ''}
        </td>
        <td className="fs-14 vertical-middle px-2 text-end">{formatMoney(item.price, currentStore)}</td>
        <td className="fs-14 vertical-middle px-2 text-end">
          <p className="mb-0">{item.qty}</p>
        </td>
        <td className="fs-14 vertical-middle px-2 text-end">{formatMoney(item.row_total, currentStore)}</td>
        <td className="fs-14 vertical-middle px-2 text-end">{formatMoney(item.base_tax_amount, currentStore)}</td>
        <td className="fs-14 vertical-middle px-2 text-end">{formatMoney(item.discount_amount, currentStore)}</td>
        <td className="fs-14 vertical-middle px-2 text-end">
          {formatMoney(item.row_total + item.base_tax_amount - item.discount_amount, currentStore)}
        </td>
      </>
    );
  };

  return (
    <div className="info-table bg-white px-4 py-3 wrapper">
      <p className="mb-4 fs-6 fw-semibold text-uppercase">Items Refunded</p>
      <Table status={status} dataTableHead={MEMOS_DETAIL} className="">
        {itemWithOutConfigurable.length ? (
          itemWithOutConfigurable.map((item: any, i: number) => {
            return <tr key={i}>{renderDetails(item)}</tr>;
          })
        ) : (
          <NoRecord tableHeaders={MEMOS_DETAIL} />
        )}
      </Table>
    </div>
  );
};
