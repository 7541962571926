import { FC, useEffect, useMemo, useState } from 'react';
import DefaultLayout from '~/components/layout/DefaultLayout';
import './style.scss';
import { useSearchParams } from 'react-router-dom';
import SearchBar from '~/components/common/Filter/searchBar';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import {
  addFilterGroup,
  addFilterItem,
  addFilters,
  addSortOrder,
  filterEmptyProperties,
  getStoreId,
  searchCriteriaBuilder,
} from '~/app/utils';
import { DateInput, ResetFilterButton, SelectFilter } from '~/components/common/Filter/filterInput';
import { FilterButtons } from '~/components/common/Filter/filterInput';
import { priorityOptions } from '~/app/constants/filter';
import { TextFilter } from '~/components/common/Filter/filterInput';
import Pagination from '~/components/common/Pagination';
import { builderCriteriaTicket } from '~/app/utils/builderCriteria';
import { getTicketListing, setQueryString } from './redux/action';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { formatFilterActive, formatFilterPriority } from '~/components/common/Filter/formatFilter';
import moment from 'moment';
import ListTicket from './ListTicket';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import { ReactComponent as TicketAll } from '~/app/images/tickets-all.svg';
import { ReactComponent as TicketOpen } from '~/app/images/tickets-open.svg';
import { ReactComponent as TicketProcessing } from '~/app/images/tickets-processing.svg';
import { ReactComponent as TicketClosed } from '~/app/images/tickets-closed.svg';
import { ReactComponent as TicketDone } from '~/app/images/tickets-done.svg';

type FormFilterValues = {
  searchTerm: string;
  status_id: string;
  customer_name: string;
  created_at_from: string;
  created_at_to: string;
  priority_id: string;
};

const TicketListing: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Reducers
  const { dataTicketListing, status: statusGetTicketListing, controller, queryString } = useAppSelector((state) => state.ticketReducer);
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));

  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const searchTerm = searchParams.get('searchTerm') || '';
  const status_id = searchParams.get('status_id') || '-1';
  const customer_name = searchParams.get('customer_name') || '';
  const created_at_from = searchParams.get('created_at_from') || '';
  const created_at_to = searchParams.get('created_at_to') || '';
  const priority_id = searchParams.get('priority_id') || '';

  const { total_count } = dataTicketListing;

  // Declare initial Filter Payload
  const initialFilterPayload = {
    searchTerm,
    status_id,
    customer_name,
    created_at_from,
    created_at_to,
    priority_id,
  } as FormFilterValues;

  // Declare useState
  const [query, setQuery] = useState<string>(window.location.search);
  const [filterPayload, setFilterPayload] = useState<any>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState(status_id.toString() || '-1');
  const [storeId, setStoreId] = useState<number>();

  // Declare useEffect
  useEffect(() => {
    setStoreId(getStoreId(storeData, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    setFirstMounted(false);

    return () => {
      controller && controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      searchTerm: '',
      customer_name: '',
      created_at_from: '',
      created_at_to: '',
      priority_id: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      searchTerm: '',
      customer_name: '',
      created_at_from: '',
      created_at_to: '',
      priority_id: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, queryString]);

  useEffect(() => {
    const filter = builderCriteriaTicket({ ...submitFilterPayload, status_id: activeMenu });
    const sort = sorterCriteria('ticket_id', false);

    // Run at first load
    if ((isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) && storeId) {
      dispatch(
        getTicketListing(
          searchCriteriaBuilder(
            convertStringToNumber(pageSize.toString(), 10),
            convertStringToNumber(currentPage.toString(), 1),
            addFilterGroup(...filter, addFilters(addFilterItem('store_id', `${storeId}`, 'eq'))),
            addSortOrder(...sort),
          ),
          accessToken,
          currentStore,
          controller,
        ),
      );
    }
    const newObject: any = filterEmptyProperties(submitFilterPayload);
    newObject.status_id && delete newObject.status_id;

    if (Object.keys(newObject).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, currentPage, pageSize, storeData, currentStore, query, isFilterSubmit, queryString]);

  const listMenu = useMemo(
    () => [
      {
        // Search status id greater than -1
        status_id: '-1',
        icon: <TicketAll />,
        name: 'All Tickets',
      },
      {
        status_id: '1',
        icon: <TicketOpen />,
        name: 'Open',
      },
      {
        status_id: '2',
        icon: <TicketProcessing />,
        name: 'Processing',
      },
      {
        status_id: '0',
        icon: <TicketClosed />,
        name: 'Closed',
      },
      {
        status_id: '3',
        icon: <TicketDone />,
        name: 'Done',
      },
    ],
    [],
  );

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      searchTerm: '',
      customer_name: '',
      created_at_from: '',
      created_at_to: '',
      priority_id: '',
      status: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      searchTerm: '',
      customer_name: '',
      created_at_from: '',
      created_at_to: '',
      priority_id: '',
      status: '',
    });
    setSearchParams({ status_id });
    resetUrlData();
    // !isEnableFilter && setIsEnableFilter(true);
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      status_id,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, status_id, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, status_id, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      status_id,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, status_id, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, status_id, [`${key}`]: '' });
    resetUrlData();
  };

  //Declare submit filter function
  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload(filterPayload);
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          status_id,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload, status_id });
    const filteredObject = filterEmptyProperties({ ...filterPayload, status_id });

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      status_id,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  //Declare onChange Menu Nav
  const onChangeMenu = (id: string) => {
    setActiveMenu(id);
    const filteredObject = filterEmptyProperties({ ...submitFilterPayload, status_id: id });
    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
  };

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  // Belong to renderContentSection
  const renderFilter = () => {
    return (
      <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>
        <div className="filter-section bg-white mb-5 p-3 wrapper">
          <div className="row mt-3">
            <h5 className="col-12 mb-4 text-dark">Filters</h5>

            <DateInput
              filterClass="col-lg-6 mt-3 mt-xl-0"
              label="Create At"
              name="created_at"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />

            <TextFilter
              filterClass="col-lg-3 mt-3 mt-xl-0"
              label="Customer Name"
              name="customer_name"
              value={filterPayload.customer_name || ''}
              onChange={onFilterChangeHandler}
            />

            <SelectFilter
              filterClass="col-lg-3 mt-3 mt-xl-0"
              label="Priority"
              name="priority_id"
              options={priorityOptions}
              value={filterPayload.priority_id}
              onChange={onFilterChangeHandler}
            />
            {/* <SelectFilter
              filterClass="col-lg-3 mt-3"
              label="Status"
              name="status_id"
              options={ticketStatusOptions}
              value={filterPayload.status_id}
              onChange={onFilterChangeHandler}
            /> */}
            <FilterButtons onSubmit={onSubmit} status={statusGetTicketListing} setIsEnableFilter={() => setIsEnableFilter(false)} />
          </div>
        </div>
      </div>
    );
  };

  //Declare render Filter Active component
  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'Create At',
        value:
          created_at_from || created_at_to
            ? `${created_at_from ? moment(created_at_from).format('MM/DD/YYYY') : '...'} - ${
                created_at_to ? moment(created_at_to).format('MM/DD/YYYY') : '...'
              }`
            : '',
        resetHandler: () => resetFilterFromToSection('created_at_from', 'created_at_to'),
      },

      { label: 'Customer Name', value: customer_name, resetHandler: () => resetFilterSection('customer_name') },
      { label: 'Priority', value: formatFilterPriority(priority_id), resetHandler: () => resetFilterSection('priority_id') },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  //Declare render Menu Nav component
  const renderMenu = () => {
    return (
      <nav className="menu">
        <ul className="menu-list p-0 d-flex align-items-center mb-4 overflow-auto">
          {listMenu.map((item) => {
            const { status_id, icon, name } = item;
            const checkActive = status_id === activeMenu ? 'active' : '';

            return (
              <li
                className={`menu-item fs-6 fw-medium pb-4 text-nowrap ${checkActive}`}
                key={status_id}
                onClick={() => onChangeMenu(status_id)}
              >
                <span className="menu-icon">{icon}</span>
                <span> {name}</span>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  };

  return (
    <DefaultLayout pageTitle="Ticket Listing">
      <div className="ticket-wrap">
        <div className="filter-wrapper d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-4 gap-3">
          <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-3 flex-grow-1">
            <SearchBar
              name="searchTerm"
              status={statusGetTicketListing}
              isTotalCount={true}
              totalCount={total_count}
              searchKeyPressHandler={searchKeyPressHandler}
              onFilterChangeHandler={onFilterChangeHandler}
              value={filterPayload.searchTerm}
            />
          </div>

          <span
            className="filters--button btn btn-primary px-3 px-xl-4 py-2 fw-medium border-0 fs-6"
            onClick={() => setIsEnableFilter(!isEnableFilter)}
          >
            Filters
          </span>
        </div>

        {renderFilter()}
        {renderFilterActive()}
        {renderMenu()}

        <ListTicket />

        <Pagination
          className=""
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={statusGetTicketListing}
          totalCount={total_count}
        />
      </div>
    </DefaultLayout>
  );
};

export default TicketListing;
