import { Outlet, useLocation } from 'react-router-dom';
import DefaultLayout from '~/components/layout/DefaultLayout';
import ProfileLeft from './ProfileLeft';
import { useAppSelector } from '~/app/hooks/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Loading from '~/components/common/Loading';

type Props = {};

const Account = (props: Props) => {
  const { pathname } = useLocation();
  const { status, message } = useAppSelector((state) => state.userReducer);

  return (
    <DefaultLayout pageTitle={`${pathname === '/profile/overview' ? 'Profile Overview' : 'Profile & Settings'}`}>
      <div className="row mb-4">
        {(!status || status === 'pending') && (
          <div className="bg-white shadow-sm p-5 rounded d-flex align-items-center justify-content-center text-danger">
            <Loading />
          </div>
        )}

        {status === 'rejected' && (
          <div className="bg-white shadow-sm p-5 rounded d-flex align-items-center justify-content-center text-danger">
            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
            {message}
          </div>
        )}

        {status === 'fulfilled' && (
          <>
            <div className="col-12 col-xl-5">
              <ProfileLeft isEdit={true} />
            </div>
            <div className="col-12 col-xl-7">
              <Outlet />
            </div>
          </>
        )}
      </div>
    </DefaultLayout>
  );
};

export default Account;
