import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import './style.scss';

interface SearchProps {
  status: string;
  totalCount?: number;
  searchKeyPressHandler: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFilterChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  isTotalCount: Boolean;
}

const SearchBar: React.FC<SearchProps> = ({
  status,
  totalCount,
  searchKeyPressHandler,
  onFilterChangeHandler,
  value,
  name,
  isTotalCount,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (status === 'pending') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]);

  return (
    <>
      <div className="search-btn d-flex align-items-center cursor-pointer w-300px">
        <input
          type="text"
          name={name}
          placeholder="Press Enter To Search"
          disabled={isDisabled}
          onKeyPress={searchKeyPressHandler}
          value={value}
          onChange={onFilterChangeHandler}
          className="w-300px"
        />
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
      {isTotalCount && (
        <span className="ms-2 ms-xl-4 fw-medium border-0 fs-6 w-75 fst-italic">Total {totalCount ? totalCount : '0'} Record(s) Found</span>
      )}
    </>
  );
};

export default SearchBar;
