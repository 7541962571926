import { FC } from 'react';
import Loading from '~/components/common/Loading';
import { ErrorPage } from '~/components/pages/Err/';

interface Props {
  className: string;
  status: string;
  children: JSX.Element[] | JSX.Element;
  onClick?: any;
  errMessage?: string;
}

const AsyncSection: FC<Props> = (props: Props) => {
  const { className = '', status, children, onClick } = props;

  const onClickHandler = () => {
    onClick();
  };

  const renderPending = () => {
    return <div className="mt-4">{<Loading />}</div>;
  };

  const renderRejected = () => {
    return <>{<ErrorPage />} </>;
  };

  return (
    <div className={`${className}`} onClick={() => onClickHandler}>
      {status === 'pending' && renderPending()}
      {status === 'fulfilled' && children}
      {status === 'rejected' && renderRejected()}
    </div>
  );
};

export default AsyncSection;
