import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFloppyDisk, faLocationDot, faPhone, faSpinner, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from '~/components/common/Button';
import { FC, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { convertBase64 } from '~/app/utils/convertBase64';
import { deleteAvatar, deleteBanner, updateAvatarAndBanner } from '../redux/action';
import { defaultMessageError } from '~/app/constants';

interface Props {
  isEdit: boolean;
}

type isChangeImage = 'avatar' | 'banner';

const ProfileLeft: FC<Props> = ({ isEdit }) => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((s) => s.authReducer);
  const { data, updateAvatarAndBannerStatus, deleteAvatarStatus, deleteBannerStatus } = useAppSelector((state) => state.userReducer);
  const {
    description,
    last_name,
    first_name,
    email,
    phone_number,
    address,
    banner,
    facebook_link,
    twitter_link,
    tiktok_link,
    image: avatar,
  } = data || {};

  const [newBanner, setNewBanner] = useState<string>('');
  const [newAvatar, setNewAvatar] = useState<string>('');

  // Return link image correct
  const avatarLink = useMemo(() => process.env.REACT_APP_IMAGE_URL && avatar && `${process.env.REACT_APP_IMAGE_URL}${avatar}`, [avatar]);
  const bannerLink = useMemo(() => process.env.REACT_APP_IMAGE_URL && banner && `${process.env.REACT_APP_IMAGE_URL}${banner}`, [banner]);
  // Return full name seller
  const fullName = useMemo(() => last_name && first_name && `${last_name} ${first_name}`, [first_name, last_name]);

  //  Declare swal
  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });

  function handleChangeImage(e: React.ChangeEvent<HTMLInputElement>, isChange: isChangeImage) {
    const file = e.target.files?.[0];

    async function convertBase64Image() {
      if (file) {
        const typeFileAccept = ['image/png', 'image/jpeg'];
        const { type } = file;

        if (!typeFileAccept.includes(type)) {
          const title = type.startsWith('image')
            ? "Your photos couldn't be uploaded. Photos should be saved as JPG, PNG, JPEG."
            : "Your files isn't valid.";

          swalToast.fire({
            title,
            icon: 'error',
          });

          // Remove file when have error
          e.target.value = '';
          return;
        }
        try {
          const fileCovert = await convertBase64(file);

          isChange === 'banner' && setNewBanner((fileCovert as string) || '');
          isChange === 'avatar' && setNewAvatar((fileCovert as string) || '');
        } catch (error) {
          // Convert base64 failure
          swalToast.fire({
            title: defaultMessageError,
            icon: 'error',
          });
        } finally {
          e.target.value = '';
        }
      }
    }
    convertBase64Image();
  }

  function handleRemoveAvatar() {
    const payload = {
      data: { image: '' },
    };

    dispatch(deleteAvatar(payload, accessToken, setNewAvatar));
  }

  function handleRemoveBanner() {
    const payload = {
      data: { banner: '' },
    };

    dispatch(deleteBanner(payload, accessToken, setNewAvatar));
  }

  function handleUpdateAvatarAndBanner() {
    // Pass if both avatar and banner not changed
    if (!newAvatar && !newBanner) return;

    const updateAvatar = newAvatar ? { image: newAvatar.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') } : {};
    const updateBanner = newBanner ? { banner: newBanner.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') } : {};

    const payload = {
      data: {
        ...updateAvatar,
        ...updateBanner,
      },
    };

    dispatch(updateAvatarAndBanner(payload, accessToken, setNewAvatar, setNewBanner));
  }

  // function handleWhenImageError({ currentTarget }: any) {
  //   currentTarget.onerror = null;
  //   currentTarget.src = require('~/app/images/no-image-available.jpg');
  // }

  // Render JSX
  function renderBanner() {
    return (
      <div className="profile__banner">
        {(newBanner || bannerLink) && <img className="profile__banner-image" src={newBanner || bannerLink} alt="banner" />}
        {renderInputSelectImage('banner', 'banner')}
        {newBanner && <FontAwesomeIcon icon={faXmark} className="close-icon fs-5 cursor-pointer" onClick={() => setNewBanner('')} />}
      </div>
    );
  }

  function renderAvatar() {
    return (
      <div className="profile__avatar d-flex align-items-center justify-content-center">
        <div className="profile__avatar-rounded">
          <img src={newAvatar || avatarLink || require('~/app/images/no-avatar.png')} alt=" avatar" className="profile__avatar-image" />

          {renderInputSelectImage('avatar', 'avatar')}
          {newAvatar && <FontAwesomeIcon icon={faXmark} className="close-icon fs-5 cursor-pointer" onClick={() => setNewAvatar('')} />}
        </div>
      </div>
    );
  }

  function renderFullNameAndDescription() {
    return (
      <div className="profile__fullname-desc mt-4 d-flex flex-column align-items-center gap-3">
        <h3 className="profile__fullname text-uppercase fw-semibold fs-5 mb-0 text-truncate">{fullName}</h3>
        {description && <p className="profile__description mb-0 fs-6 fw-medium">{description}</p>}
      </div>
    );
  }

  function renderInfoUser() {
    return (
      <div className="profile__info mt-4">
        <ul className="profile__info-list p-0 d-flex flex-column gap-4">
          {/* Profile info item */}
          {fullName && (
            <li className="profile__info-item d-flex align-items-center">
              <div className="profile__info-icon fs-6">
                <FontAwesomeIcon icon={faUser} className="profile__info-svg" />
              </div>
              <p className="profile__info-text m-0 text-truncate fs-6 fw-medium" title={fullName}>
                {fullName}
              </p>
            </li>
          )}
          {/* Profile info item */}
          {email && (
            <li className="profile__info-item d-flex align-items-center">
              <div className="profile__info-icon fs-6">
                <FontAwesomeIcon icon={faEnvelope} className="profile__info-svg" />
              </div>
              <p className="profile__info-text m-0 text-truncate fs-6 fw-medium" title={email}>
                {email}
              </p>
            </li>
          )}
          {/* Profile info item */}
          {phone_number && (
            <li className="profile__info-item d-flex align-items-center">
              <div className="profile__info-icon fs-6">
                <FontAwesomeIcon icon={faPhone} className="profile__info-svg" />
              </div>
              <p className="profile__info-text m-0 text-truncate fs-6 fw-medium" title={phone_number}>
                {phone_number}
              </p>
            </li>
          )}
          {/* Profile info item */}
          {address && (
            <li className="profile__info-item d-flex align-items-center">
              <div className="profile__info-icon fs-6">
                <FontAwesomeIcon icon={faLocationDot} className="profile__info-svg" />
              </div>
              <p className="profile__info-text m-0 text-truncate fs-6 fw-medium" title={address}>
                {address || 'N/A'}
              </p>
            </li>
          )}
        </ul>
      </div>
    );
  }

  function renderActions() {
    return (
      <div className="profile__actions d-flex flex-column align-items-center gap-3 mt-4">
        <Button
          width="300px"
          iconLeft={faFloppyDisk}
          className="profile__actions-save fs-6 fw-semibold p-0"
          onClick={handleUpdateAvatarAndBanner}
          disabled={updateAvatarAndBannerStatus === 'pending' || (!newAvatar && !newBanner)}
        >
          {updateAvatarAndBannerStatus === 'pending' ? (
            <>
              Saving
              <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
            </>
          ) : (
            'Save'
          )}
        </Button>

        <div className="profile__actions-remove d-flex align-items-center">
          {/* Remove avatar */}
          <span
            className={`link fw-medium fs-6 d-flex align-items-center gap-12 ${
              !avatar || deleteAvatarStatus === 'pending' ? 'disabled' : ''
            }`}
            onClick={handleRemoveAvatar}
          >
            {deleteAvatarStatus === 'pending' ? (
              <>
                Removing
                <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
              </>
            ) : (
              'Remove Avatar'
            )}
          </span>

          {/* Separator */}
          <div className="separator"></div>

          {/* Remove banner */}
          <span
            className={`link fw-medium fs-6 d-flex align-items-center gap-12 ${
              !banner || deleteBannerStatus === 'pending' ? 'disabled' : ''
            }`}
            onClick={handleRemoveBanner}
          >
            {deleteBannerStatus === 'pending' ? (
              <>
                Removing
                <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
              </>
            ) : (
              'Remove Banner'
            )}
          </span>
        </div>
      </div>
    );
  }

  function renderInputSelectImage(id: string, isChange: isChangeImage) {
    if (!isEdit) {
      return <></>;
    }

    // Only show when edit
    return (
      <>
        <input id={id} type="file" hidden accept="image/png, image/jpeg" onChange={(e) => handleChangeImage(e, isChange)} />
        <label htmlFor={id} className="profile__camera d-flex align-items-center justify-content-center">
          <img src={require('~/app/images/camera.png')} alt="camera" />
        </label>
      </>
    );
  }

  function renderSocialMedia() {
    if (!facebook_link && !twitter_link && !tiktok_link) return <></>;

    return (
      <ul className="p-0 m-0 profile__social-list d-flex align-items-center justify-content-center gap-3 mt-3">
        {/* Profile social item */}
        {facebook_link && (
          <li className="profile__social-item">
            <a
              className="d-bock text-decoration-none cursor-pointer"
              href={facebook_link}
              title={facebook_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="profile__social-img" src={require('~/app/images/facebook.svg').default} alt="facebook" />
            </a>
          </li>
        )}
        {/* Profile social item */}
        {twitter_link && (
          <li className="profile__social-item">
            <a
              className="d-bock text-decoration-none cursor-pointer"
              href={twitter_link}
              title={twitter_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="profile__social-img" src={require('~/app/images/twitter.svg').default} alt="twitter" />
            </a>
          </li>
        )}
        {/* Profile social item */}
        {tiktok_link && (
          <li className="profile__social-item">
            <a
              className="d-bock text-decoration-none cursor-pointer"
              href={tiktok_link}
              title={tiktok_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="profile__social-img" src={require('~/app/images/tiktok.svg').default} alt="tiktok" />
            </a>
          </li>
        )}
      </ul>
    );
  }

  return (
    <div className="profile-left bg-white shadow-sm pb-4 rounded overflow-hidden d-flex flex-column flex-grow-1 h-100">
      {renderBanner()}
      {renderAvatar()}
      {renderActions()}
      {renderFullNameAndDescription()}
      {renderSocialMedia()}
      {renderInfoUser()}
    </div>
  );
};

export default ProfileLeft;
