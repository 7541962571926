import CustomCheckBox from '~/components/common/CustomCheckBox';
import './style.scss';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { useMemo } from 'react';
import { onChangeProductInWebsites } from '../../redux/actions';
import { iInfoWebsitesAndStoreItem } from '~/app/models';

const ProductInWebsites = () => {
  const dispatch = useAppDispatch();
  const { productInWebsites, currentWebsiteId } = useAppSelector((s) => s.createEditProductReducer);
  const { data: dataUser } = useAppSelector((s) => s.userReducer);
  const { website_ids } = dataUser;

  const listWebsite = useMemo(() => {
    if (!website_ids) return;
    const websiteList = JSON.parse(website_ids || '') as { [key: string]: iInfoWebsitesAndStoreItem };
    const websiteToArray = Object.entries(websiteList);

    const result = websiteToArray.map((item) => {
      const [id, infoWebsite] = item;

      return {
        id: +id,
        label: infoWebsite.name,
      };
    });

    return result;
  }, [website_ids]);

  function handleChangeProductInWebsites(id: number) {
    const isChecked = productInWebsites.includes(id);

    dispatch(onChangeProductInWebsites(isChecked ? productInWebsites.filter((item) => item !== id) : [...productInWebsites, id]));
  }

  return (
    <div className="product-in-website wrapper d-flex flex-column gap-3">
      <div className="d-flex align-items-start">
        <h3 className="title fs-16 fw-normal text-end me-4">Websites</h3>
        <div className="list-website">
          {listWebsite?.map(({ label, id }, i) => (
            <CustomCheckBox
              disabled={currentWebsiteId === id}
              key={id}
              className="mb-3 website-checkbox-custom"
              checkBoxClass="fs-6"
              checked={productInWebsites.includes(id)}
              name={label}
              label={label}
              onChange={() => handleChangeProductInWebsites(id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductInWebsites;
