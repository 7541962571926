import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  type?: 'button' | 'submit' | 'reset' | undefined;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  outline?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  width?: string;
  onClick?: () => void;
}

const Button = (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { children, type = 'button', disabled = false, outline, className = '', width, iconLeft, iconRight, onClick } = props;

  const classNames = `button d-flex align-items-center justify-content-center gap-12 text-center text-capitalize ${className} ${
    outline ? 'outline' : ''
  }`;

  return (
    <button ref={ref} disabled={disabled} className={classNames} type={type} onClick={onClick} style={{ width: width || 'unset' }}>
      {iconLeft && <FontAwesomeIcon icon={iconLeft} />}
      {children}
      {iconRight && <FontAwesomeIcon icon={iconRight} />}
    </button>
  );
};

export default forwardRef(Button);
