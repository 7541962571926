import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetDataById } from '~/app/models';
import { formatFilterStatus } from '~/components/common/Filter/formatFilter';
import DefaultLayout from '~/components/layout/DefaultLayout';
import { clearData, getDataById, postStatusOrder } from '../redux/actions';
import './style.scss';
import { PopupWrap } from '~/components/common/Popup';
import Select from '~/components/common/Select';
import { updateStatusSchema } from './Schema';
import { useFormik } from 'formik';
import Button from '~/components/common/Button';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import { defaultMessageError } from '~/app/constants';

interface Props {}

const TABS = [
  { name: 'Information', path: 'information' },
  // { name: 'Invoices', path: 'invoice' },
  // { name: 'Credit Memos', path: 'credit-memos' },
  // { name: 'Shipments', path: 'shipments' },
  { name: 'History', path: 'history' },
];

const OrderDetails: FC<Props> = (props: Props) => {
  const [, setTab] = useState<string>('Information');
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { dataDetails, commentStatus, statusUpdateStatusOrder, queryString } = useAppSelector((state) => state.orderReducer);
  const [showFormUpdateStatus, setShowFormUpdateStatus] = useState<boolean>(false);

  const popupUpdateStatusRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupUpdateStatusRef, () => toggleFormUpdateStatus());

  const updateStatusOrder = useFormik({
    initialValues: {
      newStatus: '',
      comment: '',
    },
    validationSchema: updateStatusSchema,
    validateOnChange: false,
    onSubmit: handleUpdateStatus,
  });
  const { values, errors, resetForm, setFieldError, handleSubmit, handleChange, setFieldValue } = updateStatusOrder;

  const tabSwitching = (tab: string) => {
    setTab(tab);
  };

  const { pathname } = useLocation();

  const formatStatus = (status: string) => {
    if (!status) return;
    if (['canceled', 'failed', 'closed', 'declined', 'cancelled'].includes(status))
      return (
        <span className="page-status table-status fs-5 table-status-danger text-capitalize fw-semibold">{formatFilterStatus(status)}</span>
      );

    if (['complete', 'processing', 'completed', 'new'].includes(status))
      return (
        <span className="page-status table-status table-status-success text-capitalize fw-semibold fs-5">{formatFilterStatus(status)}</span>
      );

    return (
      <span className="page-status table-status fs-5 table-status-warning text-capitalize fw-semibold">{formatFilterStatus(status)}</span>
    );
  };

  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });

  useEffect(() => {
    if (commentStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (commentStatus === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentStatus]);

  useEffect(() => {
    if (statusUpdateStatusOrder === 'fulfilled') {
      swalToast.fire({
        title: 'Update Successfully!',
        icon: 'success',
      });

      const payload: iGetDataById = {
        id: orderId || '0',
        accessToken,
      };
      dispatch(getDataById(payload, currentStore));
    } else if (statusUpdateStatusOrder === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdateStatusOrder]);

  useEffect(() => {
    if (orderId) {
      const payload: iGetDataById = {
        id: orderId,
        accessToken,
      };
      dispatch(getDataById(payload, currentStore));
    }
    return () => {
      dispatch(clearData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (dataDetails.state && showFormUpdateStatus) {
      setFieldValue('newStatus', convertNextStatus(dataDetails.state));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetails.state, showFormUpdateStatus]);

  function convertNextStatus(status: string) {
    switch (status) {
      case 'new':
        return 'preparing';
      case 'preparing':
        return 'shipped';
      case 'shipped':
        return 'completed';
      default:
        return '';
    }
  }

  function handleUpdateStatus(values: any) {
    const { comment, newStatus } = values;

    const payloadComment = comment ? { comment: comment } : {};
    const payload = {
      data: {
        status: newStatus,
        ...payloadComment,
      },
    };

    dispatch(postStatusOrder(orderId || '0', payload, accessToken, currentStore, toggleFormUpdateStatus));
  }

  function toggleFormUpdateStatus() {
    setShowFormUpdateStatus(!showFormUpdateStatus);
    showFormUpdateStatus && resetForm();
  }

  function covertStateToClassName(state: string) {
    switch (state) {
      case 'preparing':
      case 'shipped':
        return 'text-warning';
      case 'new':
        return 'text-success';
      default:
        return 'text-primary';
    }
  }

  function covertCurrentStateToOption(state: string) {
    switch (state) {
      case 'new':
        return (
          <>
            <option value="preparing">Preparing</option>
            <option value="canceled">Canceled</option>
          </>
        );
      case 'preparing':
        return (
          <>
            <option value="shipped">Shipped</option>
            <option value="canceled">Canceled</option>
          </>
        );
      case 'shipped':
        return <option value="completed">Completed</option>;
      default:
        return <></>;
    }
  }

  const renderTabs = () => {
    return (
      <div className="tabs bg-white d-flex flex-column">
        <div className="header d-flex justify-content-between align-items-center mb-3 text-end">
          <Link className="fw-semibold text-decoration-none fs-4" to={`/orders/${queryString}`}>
            <FontAwesomeIcon className="me-3" icon={faChevronLeft} />#{dataDetails.increment_id}
          </Link>

          {!['declined', 'cancelled', 'canceled', 'completed'].includes(dataDetails.state) && dataDetails.state && (
            <span className="link fs-14 fw-medium" onClick={toggleFormUpdateStatus}>
              Update Status
            </span>
          )}
        </div>
        {formatStatus(dataDetails.state)}

        <div className="tab-wrapper d-flex">
          {TABS.map((item, i: number) => {
            return (
              <div
                onClick={() => tabSwitching(item.name)}
                className={`tabs--item cursor-pointer me-4 ${pathname.split('/').includes(item.path) ? 'active' : ''} `}
                key={i}
              >
                <Link
                  to={`${item.path}`}
                  className={`pb-3 text-decoration-none fs-6 fw-medium ${
                    pathname.split('/').includes(item.path) ? 'text-dark' : 'text-dark-600'
                  }`}
                >
                  {item.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  function renderFormUpdateStatus() {
    return (
      <PopupWrap>
        <div className="content-update bg-white p-4 d-flex flex-column gap-4" ref={popupUpdateStatusRef}>
          <div className="heading d-flex flex-column gap-1">
            <div className="d-flex justify-content-between gap-3">
              <h3 className="title fw-semibold m-0">Update Status</h3>
              <FontAwesomeIcon icon={faXmark} className="close-icon cursor-pointer" onClick={toggleFormUpdateStatus} />
            </div>

            <h4 className="fs-6 fw-medium mb-0">Order #{dataDetails.increment_id}</h4>

            <p className={`current-status text-capitalize mb-0 fs-6 fw-semibold ${covertStateToClassName(dataDetails.state)}`}>
              {dataDetails.state}
            </p>
          </div>

          <div className="body d-flex flex-column gap-4">
            <div>
              <label htmlFor="new-status" className="form-label cursor-pointer fs-6">
                New Status
              </label>

              <Select
                className={`select-new-status ps-3 py-0 ${values.newStatus ? '' : 'text-dark-600'}`}
                name="newStatus"
                value={values.newStatus || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  errors.newStatus && setFieldError('newStatus', undefined);
                  handleChange(e);
                }}
                width="100%"
              >
                {covertCurrentStateToOption(dataDetails.state)}
              </Select>

              {errors.newStatus && <p className="mb-0 mt-2 text-danger fs-14">{errors.newStatus}</p>}
            </div>

            <div>
              <label htmlFor="comment" className="form-label cursor-pointer fs-6">
                Comment
              </label>
              <textarea
                className="form-control comment"
                id="comment"
                name="comment"
                value={values.comment}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></textarea>
            </div>
          </div>

          <div className="footer">
            <Button className="fs-6" outline onClick={toggleFormUpdateStatus}>
              Cancel
            </Button>
            <Button className="fs-6" onClick={handleSubmit} disabled={statusUpdateStatusOrder === 'pending'}>
              Save
            </Button>
          </div>
        </div>
      </PopupWrap>
    );
  }

  return (
    <DefaultLayout pageTitle={`Order Details`}>
      <div className="details-wrapper">
        {showFormUpdateStatus && renderFormUpdateStatus()}
        <div className="row">
          <div className="col-sm-12 col-xxl-12">
            {renderTabs()}
            <Outlet />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default OrderDetails;
