/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { convertGetPastDay, getPastDay } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iDataOrder, statusOrderReportDashboard } from '~/app/models/dashboard';
import { dashboardCriteria } from '~/app/utils';
import formatNumber from '~/app/utils/formatNumber';
import Chart from '~/components/common/Chart';
import { formatFilterStatus } from '~/components/common/Filter/formatFilter';
import { NoRecord } from '../Err';
import { getDataOrderReport } from './redux/actions';

import './style.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const OrderReport = (props: Props) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { statusOrderReport, dataOrderReport } = useAppSelector((s) => s.dashboardReducer);

  const currentDate = convertGetPastDay(getPastDay(1)[0]);
  const aYearAgo = convertGetPastDay(getPastDay(366)[365]);

  useEffect(() => {
    const payload = {
      accessToken,
      currentStore,
      searchUrl: dashboardCriteria({
        to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
        from: moment(new Date(aYearAgo)).format('YYYY-MM-DD'),
      }),
    };
    dispatch(getDataOrderReport(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, accessToken]);

  const data = useMemo(() => {
    const statusFind: statusOrderReportDashboard[] = ['new', 'preparing', 'shipped', 'completed'];
    let result: iDataOrder[] = [];

    // Sort by: new, preparing,shipped,completed
    statusFind.forEach((item: string) => {
      const itemFound = dataOrderReport.find(({ status }) => status === item);
      itemFound && result.push(itemFound);
    });
    return result;
  }, [dataOrderReport]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const total = dataOrderReport
    .filter((item) => ['new', 'preparing', 'shipped', 'completed'].includes(item.status))
    .reduce((accumulator: any, init: any) => accumulator + parseInt(init.subtotal), 0);

  const chartData = {
    labels: data.map((item: any) => item.status),
    datasets: [
      {
        label: 'Total',
        data: data.map((item) => item.subtotal),

        // Config
        backgroundColor: data.map((item) => covertStatusToBgc(item.status)),
        cutout: '60%',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            const status = context[0].label;
            return formatFilterStatus(status);
          },
        },
        titleAlign: 'center',
        titleMarginBottom: 12,
        padding: 16,
        boxPadding: 8,
      },
    },
  };

  function covertStatusToBgc(status: statusOrderReportDashboard) {
    // use hex colors because the histogram can't use variables to render the corresponding color
    switch (status) {
      case 'complete':
        return '#7FBC02';
      case 'pending':
        return '#E22626';
      case 'pending_payment':
        return '#bb2222';
      case 'processing':
        return '#FBBC04';
      case 'holded':
        return '#70BDDE';
      case 'closed':
        return '#AB1A60';
      case 'refund':
        return '#FF005C';
      case 'new':
        return '#7fbc02';
      case 'preparing':
        return '#ffb062';
      case 'shipped':
        return '#87bdd8';
      case 'completed':
        return '#180767';
      default:
        return '#180767';
    }
  }

  function renderInfoRefunds() {
    return data.map((item, index: number) => {
      const { status } = item;
      return (
        <div className="item d-flex align-items-center" key={index}>
          {statusOrderReport !== 'pending' ? (
            <>
              <div className="rect me-3" style={{ background: covertStatusToBgc(status) }} />
              <p className="desc m-0 fw-medium text-capitalize">{formatFilterStatus(status)}</p>
            </>
          ) : (
            <Skeleton width={100} height={20} />
          )}
        </div>
      );
    });
  }

  return (
    <div className="refunds mt-4 d-flex flex-column">
      {children}

      <div className="content-wrap bg-white p-4 d-flex flex-column align-items-center">
        {dataOrderReport.length ? (
          <>
            <div className="chart-wrap">
              {statusOrderReport !== 'pending' ? (
                <>
                  <div className="total d-flex flex-column text-center align-items-center">
                    <p className="qty mb-2 text-break w-100 fw-semibold">{formatNumber(total)}</p>
                    <h3 className="title m-0 fw-semibold">Total</h3>
                  </div>

                  <Chart type="Doughnut" chartData={chartData} options={chartOptions} />
                </>
              ) : (
                <Skeleton width={'100%'} height={'100%'} circle />
              )}
            </div>
            <div className="info mt-4">{renderInfoRefunds()}</div>
          </>
        ) : (
          <table>
            <tbody>
              <NoRecord tableHeaders={[]} />
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default OrderReport;
