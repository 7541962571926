import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iResetPwd } from '~/app/models';
import ButtonForm from '~/components/common/ButtonForm';
import FormWrapper from '~/components/common/FormWrapper';
import Loading from '~/components/common/Loading';
import FullWidthLayout from '~/components/layout/FullWidthLayout';
import { clearMessage, resetPassword } from '~/components/layout/FullWidthLayout/redux/actions';
import ResetPasswordSchema from './Schema';

import './style.scss';
import { TextField } from '~/components/common/Input';

const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const { status, message } = useAppSelector((state) => state.authReducer);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Remove message when reload page
    message && dispatch(clearMessage());
    resetForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (message && status === 'fulfilled') {
    setTimeout(() => {
      navigate('/auth/sign-in');
    }, 3000);
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, resetForm } = useFormik({
    initialValues: {
      password: '',
      confirmation: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      const token = searchParams.get('token');
      const id = searchParams.get('id') as string;

      const payload: iResetPwd = {
        users: [{ token: token || '', id: parseInt(id), ...values }],
      };

      dispatch(resetPassword(payload));
    },
  });

  const dataInput = [
    {
      name: 'password',
      label: 'New Password',
      type: 'password' as const,
      value: values.password,
      textError: errors.password,
      error: errors.password && touched.password,
    },
    {
      name: 'confirmation',
      label: 'Confirm Password',
      type: 'password' as const,
      value: values.confirmation,
      textError: errors.confirmation,
      error: errors.confirmation && touched.confirmation,
    },
  ];

  const renderListInput = () => {
    return dataInput.map((input) => {
      const { name, label, type, value, error, textError } = input;

      return (
        <TextField
          key={name}
          type={type}
          name={name}
          value={value}
          label={label}
          error={!!error}
          textError={textError}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setFieldTouched(name, false, false);
          }}
        />
      );
    });
  };

  const renderMessage = () => {
    if (message && status === 'rejected') {
      return <p className={`message m-0 mt-3 text-center text-danger`}>{message}</p>;
    }

    return (
      message &&
      status === 'fulfilled' && (
        <p className={`message m-0 mt-3 text-center text-success`}>
          {message}. This page will be redirected automatically in a few seconds.
        </p>
      )
    );
  };

  return (
    <FullWidthLayout pageTitle="Reset Password">
      <form className="password-validation d-flex flex-column align-items-center" onSubmit={handleSubmit}>
        <FormWrapper title="FORGOT PASSWORD VALIDATION" backLogin>
          <h6 className="sub-title text-center fw-normal mb-3">
            <span>Enter your new password.</span>
          </h6>

          {status === 'pending' ? <Loading /> : <div className="input-list">{renderListInput()}</div>}

          <>{renderMessage()}</>

          <ButtonForm disabled={status === 'pending' || status === 'fulfilled'}>SUBMIT</ButtonForm>
        </FormWrapper>
      </form>
    </FullWidthLayout>
  );
};

export default ResetPassword;
