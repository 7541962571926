import { FC } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';

const SHIPMENT_TABLE: iTableHead[] = [
  { name: 'Product', className: 'ps-0 w-75 fs-14 fw-semibold' },
  { name: 'Qty', className: 'w-50 fs-14 fw-semibold text-end px-2' },
];
export const TableShipment: FC = () => {
  const { status, shipmentDetail, dataDetails } = useAppSelector((state) => state.orderReducer);
  const { items } = shipmentDetail;
  const arr = items.map((item: any) => item.order_item_id);
  const itemWithOutConfigurable = dataDetails.items.filter((item: any) => arr.includes(item.item_id));

  const getItemData = (id: number, extension_attributes?: any) => {
    const item: any = items.filter((item: any) => item.order_item_id === id);
    return {
      qty: item[0].qty,
      name: item[0].name,
      sku: item[0].sku,
      extension_attributes,
    };
  };

  const renderDetails = (item: any) => {
    const { extension_attributes } = item;
    return item.parent_item ? renderTd(getItemData(item.parent_item.item_id)) : renderTd(getItemData(item.item_id, extension_attributes));
  };

  const renderTd = (item: any) => {
    const options = convertDeliveryCode(item?.extension_attributes?.deliverycode);
    return (
      <>
        <td className="fs-14 w-75 vertical-middle pe-2 ps-0">
          <p className="mb-2 fw-medium" dangerouslySetInnerHTML={{ __html: item.name }} />
          <p className="mb-2 sku-product">SKU: {item.sku}</p>
          {options &&
            options.map((option: any, i: number) => (
              <p key={i} className="mb-0 option-product">
                {option.label}: {option.value}
              </p>
            ))}
        </td>
        <td className="fs-14 vertical-middle px-2 text-end">{item.qty}</td>
      </>
    );
  };

  const convertDeliveryCode = (data: string[]) => {
    if (!data) return undefined;
    const deliveryString = JSON.parse(JSON.stringify(data));

    const result = deliveryString.map((item: any) => JSON.parse(item));

    return result;
  };

  return (
    <div className="info-table bg-white px-4 py-3 wrapper h-100">
      <p className="mb-4 fs-6 fw-semibold text-uppercase">Items Shipped</p>
      <Table status={status} dataTableHead={SHIPMENT_TABLE}>
        {itemWithOutConfigurable.length ? (
          itemWithOutConfigurable.map((item: any, i: number) => {
            return <tr key={i}>{renderDetails(item)}</tr>;
          })
        ) : (
          <NoRecord tableHeaders={SHIPMENT_TABLE} />
        )}
      </Table>
    </div>
  );
};
