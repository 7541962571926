import { createSlice } from '@reduxjs/toolkit';
import { Status } from '~/app/models';
import { iDataOrder, iDataOverview, iDataProductSold, iDataSalesStatistics, iDataTotalSales } from '~/app/models/dashboard';

interface iState {
  statusOverview: Status;
  statusTotalSales: Status;
  statusSalesStatistics: Status;
  statusProductSold: Status;
  statusOrderReport: Status;
  statusBestSeller: Status;
  dataOverview: iDataOverview;
  dataTotalSales: iDataTotalSales;
  dataSalesStatistics: iDataSalesStatistics[];
  dataProductSold: iDataProductSold[];
  dataOrderReport: iDataOrder[];
  bestSales: {
    items: [];
  };
}

const initialState: iState = {
  statusOverview: '',
  statusTotalSales: '',
  statusSalesStatistics: '',
  statusProductSold: '',
  statusOrderReport: '',
  statusBestSeller: '',
  // data
  dataOverview: {} as iDataOverview,
  dataTotalSales: {} as iDataTotalSales,
  dataSalesStatistics: [],
  dataProductSold: [],
  dataOrderReport: [],
  bestSales: {
    items: [],
  },
};

const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getBestSellesFulfilled: (state, action) => ({ ...state, bestSales: action.payload, statusBestSeller: 'fulfilled' }),
    getBestSellesPending: (state) => ({ ...state, statusBestSeller: 'pending' }),
    getBestSellesRejected: (state) => ({ ...state, statusBestSeller: 'rejected' }),

    getDataProductSoldPending: (state) => ({ ...state, statusProductSold: 'pending' }),
    getDataProductSoldRejected: (state) => ({ ...state, statusProductSold: 'rejected' }),
    getDataProductSoldFulfilled: (state, { payload }) => {
      state.statusProductSold = 'fulfilled';
      state.dataProductSold = payload;
    },

    getDataSalesStatisticsPending: (state) => ({ ...state, statusSalesStatistics: 'pending' }),
    getDataSalesStatisticsRejected: (state) => ({ ...state, statusSalesStatistics: 'rejected' }),
    getDataSalesStatisticsFulfilled: (state, { payload }) => ({
      ...state,
      statusSalesStatistics: 'fulfilled',
      dataSalesStatistics: payload,
    }),

    getDataOrderReportPending: (state) => ({ ...state, statusOrderReport: 'pending' }),
    getDataOrderReportRejected: (state) => ({ ...state, statusOrderReport: 'rejected' }),
    getDataOrderReportFulfilled: (state, { payload }) => ({
      ...state,
      statusOrderReport: 'fulfilled',
      dataOrderReport: payload,
    }),

    getDataOverviewPending: (state: iState) => {
      state.statusOverview = 'pending';
    },
    getDataOverviewRejected: (state: iState) => {
      state.statusOverview = 'rejected';
    },
    getDataOverviewFulfilled: (state: iState, { payload }) => {
      state.statusOverview = 'fulfilled';
      state.dataOverview = payload;
    },

    getDataTotalSalesPending: (state: iState) => {
      state.statusTotalSales = 'pending';
    },
    getDataTotalSalesRejected: (state: iState) => {
      state.statusTotalSales = 'rejected';
    },
    getDataTotalSalesFulfilled: (state: iState, { payload }) => {
      state.statusTotalSales = 'fulfilled';
      state.dataTotalSales = payload;
    },
  },
});

export const { ...rest } = dashboardReducer.actions;

export default dashboardReducer.reducer;
