import { useEffect, useMemo, useState } from 'react';
import Table from './Table';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { onChangeListProductAssociated } from '../../redux/actions';
import Pagination from '~/components/common/Pagination';

interface iPagination {
  pageSize: number;
  currentPage: number;
}

const TableProductAssociate = () => {
  const dispatch = useAppDispatch();
  const { listProductAssociated } = useAppSelector((state) => state.createEditProductReducer);

  const [listProductAssociatedCurrentPage, setListProductAssociatedCurrentPage] = useState<any[]>([]);
  const [pagination, setPagination] = useState<iPagination>({
    pageSize: 10,
    currentPage: 1,
  });

  useEffect(() => {
    const sliceFromIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const sliceToIndex = sliceFromIndex + pagination.pageSize;
    const listProductAssociatedCurrentPage = listProductAssociated.slice(sliceFromIndex, sliceToIndex);

    setListProductAssociatedCurrentPage(listProductAssociatedCurrentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, listProductAssociated]);

  const tableHeader = useMemo(
    () => [
      { className: 'bg-thead text-dark text-center', name: 'Image' },
      { className: 'bg-thead text-dark', name: 'Name' },
      { className: 'bg-thead text-dark', name: 'SKU' },
      { className: 'bg-thead text-dark text-end', name: 'Price' },
      { className: 'bg-thead text-dark text-end', name: 'Quantity' },
      { className: 'bg-thead text-dark text-end', name: 'Weight' },
      { className: 'bg-thead text-dark', name: 'Attributes' },
      { className: 'bg-thead text-dark text-center', name: 'Action' },
    ],
    [],
  );

  const handleRemoveProductAssociated = (id: any) => {
    dispatch(onChangeListProductAssociated(listProductAssociated.filter((p: any) => p.id !== id)));
  };

  return (
    <>
      <Table tableHeader={tableHeader}>
        {listProductAssociatedCurrentPage.map((item: any) => {
          const { id, name, sku, price, qty, weight } = item;
          const image = item.image?.file;

          const listAttr = Object.entries(item.attributes)
            .sort((f: any, s: any) => {
              const fItemName = f[0];
              const sItemName = s[0];
              if (fItemName > sItemName) return 1;
              if (fItemName < sItemName) return -1;
              return 0;
            })
            .map((item: any, index: number) => {
              const newLabel = item[0].replace(/-id\d+$/g, '');

              return (
                <span className="fs-14" key={index}>
                  <span className="fw-medium">{newLabel}: </span>
                  {item[1].label}
                </span>
              );
            });

          return (
            <tr key={id}>
              <td>
                <div className="d-flex justify-content-center align-items-center image-wrap rounded border mx-auto overflow-hidden">
                  {<img src={image ? image : require('~/app/images/thumbnail.png')} alt={image ? name : 'no thumbnail'} />}
                </div>
              </td>
              <td>
                <p className="name-product m-0">
                  <a
                    href={`${process.env.REACT_APP_SELLER}product/edit/${id}`}
                    className="link text-decoration-none text-wrap"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {name}
                  </a>
                </p>
              </td>
              <td>
                <p className="sku-product m-0">{sku}</p>
              </td>
              <td className="text-end">
                <p>{price}</p>
              </td>
              <td className="text-end">
                <p>{qty || 0}</p>
              </td>
              <td className="text-end">
                <p>{weight || 0}</p>
              </td>
              <td>
                <div className="d-flex flex-column gap-2">{listAttr}</div>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={faTrash} className="trash cursor-pointer" onClick={() => handleRemoveProductAssociated(item.id)} />
              </td>
            </tr>
          );
        })}
      </Table>
      <Pagination
        className="pagination-configuration"
        currentPage={pagination.currentPage}
        pageSize={pagination.pageSize}
        onChangePageEvent={(e) => setPagination((prev) => ({ ...prev, currentPage: e }))}
        totalCount={listProductAssociated.length}
        status="fulfilled"
        onChangePageSizeEvent={(e) => {
          setPagination((prev) => ({ ...prev, pageSize: +e.target.value || 10, currentPage: 1 }));
        }}
      />
    </>
  );
};

export default TableProductAssociate;
