import React, { useEffect, useMemo, useRef, useState } from 'react';
import { faCaretDown, faTriangleExclamation, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { getAllStores, getAllWebsites, getUser } from '~/components/pages/Account/redux/action';
import { logOutAction, resetPathname } from '../../FullWidthLayout/redux/actions';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import { Link, useNavigate } from 'react-router-dom';
import logo from '~/app/images/logo.png';
import Navigation from './Navigation';
import PopupComponent from '../../Popup';
import TransitPopup from '~/components/pages/Auth/Transit/Transit';
import storeImg from '~/app/images/store.svg';
import './style.scss';

interface iHeader {
  pageTitle: string;
}

const Header: React.FC<iHeader> = (props: iHeader) => {
  const { pageTitle } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // Declare data from Reducers
  const { currentStoreName } = useAppSelector((s) => s.authReducer);
  const { isLogin } = useAppSelector((state) => state.authReducer);
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const { data, status, storeData, websiteData } = useAppSelector((state) => state.userReducer);

  const imgPath = process.env.REACT_APP_IMAGE_URL;

  // Declare useState
  const [isDisplayNav /* setIsDisplayNav */] = useState<boolean>(true);
  const [isDisplayPopup, setIsDisplayPopup] = useState<boolean>(false);
  const [isDisplayTooltip, setIsDisplayToolTip] = useState<boolean>(false);

  const popupRef = useRef<HTMLDivElement>(null);
  const toolTipRef = useRef<HTMLDivElement>(null);

  const listStore = useMemo(() => {
    if (Object.keys(data).length < 1) {
      return [];
    }

    return Object.entries(JSON.parse(data.website_ids ?? {}))
      .map((item: any) => item[1].stores || [])
      .flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.website_ids]);

  // Declare useEffect
  useEffect(() => {
    if (!Object.keys(data || {}).length) {
      dispatch(getUser({ accessToken }));
    }

    if (!Object.keys(storeData || {}).length) {
      dispatch(getAllStores({ accessToken }));
    }

    if (!Object.keys(websiteData || {}).length) {
      dispatch(getAllWebsites({ accessToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onDisplayNav = () => {
  //   setIsDisplayNav((prev) => !prev);
  // };

  const onDisplayTooltip = () => {
    setIsDisplayToolTip((prev) => !prev);
  };

  const logout = () => {
    dispatch(logOutAction(navigate));
    dispatch(resetPathname());
  };

  useClickOutside(popupRef, () => {
    setIsDisplayPopup(false);
  });

  useClickOutside(toolTipRef, () => {
    setIsDisplayToolTip(false);
  });

  const renderTooltips = () => {
    return (
      <div
        className={`tooltips ${isDisplayTooltip ? '' : 'd-none'} py-3 bg-white d-flex justify-content-center align-items-start flex-column`}
      >
        {/* <Link
          className="tooltips--items text-decoration-none fw-medium text-dark text-start mb-3 fs-14 d-flex align-items-center d-block px-3 text-nowrap"
          to={'/profile/overview'}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon icon={faUser} />
          </div>
          My Profile
        </Link> */}
        <Link
          className="tooltips--items text-decoration-none fw-medium text-dark text-start mb-3 fs-14 d-flex align-items-center d-block px-3 text-nowrap"
          to={'/profile/settings'}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon icon={faUserPen} className="fs-6" />
          </div>
          Profile Settings
        </Link>
        <div className="flex-grow-1 px-3 w-100">
          <button onClick={logout} className="logout-btn rounded-pill text-white fw-semibold w-100 py-2 border-0">
            Logout
          </button>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="header-fixed wrapper">
        <div className="header-wrapper d-flex justify-content-between align-items-center gap-2 gap-md-3 gap-lg-4">
          <div className="header__logo">
            <Link to="/" className="d-inline-block">
              <img src={logo} alt="main logos" />
            </Link>
          </div>

          <Navigation isDisplay={isDisplayNav} />

          <div className="d-flex align-items-center">
            <div className="header__features d-flex justify-content-center align-items-center">
              {listStore.length > 1 ? (
                <div
                  className="header__features--settings cursor-pointer d-flex justify-content-center align-items-center border-0"
                  onClick={() => setIsDisplayPopup(!isDisplayPopup)}
                >
                  <img src={storeImg} alt="store" />
                </div>
              ) : null}
              <div
                className="header__features--avatar d-flex justify-content-center align-items-center cursor-pointer"
                onClick={onDisplayTooltip}
              >
                <img
                  className="rounded-circle logo logo-sm flex-shrink-0"
                  src={data.image ? imgPath + data.image : require('~/app/images/no-avatar.png')}
                  alt=""
                />
                <div className="position-relative d-flex align-items-start" ref={toolTipRef}>
                  <div className="d-flex flex-column ps-3 gap-2">
                    <p
                      className="triangle username mb-0 cursor-pointer fw-medium fs-6 text-truncate d-block w-150px"
                      title={`${data.last_name} ${data.first_name}`}
                    >
                      {status === 'fulfilled' && data.last_name && data.first_name && `${data.last_name} ${data.first_name}`}
                      {status === 'rejected' && <FontAwesomeIcon icon={faTriangleExclamation} className="me-2 text-danger" />}
                    </p>
                    <p className="m-0 fs-14 fw-medium text-nowrap">
                      Your store: <span className="text-danger">{currentStoreName || 'Singapore'}</span>
                    </p>
                  </div>
                  <FontAwesomeIcon icon={faCaretDown} className="ms-3" />
                  {renderTooltips()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPopup = () => {
    return (
      <PopupComponent status="fulfilled">
        <div ref={popupRef}>
          <TransitPopup isLogin={isLogin} toggleModal={() => setIsDisplayPopup(!isDisplayPopup)} />
        </div>
      </PopupComponent>
    );
  };

  return (
    <>
      <div className="header">
        {/* Render Header Section */}
        {renderHeader()}
        <div className="page-title text-primary text-uppercase fw-semibold">{pageTitle}</div>
        {isDisplayPopup && renderPopup()}
      </div>
    </>
  );
};

export default Header;
