import modalImg1 from "./../images/modal-1.png";
import modalImg2 from "./../images/modal-2.png";
import modalImg3 from "./../images/modal-3.png";

export const dataModal = [
  {
    title: "1. General",
    body: [
      "1.1 These Terms & Conditions apply when you access and/or use www.addin.sg (the “Website”), which is operated by AddIn (“us”, “we”, “our”).",
      "1.2 You should read these Terms & Conditions, and the Privacy Policy very carefully. The Privacy Policy is incorporated into these Terms & Conditions by reference. By accessing and/or using the Website or any part of it, you agree that you have read these Terms & Conditions and that you accept them and agree to be bound by them.",
      "1.3 We may also, from time to time, post on the Website, guidelines and rules relating to the use of the Website. All such guidelines or rules are hereby incorporated by reference into these Terms & Conditions.",
      "1.4 You agree that we may modify these Terms & Conditions without liability and without prior notice to you. The modified Terms & Conditions will be posted on the Website and will come into effect from the date of such posting. You are advised to check for updates to these Terms & Conditions regularly, prior to accessing and/or using the Website.",
    ],
  },
  {
    title: "2. Territory",
    body: [
      "2.1 The information on the Website is directed solely at persons accessing the Website from Singapore. We make no representation that any product or service referred to on the Website is available, or appropriate for use for any other location.",
    ],
  },
  {
    title: "3. Warranties",
    body: [
      "3.1 The information provided on the Website is for reference only. To the extent permitted by law, we disclaim all implied and/or express warranties and make no representation as to the accuracy, suitability, applicability or completeness of any information on the Website. We assume no liability for any loss or damage arising from your use of or reliance on the information on the Website or for anything posted on or linked to the Website, including without limitation any mistake, error, omission, infringement, defamation, falsehood or other material or omission that might offend or otherwise give rise to any claim or complaint.",
      "3.2 We disclaim any endorsement or recommendation of any person, organisation, name, product or service referred to in the Website. We further disclaim all liability in relation to information or materials posted by advertisers or sponsors on the Website.",
      "3.3 We make no warranties or representations in respect of your ability to access the Website and do not warrant that the functions of the Website shall be uninterrupted or error or defect free.",
      "3.4 None of our agents or representatives is authorised to make any warranties, representations or statements regarding any information on the Website and we shall not in any way be bound by any such unauthorised warranties, representations or statements.",
    ],
  },
  {
    title: "4. Limitation of Liability",
    body: [
      "4.1 By accessing and using the Website you acknowledge and accept that the use of the Website is at your own risk. We shall not be liable for any direct, indirect, incidental, consequential or punitive damages or for damages for loss of profit or revenue arising out of any use of, access to, or inability to use or access the Website.",
      "4.2 Without limiting the foregoing:",
      "(a) the website and all information and materials contained on it are provided “as is” without any warranty of any kind either express or implied including but not limited to any implied warranties or implied terms as to title, quality, merchantability, fitness for purpose, privacy or non-infringement;",
      "(b) we have no liability or responsibility for any errors or omissions in the contents of the website; and",
      "(c) we assume no responsibility and shall not be liable (to the extent permitted by law) for any damage or injury arising out of any use of or access to the website, or any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, interception of online communication, software or hardware problems (including without limitation loss of data or compatibility problems), theft, destruction or alteration of the website, whether for breach of contract, tortious behaviour, negligence or, under any other cause of action resulting directly or indirectly from any access or use of the website, or any uploading, downloading or publication of data, text, images or other material or information to or from the website.",
      "4.3 Brand manufacturers publish and sell products on AddIn’s platform, and directly communicate with consumers for all sales and purchase transactions. Addin does not assume any responsibility or provide any guarantee for the products being published and sold by brand manufacturers. Addin is not responsible for any damages, investigations or fines that result from the publication of products on AddIn’s platform. Addin has the right to cancel any orders generated through the brand under the circumstances that may or actually violate the law, in response to official investigations, requests or legal actions, and other AddIn policies4.2 .",
      "4.4 All information about the function, version and usage of the product published on Addin’s platform is provided by the manufacturer. The manufacturer is responsible for its authenticity, accuracy and legality. Addin does not provide any guarantees and does not assume any legal responsibility. Users have to consult the manufacturer, distributor or original publisher of the product to find out more about any products.",
      "4.5 AddIn does not exert control on the following matters, and does not provide guarantees or assume any responsibility for the following matters to the maximum extent permitted by law: (1) The applicability, authenticity, quality, and safety of the goods provided through this platform Or legality; (2) If the dispute involves one or more users, users agree to directly resolve the dispute with the manufacturer, and to the maximum extent permitted by law, exempt Addin from the dispute caused by or related to it claims, demands and damages.",
      "4.6 The goods are sold online on AddIn’s platform. AddIn is neither the buyer of the goods nor the seller of the goods. Goods are sold by brand manufacturers, and AddIn is a platform provider that helps facilitate transactions between sellers and buyers. Therefore, the contract formed with the sale of the goods is completed is only between the buyer and the seller. AddIn is not a party in the contract, nor does AddIn bear any liability arising therefrom or related to it. AddIn is not an agent of the seller or buyer. The seller is responsible for the sale of the goods and is responsible for handling any buyer’s claims or any other issues arising from or related to the contract between the buyer and the seller.",
      "4.7 Except as expressly provided in this clause, to the maximum extent permitted by law, AddIn and its third parties shall not deal with any direct, indirect, special, or any liability for incidental or consequential damages.",
    ],
  },
  {
    title: "5. Changes to Our Services",
    body: [
      "5.1 The Services that we provide are always evolving and the form and nature of the Services that we provide may change from time to time without prior notice to you. In addition, we may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to users generally and not be able to provide you with prior notice of such. We also retain the right to create limits on use and storage at our sole discretion at any time and without prior notice to you.",
      "5.2 We make no representations, warranties or guarantees, whether expressed or implied, that our Services or any content on our site is accurate, complete or up to date.",
    ],
  },
  {
    title: "6. Accessing Our Services",
    body: [
      "6.1 Our Services are made available free of charge.",
      "6.2 We do not guarantee that our Services, or any content, will always be available or be uninterrupted. Access to our Services is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our Services without notice to you. We will not be liable to you if for any reason our Services are unavailable at any time or for any period.",
      "6.3 You are responsible for making all arrangements necessary for you to have access to our Services.",
      "6.4 You are also responsible for ensuring that all persons who access our Services through your internet connection are aware of these Terms & Conditions and other applicable terms and conditions, and that they comply with them.",
    ],
  },
  {
    title: "7. Use of the Services",
    body: [
      "7.1 We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any Content on the Services, to suspend or terminate users, and to reclaim usernames without liability to you. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce the Terms & Conditions, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of AddIn, its users and the public.",
      "7.2 We do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.",
      "7.3 You may not do any of the following while accessing or using the Services: (i) access, tamper with, or use non-public areas of the Services, AddIn’s computer systems, or the technical delivery systems of AddIn’s providers; (ii) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (iii) access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by us (and only pursuant to those terms and conditions), (scraping the Services without our prior consent is expressly prohibited); (iv) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or (v) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, trojan, worm, logic bomb or other material which is malicious or technologically harmful, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services.",
      "7.4 We will report any such breach to the relevant law enforcement authorities and will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Services will cease immediately.",
    ],
  },
  {
    title: "8. Intellectual Property Rights",
    body: [
      "8.1 “Intellectual Property Rights” means any and all intellectual and industrial property rights now in force or that come into force in the future in any part of the world whether or not registered or registrable and includes all applications and rights to apply for registration (and renewals and extensions of any registration) of such rights as well as all rights of action and remedies in relation to past infringements including rights in or in connection with:",
      "(a) confidential information, business or trade secrets, know-how;",
      "(b) inventions, patents (including supplementary protection certificates);",
      "(c) copyright (including the rights to secure copyright renewals and extensions of copyright, copyright not yet in existence but that comes into existence in the future and all other rights of a like nature by law in force in any part of the world);",
      "(d) trademarks, service marks, business or trade names;",
      "(e) design rights, topography rights;",
      "(f) database rights, together with all other rights of a similar or corresponding character or nature.",
      "8.2 The Website and any information available on it including, but not limited to, text, graphics, icons, images, software, source code and trademarks, are protected by copyright, design, trademark and other Intellectual Property Rights owned or controlled by us and/or our licensors. You may not copy, reproduce, republish, upload, download, post, transmit, store in retrieval system, modify, alter or display in public or distribute in any way the contents or any part of the Website for any purpose without our prior written permission except that you may download materials displayed on the Website for non-commercial, home personal use only as long as you keep all copyright, trademark and other proprietary notices intact.",
      "8.3 All right, title and interest in the Intellectual Property Rights on the Website shall at all times remain the property of us and/or our licensors. You are not authorised to use the same unless you have obtained express written permission from us and/or our licensors, as the case may be.",
      "8.4 You agree not to use the Website for any purpose or in any matter that is unlawful or infringes ours or any third party’s Intellectual Property Rights.",
    ],
  },
  {
    title: "9. Prohibitions",
    body: [
      "9.1 When using the Website you cannot:",
      "(a) interfere or disable any security-related features of the Website or features that prevent or restrict use or copying of the content accessible via the Website;",
      "(b) give any false information in your account details;",
      "(c) take another person’s identity without that person’s permission or misrepresent you are acting on behalf of a person, entity or organisation;",
      "(d) use the Website if we have suspended or banned you from using it;",
      "(e) send junk, spam or repetitive messages;",
      "(f) engage in any illegal or unlawful conduct;",
      "(g) modify, interfere, intercept, disrupt or hack the Website;",
      "(h) misuse the Website by knowingly introducing viruses, malware, Trojans, worms, logic bombs or other similar material or by undertaking any other action which would harm the Website, any Website user’s equipment or have a detrimental impact on any Website user’s experience of using the Website;",
      "(i) collect any data from the Website other than in accordance with these Terms & Conditions;",
      "(j) submit or contribute any content that is abusive, threatening, obscene, untrue, shocking or offensive;",
      "(k) abuse, harm or bully another Website user, member of our staff or person;",
      "(l) submit or contribute any user content without the permission of the content owner or otherwise infringe the copyright, trademark or other rights of third parties; or",
      "(m) submit or contribute any information or commentary about another person which is untrue, private, confidential or defamatory (including personally accusing another person of unproven criminality or serious wrongdoing which could damage their reputation in the eyes of anyone reading your comment).",
      "9.2 In addition to terminating your access to the Website (in whole or in part) for breach of these Terms & Conditions, we can also take other actions as well, including, but not limited to, reporting you to the police or other law enforcement body, issuing a warning or taking legal action against you and pursuing any costs we incur as a result of doing this.",
    ],
  },
  {
    title: "10. Links and Sharing",
    body: [
      "10.1 Any links or opportunities to share (via social media, blogs, and similar sites and communication services) on the Website are provided solely for your use and convenience. The link does not represent any endorsement or recommendation by us and does not mean that we have any association with the linked website. We are not responsible for the content of any websites that are linked to or from the Website or for the legal consequences of your entering into any contracts with the third parties that provide these linked websites and we do not accept any liability for any loss, damage, expense, costs or liability whatsoever incurred by you as a result.",
      "10.2. Please note that if you do share content onto other platforms, there may be separate terms and conditions connected to the organisation that allows you to share. Please check the terms and conditions of any website you intend to share to. Some terms and conditions may prohibit you from sharing our content.",
      "10.3. We will not be liable for any loss, damage, expense, costs, delays or other liability whatsoever (including without limitation any financial losses such as loss of profit) which you may incur as a result of any event beyond our reasonable control (including without limitation any failure of transmission, communication, computer or other facilities or your inability to access the Website for any reason or any failure, error or delay in the sending or receiving of any notice or communication or instruction through the post or any electronic medium).",
    ],
  },
  {
    title: "11. Termination",
    body: [
      "11.1 We may, under certain circumstances and without prior notice to you, at our sole discretion, terminate your access to the Website (in whole or in part). Cause for such termination shall include without limitation:",
      "(a) breaches or violations of these Terms & Conditions;.",
      "(b) request by law enforcement or other government agencies;",
      "(c) infringement of Intellectual Property Rights;",
      "(d) discontinuance or material modification to the products/services provided on the Website; and",
      "(e) unexpected technical or security issues.",
      "11.2 Termination of your access to the Website (in whole or in part) shall be without prejudice to any other rights or remedies we may be entitled to under these Terms & Conditions, at law or in equity and shall not affect any accrued rights or liabilities nor the coming into or continuance in force of any provision which is expressly or by implication intended to come into or continue in force on or after such termination.",
    ],
  },
  {
    title: "12. Invalidity",
    body: [
      "12.1 In the event that one or more of the terms set out in these Terms & Conditions is held to be invalid, illegal or unenforceable by a competent authority, it will be deemed modified to the minimum extent necessary to make it valid, legal and enforceable and the remaining terms shall continue to be valid and enforceable..",
    ],
  },
  {
    title: "13. Waiver",
    body: [
      "13.1 If you commit any breach of these Terms & Conditions and we take no action, we will not be taken to have waived our rights against you, and will still be entitled to exercise our rights and remedies at a later stage or in any other situation where you commit another breach these Terms & Conditions.",
    ],
  },
  {
    title: "14. Governing Law and Jurisdiction",
    body: [
      "14.1 These Terms & Conditions shall be governed by and construed in accordance with the laws of Singapore, and you hereby irrevocably submit to the non-exclusive jurisdiction of the courts of Singapore.",
    ],
  },
  {
    title: "15. Products and Services",
    body: [
      "15.1 We provide good and services adhering to the Sales of Goods Act S14(2), Consumer Protection (Fair Trading) Act and Lemon Law.",
      "15.2 Customers are charged only upon confirmation of delivery.",
      "15.3 In the event there are defects with the product or should you require a refund for your purchase, please submit your request here, subject to our discretion.",
      "15.4 If upon unpacking the product, the product is found to be different from your order, you can request for a free refund.",
      "15.5 Payment methods include: Mastercard, Visa, PayNow, Alipay, NETSPay, ShopeePay Atome (payments spilt into 3 instalments) and, Omise.",
      "15.6 We are a GST-registered company. Prices displayed are inclusive of GST.",
      "15.7 For individuals below 18 years old: Consent must be obtained from your parent(s) or legal guardian(s), their acceptance of these Terms of Use and their agreement to take responsibility for: (i) your actions; (ii) any charges associated with your use of any of the Services or purchase of Products; and (iii) your acceptance and compliance with these Terms of Use. If you do not have consent from your parent(s) or legal guardian(s), you must stop using/accessing this Platform and using the Services.",
      "15.8 All complaints are resolved within 21 days upon receipt of complaint. In the event a complaint is not resolved, alternative forms of redress will be carried out.",
      "15.9 Address and contact of CASE mediation centre:",
      "170 Ghim Moh Road",
      "#05-01 Ulu Pandan Community Building",
      "Singapore 279621",
      "Opening Hours: 10am to 4pm on Mondays to Fridays (except Public Holidays)",
      "Tel: 9795 8397",
      "15.10 Regarding delivery address for furniture shipped by sea:",
      "Please notify our customer service team about a change of delivery address within 15 days after placing an order. Otherwise, an additional fee of SGD5 will be charged.",
    ],
  },
  {
    title: "16. Pre-sale Notice and Disclaimer",
    body: [
      "16.1 Colour Deviation",
      "Product pictures on the website are actual photos. However, due to factors such as studio shooting, lighting and screen display parameters, the colour of actual products received may differ from photos.",
      "16.2 Delivery Period",
      "Furniture is subject to production cycles. If the factory is unable to produce your purchased items within a reasonable timeframe, our customer service team will notify you. To request for urgent delivery of items, please contact our customer service team separately.",
      "16.3 Dimension",
      "Differences in dimensions caused by inconsistent product measurement standards are not considered quality issues if the dimensions varies between 1cm and 5cm.",
      "16.4 Packaging",
      "For bulky items like furniture, a wooden frame is recommended when packing the item for delivery. For more details, please contact our customer service team.",
      "16.5 Signing off Packages",
      "To help us better assess if received items are damaged due to logistics reasons, and address differences in items in terms of colour, dimension and location of holes owing to different production batches, please take a photograph and video of the packaged items when they first arrive.",
      "Photos and videos should include the packaged product as delivered, as well as the product label and code as shown below:",
    ],
    img: [modalImg1, modalImg2, modalImg3],
    body2: [
      "Confirmation:",
      "Signing off on the received items means that you certify the items have been received in person, and that the goods are received in good condition, without any defects. As such, customers are responsible for checking that the goods are in good condition before signing off the receipt. Should there be faulty items, take a photo of the defective items or missing parts before asking the delivery staff to sign off. Thereafter, contact the seller to address the issue.",
      "16.6 Installation and Post-sales Services",
      "We strongly advise customers to engage our installation service. A professional will be in touch with you to make an appointment for installation. For self-installation, please refer to the product’s illustrated guide. Please contact our customer service team if you have further queries. If the product is found to have quality issues (under the condition that quality is not compromised after delivery, and the item is uninstalled and unused), you can request for a return. Upon confirmation, we will process the refund.",
      "Warranty for furniture covers quality issues including missing or damaged parts that did not arise during the shipping or delivery process.",
      "16.7 Delivery",
      "A delivery surcharge will be collected in situations when there is no lift or elevator at your address, or lifts and elevators cannot meet the delivery requirements (i.e. narrow lifts and stairs). As such, please check the dimension of items when purchasing.",
      "16.8 Returns of Items",
      "Due to high logistics costs incurred for bulky furniture, our priority is replacing damaged parts when defects are found. Defective parts, including damaged removeable cloth cover, and damages that arise during delivery cannot be returned. Instead, you can contact our customer service team to assist with parts replacement.",
      "(Important: Where possible, we will purchase replacement parts locally. Parts that need to be reissued overseas require an estimated 7-8 weeks to be sent to Singapore after your request is submitted)",
      "Once installed, bulky items such as furniture cannot be returned or exchanged if there are no defective parts.",
    ],
  },
  {
    title: "17. Notices",
    body: [
      "17.1 If you have any questions about these Terms & Conditions, you may contact us either by post or by email at the following address:",
      "Address: 57A NEIL ROAD, SINGAPORE 088893",
      "Contact number: +65 9773 6980",
      "Email: support@addin.sg",
    ],
  },
];
