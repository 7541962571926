import reportApi from '~/app/apis/report.api';
import { iQueries, iReport } from '~/app/models/report';
import * as types from './index';
const { rest: actions } = types;

export type typeReportReset = 'customer' | 'order' | 'refund' | 'product' | 'invoice';

export const getOrderReport = (payload: iReport) => async (dispatch: any) => {
  dispatch(actions.isPending());
  try {
    const res = await reportApi.getOrderReport(payload);
    const { data } = res;
    data ? dispatch(actions.getOrderReportFulfilled(data)) : dispatch(actions.isRejected());
  } catch (error) {
    dispatch(actions.isRejected());
  }
};

export const getInvoiceReport = (payload: iReport) => async (dispatch: any) => {
  dispatch(actions.isPending());
  try {
    const res = await reportApi.getInvoiceReport(payload);
    const { data } = res;
    data ? dispatch(actions.getInvoiceFulfilled(data)) : dispatch(actions.isRejected());
  } catch (error) {
    dispatch(actions.isRejected());
  }
};

export const getRefundedReport = (payload: iReport) => async (dispatch: any) => {
  dispatch(actions.isPending());
  try {
    const res = await reportApi.getReFundedReport(payload);
    const { data } = res;
    data ? dispatch(actions.getRefundedFulfilled(data)) : dispatch(actions.isRejected());
  } catch (error) {
    dispatch(actions.isRejected());
  }
};

export const getProductReport = (payload: iReport) => async (dispatch: any) => {
  dispatch(actions.isPending());
  try {
    const res = await reportApi.getProductReport(payload);
    const { data } = res;
    data ? dispatch(actions.getProductsFulfilled(data)) : dispatch(actions.isRejected());
  } catch (error) {
    dispatch(actions.isRejected());
  }
};

export const getCustomerReport = (payload: iReport) => async (dispatch: any) => {
  dispatch(actions.isPending());
  try {
    const res = await reportApi.getCustomerReport(payload);
    const { data } = res;
    data ? dispatch(actions.getCustomerFulfilled(data)) : dispatch(actions.isRejected());
  } catch (error) {
    dispatch(actions.isRejected());
  }
};

export const resetData = () => (dispatch: any) => {
  dispatch(actions.resetData());
};

export const setQueries = (payload: iQueries) => (dispatch: any) => {
  const { type, query } = payload;
  type === 'customer' && dispatch(actions.setCustomerQueries(query));
  type === 'invoice' && dispatch(actions.setInvoiceQueries(query));
  type === 'order' && dispatch(actions.setOrderQueries(query));
  type === 'product' && dispatch(actions.setProductQueries(query));
  type === 'refund' && dispatch(actions.setRefundedQueries(query));
};

export const reportReset = (type: typeReportReset) => (dispatch: any) => {
  type === 'customer' && dispatch(actions.resetCustomers());
  type === 'invoice' && dispatch(actions.resetInvoices());
  type === 'order' && dispatch(actions.resetOrder());
  type === 'product' && dispatch(actions.resetProducts());
  type === 'refund' && dispatch(actions.resetRefunded());
};
