import numeral from 'numeral';

const currencySymbols: { [key: string]: String } = {
  // VN: '₫',
  // TH: '฿',
  // PH: '₱',
  // ID: 'Rp',
  // my_en: 'RM',
  // sg_en: '$',
  // TW: 'NT$',
  // hk_en: 'HK$',
  // hk_zh: 'HK$',
  // vn_vn: '₫',
  my: 'RM',
  sg: '$',
  hk: 'HK$',
  vn: '₫',
  tw: 'NT$',
  id: 'Rp',
  th: '฿',
  ph: '₱',
};

export const formatMoney = (amount: number = 0, localization: string = 'sg_en') => {
  const code = localization?.split('_')[0];

  return `${currencySymbols[code] ?? '$'}${numeral(+amount).format('0,0.00')}`;
};

export const formatCurrency = (localization: string = 'sg_en') => {
  const code = localization?.split('_')[0];
  return currencySymbols[code] ?? '$';
};

export const filterEmptyProperties = (obj: { [key: string]: any }) => {
  let o = Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((arr, key) => ({ ...arr, [key]: obj[key] }), {});
  return o;
};

export function formatDate() {
  const d = new Date();
  const year = d.getFullYear();
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const hour = `${d.getHours()}`.padStart(2, '0');
  const minute = `${d.getMinutes()}`.padStart(2, '0');
  const second = `${d.getSeconds()}`.padStart(2, '0');
  const milliSeconds = d.getMilliseconds();

  const result = `${year}${month}${day}${hour}${minute}${second}${milliSeconds}`;
  return result;
}
