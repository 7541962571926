import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '~/app/hooks/hooks';
import { exportCSV, filterEmptyProperties, getStoreId } from '~/app/utils';
import DefaultLayout from '~/components/layout/DefaultLayout';
import './style.scss';
import Select from '~/components/common/Select';

type Props = {};

const SalesReport: FC = (props: Props) => {
  const baseUrl = process.env.REACT_APP_URL;
  const { pathname } = useLocation();

  const { orderQueries, productQueries } = useAppSelector((s) => s.reportReducer);
  const { currentStore, accessToken } = useAppSelector((s) => s.authReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);

  const [searchParams] = useSearchParams();
  const period = searchParams.get('period') || '';
  const status = searchParams.get('status') || '';
  const from = searchParams.get('from') || '';
  const to = searchParams.get('to') || '';

  const LINKS: { realName: string; label: string; url: string; exportLink: string }[] = [
    { realName: 'orders-report', label: 'Orders', url: `orders-report${orderQueries}`, exportLink: 'order' },
    // { realName: 'customers-report', label: 'Customers', url: `customers-report${customerQueries}`, exportLink: 'customer' },
    // { realName: 'invoices-report', label: 'Invoices', url: `invoices-report${invoiceQueries}`, exportLink: 'invoice' },
    { realName: 'products-report', label: 'Products', url: `products-report${productQueries}`, exportLink: 'product' },
    // { realName: 'refunds-report', label: 'Refunds', url: `refunds-report${refundedQueries}`, exportLink: 'refund' },
  ];

  const [pageTitle, setPageTitle] = useState<string>('Orders');

  const arrPathName = pathname.split('/');

  const renderTabs = () => {
    return (
      <div className="report-tabs bg-white wrapper">
        {LINKS.map((link, i: number) => (
          <Link
            key={i}
            className={`report-tabs--items d-block fs-6 text-decoration-none fw-semibold p-4 report-tabs--items ${
              arrPathName.includes(link.realName) ? 'active' : ''
            }`}
            to={link.url}
            onClick={() => {
              setPageTitle(link.label);
            }}
          >
            <span>{link.label}</span>
          </Link>
        ))}
      </div>
    );
  };

  const csvQueries = () => {
    const values = {
      from,
      to,
      period,
      status,
      store_id: getStoreId(storeData, currentStore),
      token: accessToken,
    };
    const filteredObject = filterEmptyProperties(values);

    return exportCSV(filteredObject);
  };

  const exportCSVUrl = (): string => {
    let l = 'order'; // l mean Link
    let q = csvQueries(); // q mean Query
    const arr = pathname.split('/');
    if (arr.includes('orders-report')) l = 'order';
    if (arr.includes('customers-report')) l = 'customer';
    if (arr.includes('invoices-report')) l = 'invoice';
    if (arr.includes('products-report')) l = 'product';
    if (arr.includes('refunds-report')) l = 'refund';

    const url = `${baseUrl}addin_report/export/${l}?${q}`;
    return url;
  };

  const renderExport = () => {
    return (
      <div className="bg-white p-4 wrapper mt-3">
        <span className="fs-6 fw-medium">Export to</span>
        <div className="d-flex mt-2">
          <Select className="w-unset flex-grow-1" onChange={() => {}}>
            <option value="">CSV</option>
          </Select>

          <a download className="export-button cursor-pointer text-white bg-primary ms-3" href={exportCSVUrl()}>
            <FontAwesomeIcon icon={faArrowUpFromBracket} />
          </a>
        </div>
      </div>
    );
  };

  return (
    <DefaultLayout pageTitle={`${pageTitle} Report`}>
      <div className="report-wrapper">
        <div className="row">
          <div className="col-xl-3">
            {renderTabs()}
            {renderExport()}
          </div>
          <div className="col-xl-9">
            <Outlet />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SalesReport;
