import React, { useEffect } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import Footer from './Footer';
import Header from './Header';
import './style.scss';

interface Props {
  children: JSX.Element[] | JSX.Element;
  pageTitle?: string;
}

const DefaultLayout: React.FC<Props> = (props: Props) => {
  const { children, pageTitle = '' } = props;
  const { currentStoreName } = useAppSelector((s) => s.authReducer);
  // const { status, data } = useAppSelector((s) => s.userReducer);

  useEffect(() => {
    document.title = `${pageTitle} | ${process.env.REACT_APP_PRE_PAGE_TITLE} | ${currentStoreName || 'Singapore'}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle, currentStoreName]);

  // const renderBanner = () => {
  //   return (
  //     <div className="banner d-flex justify-content-start align-items-center ">
  //       <div className="banner--date text-white fs-6 fw-medium">{datetime}</div>
  //       <div className="banner--greeter text-white fw-semibold ms-4 ">
  //         {status === 'fulfilled' && `Hi ${data.last_name} ${data.first_name}!`}
  //         {status === 'rejected' && <FontAwesomeIcon icon={faTriangleExclamation} className="me-2 text-danger" />}
  //       </div>
  //       <div className="banner--current-page fs-5 fw-semibold text-white text-uppercase">{pageTitle}</div>
  //       <div className="banner--store fs-5 text-white fw-semibold text-uppercase">Your Store: {convertCodeToStore(currentStore)}</div>
  //     </div>
  //   );
  // };

  return (
    <div className="container-fuild">
      <div className="app-container">
        <div>
          <div className="min-vh-100 d-flex flex-column">
            <Header pageTitle={pageTitle} />
            <div className="flex-grow-1 d-flex flex-column">
              {/* {renderBanner()} */}
              <div className="content-section">{children}</div>
              <div className="mt-auto">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
