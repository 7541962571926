import { useEffect } from 'react';

// import { getUser } from '../redux/action';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { logOutAction, resetPathname } from '~/components/layout/FullWidthLayout/redux/actions';
import SignInMethod from './SignInMethod';
import Settings from './Settings';
// import Info from './Info';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import './style.scss';

type Props = {};

const Setting = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { updateStatus, updatePasswordStatus, updateAvatarAndBannerStatus, message, deleteAvatarStatus, deleteBannerStatus } =
    useAppSelector((state) => state.userReducer);

  // eslint-disable-next-line

  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 3000,
  });

  useEffect(() => {
    if (updateAvatarAndBannerStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (updateAvatarAndBannerStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAvatarAndBannerStatus]);

  useEffect(() => {
    if (updateStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (updateStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  useEffect(() => {
    if (updatePasswordStatus === 'fulfilled') {
      swalToast
        .fire({
          title: 'Save Successfully!',
          icon: 'success',
        })
        .then(function () {
          dispatch(logOutAction(navigate));
          dispatch(resetPathname());
        });
    } else if (updatePasswordStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePasswordStatus]);

  useEffect(() => {
    if (deleteAvatarStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (deleteAvatarStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAvatarStatus]);

  useEffect(() => {
    if (deleteBannerStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (deleteBannerStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBannerStatus]);

  return (
    <>
      <Settings />
      <SignInMethod />
    </>
  );
};

export default Setting;
