import { iInitState, iTableHead } from './../../../app/models';
import { FC } from 'react';

import './style.scss';
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { NoRecord } from '~/components/pages/Err/';

interface Props extends iInitState {
  children: JSX.Element | JSX.Element[];
  dataTableHead: iTableHead[];
  className?: string;
  message?: string;
  sortOrder?: any;
  keySearch?: string;
  isDESC?: boolean;
}

export const Table: FC<Props> = (props: Props) => {
  const { className = '', dataTableHead, children, status, message, sortOrder, keySearch, isDESC } = props;

  const onSortHandler = (key: string | undefined) => {
    if (!key) return;
    sortOrder(key);
  };

  //   Render table header
  const RenderTableHeader = () => {
    return (
      <thead className="thead">
        <tr>
          {dataTableHead.map((item, i: number) => (
            <td className={`${item.className}`} key={i} data-title={item.name} id={item.key} onClick={() => onSortHandler(item.key)}>
              <span>{item.name}</span>
              {keySearch && keySearch === item.key ? (
                isDESC ? (
                  <FontAwesomeIcon icon={faArrowDown} className="ms-1" />
                ) : (
                  <FontAwesomeIcon icon={faArrowUp} className="ms-1" />
                )
              ) : (
                ''
              )}
            </td>
          ))}
        </tr>
      </thead>
    );
  };

  return (
    <table className={`table m-0 ${className}`}>
      <RenderTableHeader />
      <tbody>
        {(status === 'pending' || !status) && (
          <tr>
            <td className="text-center" colSpan={dataTableHead.length}>
              <Loading />
            </td>
          </tr>
        )}
        {status === 'fulfilled' && children}
        {status === 'rejected' && <NoRecord tableHeaders={dataTableHead} message={message} />}
      </tbody>
    </table>
  );
};
