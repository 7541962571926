export function slugify(str: string) {
  str = str.toLowerCase().trim();

  // Remove viename language
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');

  return str
    .replace(/[&/\\#,+()$~%.'":*?<>{}!]/g, '')
    .replace(/-+/g, ' ')
    .trim()
    .split(' ')
    .filter((item) => item)
    .join('-');
}

export function convertDescriptionToPayload(description: string) {
  if (!description || typeof description !== 'string') return '';

  return `<div style="max-width:1400px">${description
    .replaceAll('<div data-oembed-url', '<div style="width: 100%; height: auto;; max-width:1400px" data-oembed-url')
    .replaceAll('<table', '<table style="border: 1px solid black;border-collapse: collapse;max-width: 1400px;"')
    .replaceAll('<th', '<th style="border: 1px solid black;border-collapse: collapse;"')
    .replaceAll('<td', '<td style="border: 1px solid black;border-collapse: collapse"')}</div>`
    .replaceAll('alt="desktop-image"', 'class="pagebuilder-mobile-hidden" data-element="desktop_image"')
    .replaceAll('alt="mobile_image"', 'class="pagebuilder-mobile-only" data-element="mobile_image"');
}
