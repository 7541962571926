import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useAppSelector } from '~/app/hooks/hooks';
import { NoRecord } from '../Err';
import Loading from '~/components/common/Loading';
import moment from 'moment';

const ListTicket = () => {
  const { dataTicketListing, status } = useAppSelector((state) => state.ticketReducer);
  const { items: listTicket } = dataTicketListing;

  function covertPriorityToText(priority_id: string) {
    switch (priority_id) {
      case '0':
        return 'Low Priority';
      case '1':
        return 'Medium Priority';
      case '2':
        return 'High Priority';
      case '3':
        return 'Ugent Priority';
      default:
        return 'N/A Priority';
    }
  }

  function covertStatusIdToBackground(status_id: string) {
    switch (status_id) {
      case '0':
        return 'bg-danger';
      case '1':
        return 'bg-primary';
      case '2':
        return 'bg-warning';
      case '3':
        return 'bg-success';
      default:
        return 'bg-dark';
    }
  }

  function renderListTicket() {
    return listTicket?.map((ticket) => {
      const { ticket_id, customer_name, subject, description, priority_id, status_id, created_at, updated_at, is_read } = ticket;
      return (
        <div className="ticket-item p-4 bg-white" key={ticket_id}>
          <div className="heading d-flex align-items-center justify-content-between pb-3 mb-3 gap-5">
            <div className="left d-flex align-items-center gap-3">
              <div className={`status-color rounded-circle ${covertStatusIdToBackground(status_id)}`}></div>
              <h3 className="ticket-id m-0 fw-semibold text-dark">Ticket #{ticket_id}</h3>
              {priority_id && <button className="priority fs-14 fw-medium px-12 py-1">{covertPriorityToText(priority_id)}</button>}
            </div>

            <div className="right">
              <Link to={ticket_id} className="text-decoration-none d-flex align-items-center fw-medium link">
                <span>Open Ticket</span>
                <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
              </Link>
            </div>
          </div>

          <div className="body d-flex flex-column gap-3">
            <div className="customer-name m-0 text-dark-600 fs-14 d-flex justify-content-between">
              <p className="mb-0">
                By Customer: <span className="text-capitalize">{customer_name}</span>
              </p>
              <p className="mb-0">
                <span className="create-day m-0 text-dark-600 fs-14 border-end pe-3">
                  Create at {moment(created_at).format('MMM D, YYYY')}
                </span>
                <span className="update-day m-0 text-dark-600 fs-14 ps-3"> Update at {moment(updated_at).format('MMM D, YYYY')}</span>
              </p>
            </div>
            <h3 className={`subject m-0 fs-6 fw-medium ${is_read === '0' ? 'unread' : 'read'}`}>{subject}</h3>
            <p className="desc m-0 text-dark-600 fs-14">{description}</p>
          </div>
        </div>
      );
    });
  }

  // status === "" still show Loading
  return status === 'pending' || !status ? (
    <div className="ticket-item bg-white p-4">
      <Loading />
    </div>
  ) : (
    <div className="d-flex flex-column gap-2 overflow-auto">
      {!listTicket?.length ? (
        <table className="ticket-item bg-white overflow-hidden">
          <tbody>
            <NoRecord tableHeaders={[]} />
          </tbody>
        </table>
      ) : (
        renderListTicket()
      )}
    </div>
  );
};

export default ListTicket;
