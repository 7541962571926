import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '~/store';

const devBaseUrl = `${process.env.REACT_APP_URL}rest`;

const axiosConfig = axios.create({
  baseURL: devBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosConfig.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  },
);

axiosConfig.interceptors.request.use(
  function (config) {
    config.headers = config.headers ?? {};

    const token = store.getState().authReducer.accessToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const config = (accessToken: string) => ({
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export default axiosConfig;
