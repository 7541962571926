/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown, faChevronUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { convertBase64 } from '~/app/utils/convertBase64';
import Button from '~/components/common/Button';
import { onChangeProductGenerate } from '../../redux/actions';
import { v4 as uuidv4 } from 'uuid';
import TableProductAssociate from './TableProductAssociate';
import { FormikProps } from 'formik';

interface iValueAttrSelect {
  label: string;
  value: string;
}

interface Props {
  formik: FormikProps<any>;
}

const Step2: FC<Props> = ({ formik }) => {
  const { touched, errors, setFieldTouched, handleBlur } = formik;

  const dispatch = useAppDispatch();
  const { dataProductGenerate, dataValuesConfigSelected, listProductAssociated, dataHeader } = useAppSelector(
    (state) => state.createEditProductReducer,
  );
  const { name, sku, price } = dataHeader;

  const [showListAssociatedProduct, setShowListAssociatedProduct] = useState<boolean>(true);

  useEffect(() => {
    const arrAttrValuesSelected = Object.entries(dataValuesConfigSelected)
      .sort((f: any, s: any): number => {
        const fItem = f[0];
        const sItem = s[0];
        if (fItem > sItem) return 1;
        if (fItem < sItem) return -1;
        return 0;
      })
      .map((item) => {
        return item[1];
      }) as iValueAttrSelect[][];

    // Create array attribute combine
    const handleChangeAttrCombine = (prevArr: iValueAttrSelect[][], index: number) => {
      let arrNew = [...prevArr];

      if (index === 0) {
        arrNew.push(...arrAttrValuesSelected[index].map((item) => [item]));
      } else {
        const arr = arrNew.map((item) => {
          const result = arrAttrValuesSelected[index] ? arrAttrValuesSelected[index].map((attr) => [...item, attr]) : [item];
          return result;
        });
        arrNew = arr.flat();
      }

      return arrNew;
    };

    let listAttrCombine: iValueAttrSelect[][] = [];

    arrAttrValuesSelected.forEach((_, index) => {
      if (index !== arrAttrValuesSelected.length) {
        const arrNew = handleChangeAttrCombine(listAttrCombine, index);
        listAttrCombine = arrNew;
      }
    });

    const listProductAutoGenerate = listAttrCombine.map((item) => {
      const id = uuidv4();
      const attrLabel = item.reduce((result, current) => `${result}-${current.label}`, '');
      const attrLabelAndId = Object.entries(dataValuesConfigSelected)
        .sort((f: any, s: any): number => {
          const fItem = f[0];
          const sItem = s[0];
          if (fItem > sItem) return 1;
          if (fItem < sItem) return -1;
          return 0;
        })
        .map((item) => {
          return item[0];
        });
      const attrOfProduct = Object.fromEntries(attrLabelAndId.map((label, index) => [label, item[index]]));

      const nameGenerate = `${name}${attrLabel}`;
      const skuGenerate = `${sku}${attrLabel}`;
      const newProduct = {
        id,
        [`image${id}`]: null,
        [`name${id}`]: nameGenerate,
        [`sku${id}`]: skuGenerate,
        [`price${id}`]: Number.isNaN(+price) ? '0' : +price,
        [`qty${id}`]: '',
        [`weight${id}`]: '',
        [`attributes${id}`]: attrOfProduct,
      };

      return newProduct;
    });

    const listAttrProductAssociated = listProductAssociated.map((productAssociated) => {
      const { attributes } = productAssociated;
      return Object.entries(attributes);
    }) as [string, iValueAttrSelect][][];

    const newListProductAutoGenerate = listProductAssociated.length
      ? listProductAutoGenerate.filter((item) => {
          const { id } = item;
          const attr: any = item[`attributes${id}`] || {};

          // Case add or remove attribute -> automatically accepted product
          if (Object.keys(attr).length !== listAttrProductAssociated[0].length) return true;

          // In list product associated if only 1 product has attributes set duplicate will remove
          const isDuplicate = listAttrProductAssociated.some((item) => {
            const duplicateAttr = item.every((attrProductAssociated) => {
              const [attLabelAndId, attrOptionSelect] = attrProductAssociated;

              return attr[attLabelAndId]?.value === attrOptionSelect.value ? true : false;
            });

            // Break loop
            return duplicateAttr ? true : false;
          });

          return !isDuplicate;
        })
      : listProductAutoGenerate;

    dispatch(onChangeProductGenerate(newListProductAutoGenerate));
  }, []);

  const tableHeader = useMemo(
    () => [
      { className: 'bg-thead text-dark text-center', name: 'Image' },
      { className: 'bg-thead text-dark', name: 'Name' },
      { className: 'bg-thead text-dark', name: 'SKU' },
      { className: 'bg-thead text-dark', name: 'Price' },
      { className: 'bg-thead text-dark', name: 'Quantity' },
      { className: 'bg-thead text-dark', name: 'Weight' },
      { className: 'bg-thead text-dark', name: 'Attributes' },
      { className: 'bg-thead text-dark text-center', name: 'Action' },
    ],
    [],
  );

  const handleRemoveProduct = (id: string) => {
    dispatch(onChangeProductGenerate(dataProductGenerate.filter((item: any) => item.id !== id)));
    formik.setErrors({});
  };

  const handleChange = (id: string, e: any) => {
    const findProduct = dataProductGenerate.find((item: any) => item.id === id);
    const findIndexProduct = dataProductGenerate.findIndex((item: any) => item.id === id);
    const newList = dataProductGenerate.filter((item: any) => item.id !== id);

    if (!findProduct) {
      return;
    }

    if (findIndexProduct !== -1) {
      newList.splice(findIndexProduct, 0, { ...findProduct, [e.target.name]: e.target.value });
      dispatch(onChangeProductGenerate(newList));
      return;
    }
  };

  const handleUploadImage = async (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;

    if (!file) {
      return;
    }

    try {
      const fileCovert = await convertBase64(file[0]);
      const { type, name } = file[0];
      const newName =
        name.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '').split('.')[0].length === 0 ? uuidv4() : name.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '');

      const event = {
        target: {
          name: e.target.name,
          value: {
            type,
            name: newName,
            label: '',
            file: fileCovert,
          },
        },
      };
      handleChange(id, event);
    } catch (error) {}
  };

  const handleChangeImage = (id: string) => {
    handleChange(id, {
      target: {
        value: null,
        name: `image${id}`,
      },
    });
  };

  const renderInput = (name: string, value: string, id: string, type: 'text' | 'number' = 'text') => {
    return (
      <>
        <input
          type="text"
          title={value}
          className={`py-2 px-3 fs-14 form-control`}
          name={name}
          value={value}
          autoComplete="off"
          aria-autocomplete="none"
          onKeyPress={(e: any) => {
            if (type !== 'number') return;
            e = e || window.event;
            const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);
            const checkDot = e.target.value.includes('.') && charStr === '.';
            (checkDot || !charStr.match(/^[0-9.]+$/)) && e.preventDefault();
          }}
          onPaste={(e: any) => {
            if (type !== 'number') return;
            const valueCopied = e.clipboardData.getData('text/plain').match(/^[0-9]+$/);
            if (!valueCopied) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            handleChange(id, e);
            setFieldTouched(name, false, false);
          }}
          onBlur={handleBlur}
        />
        {errors[name] && touched[name] && <p className="m-0 mt-2 fz-14 text-danger mess-err">{errors[name] as string}</p>}
      </>
    );
  };

  const renderBodyProductWillCreate = () => {
    return dataProductGenerate.map((item: any) => {
      const image = item[`image${item.id}`];
      const valueName = item[`name${item.id}`];
      const valueSku = item[`sku${item.id}`];
      const valuePrice = item[`price${item.id}`];
      const valueQty = item[`qty${item.id}`];
      const valueWeight = item[`weight${item.id}`];
      const listAttr = Object.entries(item[`attributes${item.id}`]).map((item: any, index: number) => {
        const newLabel = item[0].replace(/-id\d+$/g, '');
        return (
          <span className="fs-14" key={index}>
            <span className="fw-medium">{newLabel}: </span>
            {item[1].label}
          </span>
        );
      });

      return (
        <tr key={item.id}>
          <td>
            {image ? (
              <div className="thumbnail d-flex flex-column align-items-center">
                <img src={image.file} alt={image.label} className="rounded" />
                <span className="mt-1 link fs-7 cursor-pointer" onClick={() => handleChangeImage(item.id)}>
                  Remove
                </span>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  name={`image${item.id}`}
                  id={item.id}
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleUploadImage(item.id, e)}
                />
                <label htmlFor={item.id} className="image border rounded d-flex justify-content-center align-items-center cursor-pointer">
                  <FontAwesomeIcon icon={faPlus} />
                </label>
              </>
            )}
          </td>
          <td>{renderInput(`name${item.id}`, valueName, item.id)}</td>
          <td>{renderInput(`sku${item.id}`, valueSku, item.id)}</td>
          <td>{renderInput(`price${item.id}`, valuePrice, item.id, 'number')}</td>
          <td>{renderInput(`qty${item.id}`, valueQty, item.id, 'number')}</td>
          <td>{renderInput(`weight${item.id}`, valueWeight, item.id, 'number')}</td>
          <td>
            <div className="d-flex flex-column gap-2 attr-list-selected">{listAttr}</div>
          </td>
          <td className="text-center align-middle">
            <FontAwesomeIcon icon={faTrash} className="trash cursor-pointer" onClick={() => handleRemoveProduct(item.id)} />
          </td>
        </tr>
      );
    });
  };

  const renderHeader = () => {
    return (
      <thead className="table-head flex-grow-1">
        <tr>
          {tableHeader.map((col, i: number) => (
            <th className={col.className} key={i}>
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderListProductWillCreate = () => {
    return (
      <table id="table-config" className="w-100 table-create-associated">
        {renderHeader()}
        <tbody className="table-body flex-grow-1">{renderBodyProductWillCreate()}</tbody>
      </table>
    );
  };

  return (
    <>
      {listProductAssociated.length > 0 && (
        <div className="mb-4">
          <h3
            className="fs-6 text-primary cursor-pointer d-flex align-items-center m-0"
            onClick={() => setShowListAssociatedProduct((prev) => !prev)}
          >
            <FontAwesomeIcon icon={showListAssociatedProduct ? faChevronDown : faChevronUp} className="me-2" size="1x" />
            Associated Products
          </h3>
          {showListAssociatedProduct && (
            <>
              <p className="mt-3 mb-2 fs-14 fw-medium">You created these products for this configuration.</p>
              <TableProductAssociate />
            </>
          )}
        </div>
      )}

      {dataProductGenerate.length > 0 && (
        <div className="mb-4">
          <h3 className="fs-6 text-primary m-0">New Product Review</h3>
          <p className="mt-3 mb-2 fs-14 fw-medium">Here are the products you're about to create.</p>
          {renderListProductWillCreate()}
        </div>
      )}

      <Button className="btn-done" onClick={formik.handleSubmit} type="button">
        Done
      </Button>
    </>
  );
};

export default Step2;
