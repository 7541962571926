/* eslint-disable react-hooks/exhaustive-deps */
import closeImg from '~/app/images/close.png';
import closeCirImg from '~/app/images/closeCircle.svg';
import arrowImg from '~/app/images/arrow-down.png';
import './style.scss';
import { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import { Status } from '~/app/models';

interface iOptions {
  id: number;
  name: string;
}

interface Props {
  options: iOptions[];
  status?: Status;
  listSelected: any;
  setListSelected: any;
  query: string;
  setQuery: any;
  width?: string;
  sortBy?: string;
  onFocus?: () => void;
  removeSearchWhenSelected?: boolean;
}

const MultiSelect = (props: Props) => {
  const { options, listSelected, setListSelected, query, setQuery, status, width, sortBy, removeSearchWhenSelected, onFocus } = props;

  const [isShowMenuWhenOptionsChange, setIsShowMenuWhenOptionsChange] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuList, setMenuList] = useState<any>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLInputElement>(null);

  useClickOutside(contentRef, () => {
    setShowMenu(false);
    setQuery('');
  });

  useEffect(() => {
    const listNameSelected = listSelected.map((item: any) => item.name);
    const newOptions = options
      .filter((item: any) => !listNameSelected.includes(item.name))
      .sort((f: any, s: any) => {
        const fItem = f[sortBy ?? 'id'];
        const sItem = s[sortBy ?? 'id'];
        if (fItem > sItem) return 1;
        if (fItem < sItem) return -1;
        return 0;
      });
    setMenuList(newOptions);
    isShowMenuWhenOptionsChange && removeSearchWhenSelected && document.activeElement === inputRef.current && setShowMenu(true);
  }, [options]);

  useEffect(() => {
    showMenu && inputRef.current?.focus();
  }, [showMenu]);

  useEffect(() => {
    listSelected.length &&
      isShowMenuWhenOptionsChange &&
      removeSearchWhenSelected &&
      document.activeElement === inputRef.current &&
      setShowMenu(true);
  }, [listSelected]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleSelectedItem = (item: any) => {
    const newMenuList = menuList.filter((itemMenu: any) => itemMenu.id !== item.id);

    setListSelected((pre: any) => [...pre, item]);
    inputRef.current?.focus();
    // Turn off menu to avoid show not "We couldn't find any records"
    removeSearchWhenSelected && setQuery('');
    removeSearchWhenSelected && setShowMenu(false);

    removeSearchWhenSelected && setIsShowMenuWhenOptionsChange(true);
    setMenuList(newMenuList);
  };

  const handleRemoveItemSelected = (item: any) => {
    const index = menuList.findIndex((itemMenu: any) => itemMenu[sortBy ?? 'id'] > item[sortBy ?? 'id']);
    const newListSelected = listSelected.filter((itemMenu: any) => itemMenu.id !== item.id);

    index === -1 ? setMenuList((pre: any) => [...pre, item]) : menuList.splice(index, 0, item);
    setListSelected(newListSelected);
  };

  const handleClearItemSelected = () => {
    setQuery('');
    if (listSelected.length) {
      setMenuList(options);
      setListSelected([]);
    }
  };

  const renderListSelected = () => {
    return listSelected.map((item: any) => {
      const { id, name } = item;
      return (
        <div className="item-selected d-flex align-items-center" key={id}>
          <p className="name fw-normal mb-0">{name}</p>
          <img src={closeCirImg} alt="close" className="cursor-pointer" onClick={() => handleRemoveItemSelected(item)} />
        </div>
      );
    });
  };

  const renderListItem = () => {
    if (status === 'pending') {
      return <p className="item no-item m-0 ms-2 fw-medium py-2 text-center">Loading data. Please wait...</p>;
    }

    if ((!status || status === 'fulfilled') && menuList.length === 0) {
      return <p className="item no-item m-0 ms-2 fw-medium py-2 text-center">We couldn't find any records</p>;
    }

    return menuList.map((item: any) => (
      <div className="item m-0 cursor-pointer py-2 ps-3 d-flex align-items-center" key={item.id} onClick={() => handleSelectedItem(item)}>
        <p className="m-0 fw-normal">{item.name}</p>
      </div>
    ));
  };

  return (
    <div className="custom-select d-flex align-items-center ps-4 flex-grow-1" style={{ width: width }}>
      <img src={closeImg} alt="close" className="cursor-pointer" onClick={handleClearItemSelected} />

      <div className="d-flex align-items-stretch flex-grow-1 py-2" ref={contentRef}>
        <div className="list-selected d-flex align-items-center flex-wrap flex-grow-1 h-100 me-2 ms-4 ps-4">
          {renderListSelected()}
          <input
            ref={inputRef}
            onClick={toggleMenu}
            type="text"
            className="flex-grow-1 h-100"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setShowMenu(true);
            }}
            onFocus={onFocus}
            autoComplete="off"
            placeholder={listSelected.length > 0 ? '' : 'Search here...'}
          />
        </div>
        <div className="drop-menu cursor-pointer d-flex align-items-center" onClick={toggleMenu}>
          <img src={arrowImg} alt="drop-menu" />
        </div>

        {showMenu && <div className="list-item">{renderListItem()}</div>}
      </div>
    </div>
  );
};

export default MultiSelect;
