export type Moth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const d = new Date();
export const CURRENT_YEAR = d.getFullYear();
export const CURRENT_MONTH = `0${d.getMonth() + 1}`.slice(-2);
export const LAST_DAY_OF_MONTH = (CURRENT_MONTH: Moth) => {
  switch (CURRENT_MONTH) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    case 2:
      return CURRENT_YEAR % 4 === 0 ? 29 : 28;
  }
};

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const MONTHS_SHORTHAND = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const datetime = `${DAYS[d.getDay()]}, ${MONTHS[d.getMonth()]} ${d.getDate()}, ${CURRENT_YEAR}`;

export const timeConvert = (timeString: string): string => {
  if (!timeString) return '';
  const arr: any[] = timeString.split(' ');
  const date = arr[0].split('-');
  const time = arr[1].split(':');
  const newTimeValue = `${MONTHS_SHORTHAND[date[1] - 1]} ${date[2]}, ${date[0]}\n ${time[0]}:${time[1]}:${Math.round(+time[2])
    .toString()
    .padStart(2, '0')} ${time[0] - 12 > 0 ? 'PM' : 'AM'}`;

  return newTimeValue;
};

export const timeConvertNotShowHour = (timeString: string): string => {
  if (!timeString) return '';
  const arr: any[] = timeString.split(' ');
  const date = arr[0].split('-');
  const newTimeValue = `${MONTHS_SHORTHAND[date[1] - 1]} ${date[2]}, ${date[0]}`;

  return newTimeValue;
};

export const reportTimeConvert = (timeString: string, type?: string): string => {
  if (!timeString) return '';
  let result: string = '';
  const arr: string[] = timeString.split('-');
  if (!arr) return '';
  if (!type) return (result = `${MONTHS_SHORTHAND[+arr[1] - 1]} ${arr[2]}, ${arr[0]}`);
  if (type === 'day') result = `${MONTHS_SHORTHAND[+arr[1] - 1]} ${arr[2]}, ${arr[0]}`;
  if (type === 'month') result = `${MONTHS_SHORTHAND[+arr[1] - 1]}, ${arr[0]}`;
  if (type === 'year') result = `${arr[0]}`;
  return result;
};

export const getPastDay = (X: number) => {
  let dates = [];
  for (let I = 0; I < Math.abs(X); I++) {
    dates.push(new Date(new Date().getTime() - (X >= 0 ? I : I - I - I) * 24 * 60 * 60 * 1000).toLocaleString('en-US'));
  }
  return dates;
};

export const convertGetPastDay = (str: string): string => {
  if (!str) return '';
  const result = str.split(',')[0];
  return result;
};

export const currentYear = () => {};
