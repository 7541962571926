import { Option } from '../models/filter';

export const productTypeOptions: Option[] = [
  { value: '', label: 'All Type' },
  { value: 'simple', label: 'Simple Product' },
  { value: 'configurable', label: 'Configurable Product' },
  { value: 'virtual', label: 'Virtual Product' },
  // { value: 'grouped', label: 'Grouped Product' },
  // { value: 'bundle', label: 'Bundle Product' },
  // { value: 'downloadable', label: 'Downloadable Product' },
];

export const visibilityOptions: Option[] = [
  { value: '', label: 'All Visibility' },
  { value: '1', label: 'Not Visible Individually' },
  { value: '2', label: 'Catalog' },
  { value: '3', label: 'Search' },
  { value: '4', label: 'Catalog, Search' },
];

export const statusOptions: Option[] = [
  { value: '', label: 'All Status' },
  { value: '1', label: 'Enabled' },
  { value: '2', label: 'Disabled' },
];

export const orderStatusOptions: Option[] = [
  { value: '', label: 'All Status' },
  // { value: 'canceled', label: 'Canceled' },
  // { value: 'closed', label: 'Closed' },
  // { value: 'complete', label: 'Complete' },
  // { value: 'fraud', label: 'Suspected Fraud' },
  // { value: 'holded', label: 'On Hold' },
  // { value: 'payment_review', label: 'Payment Review' },
  // { value: 'paypal_canceled_reversal', label: 'PayPal Canceled Reversal' },
  // { value: 'paypal_reversed', label: 'PayPal Reversal' },
  // { value: 'pending', label: 'Pending' },
  // { value: 'pending_payment', label: 'Pending Payment' },
  // { value: 'pending_paypal', label: 'Pending PayPal' },
  // { value: 'processing', label: 'Processing' },

  { value: 'new', label: 'New' },
  { value: 'preparing', label: 'Preparing' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'completed', label: 'Completed' },
  { value: 'canceled', label: 'Canceled' },
  // { value: 'declined', label: 'Declined' },
];

export const priorityOptions: Option[] = [
  { value: '', label: 'All Priority' },
  { value: '0', label: 'Low' },
  { value: '1', label: 'Medium' },
  { value: '2', label: 'High' },
  { value: '3', label: 'Ugent' },
];

export const ticketStatusOptions: Option[] = [
  { value: '', label: 'All Status' },
  { value: '0', label: 'Disabled' },
  { value: '1', label: 'Enabled' },
];
