import { useRef } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dataModal } from '~/app/constants/dataModal';
import { useClickOutside } from '~/app/hooks/useClickOutSide';

import './style.scss';

interface Props {
  toggleModal: () => void;
}
const TermsAndConditions = (props: Props) => {
  const { toggleModal } = props;

  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, toggleModal);

  const renderTermsList = () => {
    return dataModal.map((item) => (
      <div key={item.title}>
        <h3 className="mb-3 fs-6 fw-medium">{item.title}</h3>
        {item.body.map((item, index) => (
          <p className="fw-normal" key={index} style={{ fontSize: '14px', lineHeight: '17px' }}>
            {item}
          </p>
        ))}
        {item.img && item.img.map((img, index) => <img src={img} className="pb-4" alt="Modal terms" key={index} />)}
      </div>
    ));
  };

  return (
    <div className="modal d-flex justify-content-center align-items-center">
      <div className="modal__terms p-4 m-4 text-center" ref={popupRef}>
        <FontAwesomeIcon className="modal__close-icon cursor-pointer" onClick={toggleModal} icon={faXmark} />
        <h3 className=" modal__title mb-2 fw-semibold">Terms and Conditions</h3>
        <h4 className="modal__sub-title mb-4 fw-semibold">Our Policy</h4>
        <div className="modal__content-wrap text-start">{renderTermsList()}</div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
