import * as types from '.';
import ticketApi from '~/app/apis/ticket.api';
import { iPostAttachment, iPostMessage, iPostQuickAnswer, iSaveTicket } from '~/app/models';
import orderApi from '~/app/apis/order.api';
import { addFilterGroup, addFilterItem, addFilters, getStoreId, searchCriteriaBuilder } from '~/app/utils';
import { defaultMessageError } from '~/app/constants';

const { rest: actions } = types;

export const resetData = () => (dispatch: any) => {
  dispatch(actions.resetData());
};

export const getTicketListing =
  (searchURL: any, accessToken: string, store: string = 'all', cancelController: AbortController | null) =>
  async (dispatch: any) => {
    cancelController && cancelController.abort();
    dispatch(actions.getTicketListingPending());
    const controller = new AbortController();
    dispatch(actions.changeController(controller));
    try {
      const res = await ticketApi.getTicketListing(searchURL, accessToken, store, controller);
      const { data } = res;
      !data ? dispatch(actions.getTicketListingRejected()) : dispatch(actions.getTicketListingFulfilled(data));
    } catch (error: any) {
      if (error.message === 'canceled') return;

      dispatch(actions.getTicketListingRejected());
    }
  };

export const getAllTicket =
  (searchURL: any, accessToken: string, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(actions.getAllTicketPending());
    try {
      const res = await ticketApi.getAllTicket(searchURL, accessToken, store);
      const { data } = res;
      !data ? dispatch(actions.getAllTicketRejected()) : dispatch(actions.getAllTicketFulfilled(data));
    } catch (error: any) {
      if (error.message === 'canceled') return;

      dispatch(actions.getAllTicketRejected());
    }
  };

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(actions.setUrlData(payload));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(actions.setQueryString(q));
};

export const resetProductQueryString = () => (dispatch: any) => {
  dispatch(actions.resetQueryString());
};

export const getListQuickAnswer = (searchURL: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  try {
    const res = await ticketApi.getQuickAnswer(searchURL, accessToken, currentStore);
    const { items } = res.data;
    dispatch(actions.getListQuickAnswer(items));
  } catch (error) {}
};

export const getTicketDetails = (ticketId: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getTicketDetailsPending());
  try {
    let data;
    const { data: dataGetInfoTicket } = await ticketApi.getTicketDetails(ticketId, accessToken, currentStore);
    const { category_id, department_id, order_id } = dataGetInfoTicket;

    // GET department
    if (department_id || category_id) {
      try {
        const { data: dataGetDepartment } = await ticketApi.getDepartmentById(department_id, accessToken, currentStore);
        const { title: department_name } = dataGetDepartment;
        const { data: dataGetCate } = await ticketApi.getCategoryById(category_id, accessToken, currentStore);
        const { title: category_name } = dataGetCate;
        data = { ...dataGetInfoTicket, department_name, category_name };
      } catch (error) {}
    }

    if (order_id) {
      try {
        const { data: dataGetOrderById } = await orderApi.getOrderDetails(
          {
            id: order_id,
            accessToken,
          },
          currentStore,
        );
        dispatch(actions.getOrderByIdFulfilled(dataGetOrderById));
        dispatch(actions.getTicketDetailsFulfilled(data));
      } catch (error) {
        dispatch(actions.getTicketDetailsFulfilled(data));
      }
    } else {
      dispatch(actions.getTicketDetailsFulfilled(data));
    }
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.getTicketDetailsRejected(message || defaultMessageError));
  }
};

export const getMessageByTicketId = (searchURL: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getListMessageByTicketIdPending());
  try {
    const { data } = await ticketApi.getMessageByTicketId(searchURL, accessToken, currentStore);
    const { items: listMessage } = data;
    const listMessageId = listMessage.map((message: any) => message.message_id);

    try {
      const searchAttachmentOfMessageId = searchCriteriaBuilder(
        Number.MAX_VALUE,
        1,
        addFilterGroup(addFilters(addFilterItem('message_id', `${listMessageId.join(',')}`, 'in'))),
      );
      const { data } = await ticketApi.getAttachmentOfMessageId(searchAttachmentOfMessageId, accessToken, currentStore);
      const { items: listAttachment } = data;

      const newListAttachment = listAttachment.filter((attachment: any) => attachment.body);

      dispatch(actions.getListMessageByTicketIdFulfilled(listMessage));
      dispatch(actions.getListAttachmentByMessageId(newListAttachment));
    } catch (error) {
      dispatch(actions.getListMessageByTicketIdRejected());
    }
  } catch (error) {
    dispatch(actions.getListMessageByTicketIdRejected());
  }
};

export const postQuickAnswer =
  (payload: iPostQuickAnswer, accessToken: string, currentStore: string, resetForm: () => void, closePopup: () => void) =>
  async (dispatch: any) => {
    dispatch(actions.postQuickAnswerPending());
    try {
      await ticketApi.postQuickAnswer(payload, accessToken, currentStore);
      resetForm();
      closePopup();
      dispatch(actions.postQuickAnswerFulfilled());
    } catch (error) {
      dispatch(actions.postQuickAnswerRejected());
    }
  };

export const postMessage =
  (
    payloadMessage: iPostMessage,
    payloadAttachment: iPostAttachment | undefined,
    accessToken: string,
    currentStore: string,
    messageEditorRef: React.RefObject<HTMLParagraphElement>,
    ticketId: string,
    resetAttachment: React.Dispatch<any>,
    setDisabledButtonSendMessage: React.Dispatch<boolean>,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.postMessagePending());
    setDisabledButtonSendMessage(true);

    try {
      const { data } = await ticketApi.postMessage(payloadMessage, accessToken, currentStore);

      // Remove text when success
      if (messageEditorRef.current) {
        messageEditorRef.current.innerHTML = '';
      }

      // POST attachment
      if (payloadAttachment) {
        const { message_id } = data;

        const newPayloadAttachment = {
          attachment: {
            ...payloadAttachment?.attachment,
            message_id,
          },
        };

        try {
          await ticketApi.postAttachmentOfMessageId(newPayloadAttachment, accessToken, currentStore);

          // Reset attachment
          resetAttachment(undefined);

          dispatch(actions.postMessageFulfilled());
        } catch (error: any) {
          const { message } = error?.response?.data || {};
          dispatch(actions.postMessageRejected(message || defaultMessageError));
        } finally {
          dispatch(
            getMessageByTicketId(
              searchCriteriaBuilder(Number.MAX_VALUE, 1, addFilterGroup(addFilters(addFilterItem('ticket_id', ticketId, 'eq')))),
              accessToken,
              currentStore,
            ),
          );
        }
      } else {
        dispatch(
          getMessageByTicketId(
            searchCriteriaBuilder(Number.MAX_VALUE, 1, addFilterGroup(addFilters(addFilterItem('ticket_id', ticketId, 'eq')))),
            accessToken,
            currentStore,
          ),
        );

        dispatch(actions.postMessageFulfilled());
      }
    } catch (error) {
      // Allow send messages and attachments
      setDisabledButtonSendMessage(false);
      dispatch(actions.postMessageRejected(defaultMessageError));
    }
  };

export const putTicket = (ticketId: string, payload: any, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.putTicketPending());
  try {
    const { data } = await ticketApi.putTicket(ticketId, payload, accessToken, currentStore);

    dispatch(actions.getTicketDetailsFulfilled(data));
    dispatch(actions.putTicketFulfilled());
  } catch (error) {
    dispatch(actions.putTicketRejected());
  }
};

export const saveTicket = (payload: iSaveTicket, accessToken: string, currentStore: string, storeData: any) => async (dispatch: any) => {
  dispatch(actions.saveTicketPending());
  try {
    const { data } = await ticketApi.saveTicket(payload, accessToken, currentStore);

    dispatch(actions.getTicketDetailsFulfilled(data));
    dispatch(actions.saveTicketFulfilled());

    if (getStoreId(storeData, currentStore)) {
      dispatch(
        getQtyNewTicket(
          searchCriteriaBuilder(
            Number.MAX_VALUE,
            1,
            addFilterGroup(
              addFilters(addFilterItem('is_read', '0', 'eq')),
              addFilters(addFilterItem('store_id', `${getStoreId(storeData, currentStore)}`, 'eq')),
            ),
          ),
          accessToken,
          currentStore,
        ),
      );
    }
  } catch (error) {
    dispatch(actions.saveTicketRejected());
  }
};

export const getQtyNewTicket =
  (searchURL: any, accessToken: string, store: string = 'all') =>
  async (dispatch: any) => {
    try {
      const controller = new AbortController();
      const { data } = await ticketApi.getTicketListing(searchURL, accessToken, store, controller);
      const { total_count } = data;

      dispatch(actions.getQtyNewTicket(total_count || 0));
    } catch (error) {
      dispatch(actions.getQtyNewTicket(0));
    }
  };
