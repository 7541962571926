import { useMemo, useState } from 'react';

import last12Moths from '~/app/utils/last12Moths';
import DefaultLayout from '~/components/layout/DefaultLayout';
import BestSeller from './BestSeller';
import HeaderContent from './HeaderContent';
import Overview from './Overview';
import ProductSold from './ProductSold';
import SalesStatistics from './SalesStatistics';
import TotalSales from './TotalSales';
import { useAppSelector } from '~/app/hooks/hooks';

import OrderReport from './OrderReport';

import './style.scss';

const DashBoard = () => {
  const [viewActionBestSellerId, setViewActionBestSellerId] = useState<number>(1);

  const { statusBestSeller } = useAppSelector((state) => state.dashboardReducer);
  const { queryString: orderQuery } = useAppSelector((s) => s.orderReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);

  const timeLast12Months = useMemo(() => `${last12Moths[0]} - ${last12Moths[last12Moths.length - 1]}`, []);

  const actionsData = useMemo(
    () => [
      {
        id: 1,
        text: '7 days ago',
      },
      {
        id: 2,
        text: 'This Month',
      },
      {
        id: 3,
        text: 'This Year',
      },
    ],
    [],
  );

  const handleChangeView = (id: number) => {
    if (id === viewActionBestSellerId || statusBestSeller === 'pending') {
      return;
    }

    setViewActionBestSellerId(id);
  };

  const renderActionsData = () => {
    return actionsData.map((action) => {
      const { id, text } = action;
      const classNames = `btn-action rounded-pill fs-7 fw-semibold btn mt-3 ${id === viewActionBestSellerId ? 'btn-primary' : 'no-active'}`;

      return (
        <button key={id} className={classNames} onClick={() => handleChangeView(id)}>
          {text}
        </button>
      );
    });
  };

  return (
    <DefaultLayout pageTitle="Dashboard">
      <div className="dashboard">
        <Overview />

        <div className="content w-100">
          <div className="row gy-4">
            <div className="col-sm-12 col-xl-6">
              <TotalSales>
                <HeaderContent title="Total Sales" subtitle={timeLast12Months} />
              </TotalSales>
            </div>

            <div className="col-sm-12 col-xl-6">
              <SalesStatistics>
                <HeaderContent title="Sales Statistics" subtitle={timeLast12Months} />
              </SalesStatistics>
            </div>

            <div className="col-sm-12 col-xl-6 d-flex flex-column">
              <ProductSold>
                <HeaderContent title="Products Sold" subtitle={timeLast12Months} />
              </ProductSold>

              <OrderReport>
                <HeaderContent
                  title="Orders Report"
                  subtitle={timeLast12Months}
                  to={`/orders/${orderQuery}`}
                  textLink="View Order Listing"
                />
              </OrderReport>
            </div>

            <div className="col-sm-12 col-xl-6">
              <div className="best-seller d-flex flex-column h-100">
                <BestSeller
                  id={viewActionBestSellerId}
                  onChangeStore={(id: number) => {
                    handleChangeView(id);
                  }}
                >
                  <HeaderContent
                    title="Bestsellers"
                    subtitle="Top 10 products"
                    to={`/products/${productQuery}`}
                    textLink="View Product Listing"
                  >
                    <div className="actions">{renderActionsData()}</div>
                  </HeaderContent>
                </BestSeller>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DashBoard;
