import { FC } from 'react';
import noRecord from '~/app/images/no-record.png';
import { iTableHead } from '~/app/models';

type Props = {
  tableHeaders: iTableHead[];
  message?: string;
};

export const NoRecord: FC<Props> = (props: Props) => {
  const { tableHeaders, message } = props;
  return (
    <tr className="no-record">
      <td className="text-center border-bottom-0" colSpan={tableHeaders.length}>
        <img className="mt-4" src={noRecord} alt="" />
        <p className="mt-3 fs-6 fw-medium text-dark">{message ? message : "We couldn't find any records."}</p>
      </td>
    </tr>
  );
};
