import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import closeImg from '~/app/images/closeRec.png';
import uploadImg from '~/app/images/upload.png';
import { convertBase64 } from '~/app/utils/convertBase64';
import Button from '~/components/common/Button';
import { onChangeDataVideo, onChangeListImage } from '../../redux/actions';
import './style.scss';
import Video from './Video';
import { v4 as uuidv4 } from 'uuid';
import { iImage, iVideo } from '~/app/models';
import { TextArea } from '~/components/common/Input';

const ImageAndProduct = () => {
  const dispatch = useAppDispatch();
  const { dataImage, dataVideo, type, action, dataProductGenerate, listProductAssociated } = useAppSelector(
    (state) => state.createEditProductReducer,
  );
  const [showImageDetail, setShowImageDetail] = useState<undefined | string>();

  const popupRef = useRef<HTMLDivElement>(null);

  const showSwatch = useMemo(
    () => type !== 'config' && dataProductGenerate.concat(listProductAssociated).length === 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataProductGenerate, listProductAssociated, action, type],
  );

  useEffect(() => {
    const newListImg = dataImage.map((item) => ({ ...item, types: item.types.filter((type) => type !== 'swatch_image') }));
    const newListVideo = dataVideo.map((item) => ({ ...item, types: item.types.filter((type) => type !== 'swatch_image') }));
    dispatch(onChangeListImage(newListImg));
    dispatch(onChangeDataVideo(newListVideo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductGenerate, listProductAssociated, action, type]);

  useClickOutside(popupRef, () => setShowImageDetail(undefined));

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(e.target.files || []);

    const base64List = fileList.map(async (file: any) => {
      const { type, name } = file;
      const newName =
        name.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '').split('.')[0].length === 0 ? uuidv4() : name.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '');

      const fileCovert = await convertBase64(file);
      return {
        type,
        label: '',
        name: newName,
        file: fileCovert,
      };
    });

    Promise.all(base64List).then((result: any) => {
      const newListImg = result.filter((file: any) => file.file.startsWith('data:image'));
      const lengthListImg = [...dataImage, ...dataVideo].length === 0;

      const newListImgRole = newListImg.map((file: any, index: number) => {
        if (lengthListImg && index === 0) {
          const types = ['image', 'small_image', 'thumbnail'];
          return { ...file, types: showSwatch ? [...types, 'swatch_image'] : types };
        } else {
          return { ...file, types: [] };
        }
      });

      e.target.value = '';
      dispatch(onChangeListImage(dataImage.concat(newListImgRole).slice(0, 10)));
    });
  };

  const handleRemoveImage = (index: number) => {
    dispatch(onChangeListImage(dataImage.filter((_, indexImg: number) => indexImg !== index)));
  };

  const handleRemoveAllImage = () => {
    dispatch(onChangeListImage([]));
  };

  const renderListImg = () => {
    return (
      <>
        {dataImage.map((item: any, index: number) => {
          const { file, label, id, types } = item;

          return (
            <div key={index} className="item-select">
              <div>
                <img
                  src={closeImg}
                  alt="close-img"
                  className="remove cursor-pointer"
                  onClick={() => {
                    handleRemoveImage(index);
                  }}
                />
                <img
                  className="image cursor-pointer"
                  src={id ? `${process.env.REACT_APP_IMAGE_CATALOG_URL}${file}` : file}
                  alt={label}
                  onClick={() => setShowImageDetail(index.toString())}
                />
              </div>
              {types?.length > 0 && (
                <div className="d-flex flex-wrap mt-2 gap-1">
                  {types.map((item: any) => (
                    <span key={item} className="fs-8 bg-primary text-white px-3 py-2 rounded">
                      {item === 'image' && 'Base'}
                      {item === 'small_image' && 'Small'}
                      {item === 'thumbnail' && 'Thumbnail'}
                      {item === 'swatch_image' && 'Swatch'}
                    </span>
                  ))}
                </div>
              )}
            </div>
          );
        })}
        {dataImage.length < 10 && (
          <>
            <input type="file" hidden id="multi-img" multiple accept="image/png, image/gif, image/jpeg" onChange={handleUploadImage} />
            <label htmlFor="multi-img" className="upload d-flex flex-column justify-content-center align-items-center gap-3 cursor-pointer">
              <div className="image-wrap">
                <img src={uploadImg} alt="upload-img" className="upload-img" />
                <div></div>
              </div>
              <span>(max 10 files)</span>
            </label>
          </>
        )}
      </>
    );
  };

  const handleChangeRole = (indexImage: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const imgSelect = dataImage[indexImage];
    let newList: Array<iImage | iVideo> = dataImage.filter((_, index: number) => index !== indexImage);

    if (!imgSelect) return;

    if (e.target.checked) {
      newList = [...newList, ...dataVideo].map((item) => ({
        ...item,
        types: item.types.filter((role: any) => role !== e.target.name),
      }));

      const newListVideo = newList.filter((item) => item.media_type === 'external-video');
      dispatch(onChangeDataVideo(newListVideo));

      newList = newList.filter((item) => item.media_type !== 'external-video');

      const newRole = [...imgSelect.types, e.target.name];
      newList.splice(indexImage, 0, { ...imgSelect, types: newRole });
      dispatch(onChangeListImage(newList));
      return;
    } else {
      const newRole = imgSelect.types.filter((type: any) => type !== e.target.name);
      newList.splice(indexImage, 0, { ...imgSelect, types: newRole });
      dispatch(onChangeListImage(newList));
      return;
    }
  };

  const handleChangeAlt = (indexImage: number, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const findImage = dataImage[indexImage];
    const newList = dataImage.filter((_: any, index: number) => index !== indexImage);

    if (findImage) {
      newList.splice(indexImage, 0, { ...findImage, label: e.target.value });
      dispatch(onChangeListImage(newList));
      return;
    }
  };

  const renderRole = (role: string[], indexImage: number) => {
    return (
      <div className="d-flex flex-column gap-3 border p-12 rounded list-role">
        <div className="d-flex align-items-center checkbox-custom">
          <input
            className="form-check-input m-0 cursor-pointer"
            type="checkbox"
            id="image"
            name="image"
            checked={role.includes('image')}
            onChange={(e) => {
              handleChangeRole(indexImage, e);
            }}
          />
          <label htmlFor="image" className="fs-6 fw-normal ms-2 cursor-pointer">
            Base
          </label>
        </div>

        <div className="d-flex align-items-center checkbox-custom">
          <input
            className="form-check-input m-0 cursor-pointer"
            type="checkbox"
            id="small_image"
            name="small_image"
            checked={role.includes('small_image')}
            onChange={(e) => {
              handleChangeRole(indexImage, e);
            }}
          />
          <label htmlFor="small_image" className="fs-6 fw-normal ms-2 cursor-pointer">
            Small
          </label>
        </div>

        <div className="d-flex align-items-center checkbox-custom">
          <input
            className="form-check-input m-0 cursor-pointer"
            type="checkbox"
            name="thumbnail"
            id="thumbnail"
            checked={role.includes('thumbnail')}
            onChange={(e) => {
              handleChangeRole(indexImage, e);
            }}
          />
          <label htmlFor="thumbnail" className="fs-6 fw-normal ms-2 cursor-pointer">
            Thumbnail
          </label>
        </div>

        {showSwatch && (
          <div className="d-flex align-items-center checkbox-custom">
            <input
              className="form-check-input m-0 cursor-pointer"
              type="checkbox"
              id="swatch_image"
              name="swatch_image"
              onChange={(e) => {
                handleChangeRole(indexImage, e);
              }}
              checked={role.includes('swatch_image')}
            />
            <label htmlFor="swatch_image" className="fs-6 fw-normal ms-2 cursor-pointer">
              Swatch
            </label>
          </div>
        )}
      </div>
    );
  };

  const renderPopup = () => {
    const image = dataImage.find((_: any, index: number) => index.toString() === showImageDetail);

    return (
      image && (
        <div className="popup d-flex justify-content-center align-items-center">
          <div className="popup-content wrapper d-flex flex-column position-relative" ref={popupRef}>
            <div className="text-end">
              <div className="close-icon cursor-pointer mb-4 d-inline-flex" onClick={() => setShowImageDetail(undefined)}>
                <FontAwesomeIcon icon={faClose} />
              </div>
              <div className="done-wrap mb-4 py-3 d-flex justify-content-end">
                <Button className="me-3" onClick={() => setShowImageDetail(undefined)}>
                  Done
                </Button>
              </div>
            </div>

            <div className="d-flex">
              <img
                className="image-large rounded me-5"
                src={image.id ? `${process.env.REACT_APP_IMAGE_CATALOG_URL}${image.file}` : image.file}
                alt={image.label ? image.label : image.name?.split('.')[0] || ''}
              />

              <div className="flex-grow-1">
                <h3 className="m-0 mb-3 title fs-6 fw-normal">Alt Text</h3>

                <TextArea
                  className="mb-3"
                  value={image.label ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleChangeAlt(showImageDetail ? +showImageDetail : 0, e);
                  }}
                />

                <h3 className="m-0 mb-3 title fs-6 fw-normal">Role</h3>
                {renderRole(image.types, showImageDetail ? +showImageDetail : 0)}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="img-video wrapper">
      {renderPopup()}
      <div className="d-flex">
        <h3 className="title fs-6 fw-normal m-0 me-4">Add More Image</h3>
        <div className="list-img me-3 d-flex gap-3 flex-wrap">{renderListImg()}</div>
        <span className="link fs-14 ms-auto" onClick={handleRemoveAllImage}>
          Clear all images
        </span>
      </div>
      <div className="d-flex mt-4">
        <h3 className="title fs-6 fw-normal m-0 me-4">Add More Video</h3>
        <div className="list-img">
          <Video />
        </div>
      </div>
    </div>
  );
};

export default ImageAndProduct;
