const last12Moths: string[] = [];

const monthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const d = new Date();
d.setDate(1);
for (let i = 11; i >= 0; i--) {
  last12Moths[i] = monthName[d.getMonth()] + ' ' + d.getFullYear();

  d.setMonth(d.getMonth() - 1);
}

export default last12Moths;
