import { useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { formatMoney } from '~/app/utils';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';

const ORDER_TABLE: iTableHead[] = [
  { name: 'Product', className: 'ps-0 pb-3 fs-14 w-35 fw-semibold' },
  { name: 'Price', className: 'pb-3 fs-14 fw-semibold text-end' },
  { name: 'Qty', className: 'pb-3 fs-14 fw-semibold text-end' },
  { name: 'Subtotal', className: 'pb-3 fs-14 fw-semibold text-end' },
  { name: 'Tax Amount', className: 'pb-3 fs-14 fw-semibold text-end' },
  { name: 'Tax Percent', className: 'pb-3 fs-14 fw-semibold text-end' },
  { name: 'Discount', className: 'pb-3 fs-14 fw-semibold text-end' },
  { name: 'Row Total', className: 'pb-3 fs-14 fw-semibold text-end' },
];

interface Props {
  currentStore: string;
}
export const TableOrdered = ({ currentStore }: Props) => {
  const { status, dataDetails } = useAppSelector((s) => s.orderReducer);
  const { items } = dataDetails;
  const itemsWithoutConfigurable = items.filter((item: any) => item.product_type !== 'configurable');

  const renderDetails = (item: any) => {
    const itemCheck = item.parent_item ? item.parent_item : item;

    return (
      <>
        <td className="vertical-middle fs-14 text-end">{formatMoney(+itemCheck.price_incl_tax, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end text-nowrap">
          <p className="mb-1">{itemCheck.qty_ordered ? `Ordered ${itemCheck.qty_ordered}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_invoiced ? `Invoiced ${itemCheck.qty_invoiced}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_canceled ? `Canceled ${itemCheck.qty_canceled}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_refunded ? `Refunded ${itemCheck.qty_refunded}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_shipped ? `Shipped ${itemCheck.qty_shipped}` : ''}</p>
        </td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(itemCheck.price_incl_tax * itemCheck.qty_ordered, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(itemCheck.tax_amount, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end">{itemCheck.tax_percent}%</td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(itemCheck.discount_amount, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(itemCheck.row_total_incl_tax, currentStore)}</td>
      </>
    );
  };

  const convertDeliveryCode = (data: string[]) => {
    if (!data) return undefined;
    const deliveryString = JSON.parse(JSON.stringify(data));

    const result = deliveryString.map((item: any) => JSON.parse(item));

    return result;
  };

  return (
    <div className="info-table bg-white px-4 py-3 wrapper">
      <p className="mb-4 fs-6 fw-semibold">ITEMS ORDERED</p>
      <Table status={status} dataTableHead={ORDER_TABLE} className="">
        {itemsWithoutConfigurable.length > 0 ? (
          itemsWithoutConfigurable.map((item: any, i: number) => {
            const options = convertDeliveryCode(item?.parent_item?.extension_attributes?.deliverycode);

            return (
              <tr key={i}>
                <td className="ps-0 vertical-middle fs-14">
                  <p
                    className="mb-2 fw-medium"
                    dangerouslySetInnerHTML={{ __html: item?.parent_item?.name ? item?.parent_item?.name : item.name }}
                  />
                  <p className="mb-2 sku-product">SKU: {item.sku}</p>
                  {options &&
                    options.map((option: any, i: number) => (
                      <p key={i} className="mb-0 option-product">
                        {option.label}: {option.value}
                      </p>
                    ))}
                </td>
                {renderDetails(item)}
              </tr>
            );
          })
        ) : (
          <NoRecord tableHeaders={ORDER_TABLE} />
        )}
      </Table>
    </div>
  );
};
