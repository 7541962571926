import productApi from '~/app/apis/product.api';
import * as types from '.';
import {
  iApplyAllStore,
  iCategoryTreeItem,
  iGetAttributesOfAttributeSetId,
  iGetCategories,
  iGetListAttrSet,
  iGetProductDetail,
  iKeyAllowApplyAllStores,
  iProduct,
  iProductLink,
  iResponseListing,
} from '~/app/models';
import { defaultMessageError } from '~/app/constants';
import { AppDispatch } from '~/store';
const { actions } = types;

export const getListAttSet =
  ({ currentStore, searchURL, idProductEdit }: iGetListAttrSet & { idProductEdit?: number }) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.getDataPending());

    try {
      const { data } = await productApi.getListAttrSet({ currentStore, searchURL });

      // if type create -> change attribute set id to fetch attribute listing
      if (!idProductEdit) {
        const listAttributeSetId = data.items.map((item) => item.attribute_set_id);

        dispatch(
          onChangeDataHeader({
            attribute_set_id: listAttributeSetId.find((id) => id === 4) || listAttributeSetId[0],
            status: true,
            tax_class_id: '2',
            quantity_and_stock_status: '1',
          }),
        );
      }
      dispatch(actions.getListAttrSetFulfilled(data.items));
      dispatch(actions.getDataFulfilled());
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(actions.getDataRejected(message || defaultMessageError));
    }
  };

export const getAttrListingProduct =
  ({
    attributeSetId,
    currentStore,
    shouldShowLoadingWhenAttrSetIdChange,
  }: iGetAttributesOfAttributeSetId & { shouldShowLoadingWhenAttrSetIdChange: boolean }) =>
  async (dispatch: AppDispatch) => {
    // First fetch attributes of attribute set show loading but change checkbox attribute set to true
    if (shouldShowLoadingWhenAttrSetIdChange) {
      dispatch(actions.getAttributesOfAttributeSetIdPending());
      dispatch(actions.onRejectLoadingWhenAttrSetChange());
    }

    try {
      const { data } = await productApi.getAttributesOfAttributeSetId({ attributeSetId, currentStore });
      dispatch(actions.getAttributesOfAttributeSetIdFulfilled(data.items));

      // Filter list attribute product config can use to create child products
      const listAttrProductConfig = data.items.filter(
        ({ frontend_input, scope, is_user_defined }) => frontend_input === 'select' && scope === 'global' && is_user_defined,
      );
      dispatch(actions.onChangeListAttrProductConfig(listAttrProductConfig));
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(actions.getAttributesOfAttributeSetIdRejected(message || defaultMessageError));
    }
  };

export const getCategories = (payload: iGetCategories) => async (dispatch: any) => {
  try {
    const { data } = await productApi.getCategories(payload);
    dispatch(actions.getCateListFulfilled(data.items.filter((item) => item.id > 2 && item.is_active === true)));
  } catch (error: any) {}
};

export const getCategoriesTree = (currentStore: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.getCategoriesTreePending());
  try {
    const { data } = await productApi.getCategoriesTree(currentStore);

    // logic filter category enabled
    const handleFilter = (item: iCategoryTreeItem) => item.is_active === true;
    const handleMap = (item: iCategoryTreeItem) => {
      if (item.children_data.length === 0) {
        return { ...item, children_data: [] };
      }

      const recursiveCategoriesTree: iCategoryTreeItem[] = item.children_data.filter(handleFilter).map(handleMap);
      return { ...item, children_data: recursiveCategoriesTree };
    };

    const categoriesTreeActiveAfterRecursive = data.children_data.filter(handleFilter).map(handleMap);
    dispatch(actions.getCategoriesTreeFulfilled(categoriesTreeActiveAfterRecursive));
  } catch (error: any) {
    dispatch(actions.getCategoriesTreeReject());
  }
};

export const getProductList =
  (searchURL: string, accessToken: string, currentStore: string, cancelRequest: AbortController | null) => async (dispatch: any) => {
    cancelRequest && cancelRequest.abort();
    const controller = new AbortController();
    dispatch(actions.changeController(controller));
    dispatch(actions.getProductListPending());
    try {
      const res = await productApi.getProductList(searchURL, currentStore);

      const { items: data } = res.data;
      dispatch(actions.getProductListFulfilled(data));
    } catch (error: any) {
      if (error.message === 'canceled') return;

      dispatch(actions.getProductListRejected());
    }
  };

export const getProductDetail = (payload: iGetProductDetail) => async (dispatch: any) => {
  dispatch(actions.getProductDetailPending());
  try {
    const { data } = await productApi.getProductDetails(payload);

    const { use_default }: { use_default: iKeyAllowApplyAllStores[] } = data.extension_attributes;
    const newListApplyAllStores = Object.fromEntries(use_default.map((item) => [item, true])) as iApplyAllStore;
    dispatch(
      actions.onChangeListApplyAllStores({
        ...newListApplyAllStores,
      }),
    );

    dispatch(actions.getProductDetailFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.getProductDetailRejected(message || 'Something went wrong. Please try again.'));
  }
};

export const getRelatedSelected =
  (searchURL: string, accessToken: string, currentStore: string, product_links: iProductLink[]) => async (dispatch: any) => {
    const productList = [...product_links];
    const listSkuSortASC = productList
      .filter((item) => item.link_type === 'related')
      .sort((a, b) => a.position - b.position)
      .map((item) => item.linked_product_sku);

    try {
      const res = await productApi.getProductList(searchURL, currentStore);

      const { items: data }: iResponseListing<iProduct[]> = res.data;

      const result: iProduct[] = [];

      listSkuSortASC.forEach((sku) => {
        const productFound = data.find((item) => item.sku === sku);

        if (productFound) {
          result.push(productFound);
        }
      });

      dispatch(actions.getDataRelatedSelected(result));
    } catch (error: any) {}
  };

export const getUpSellSelected =
  (searchURL: string, accessToken: string, currentStore: string, product_links: iProductLink[]) => async (dispatch: any) => {
    const productList = [...product_links];
    const listSkuSortASC = productList
      .filter((item) => item.link_type === 'upsell')
      .sort((a, b) => a.position - b.position)
      .map((item) => item.linked_product_sku);

    try {
      const res = await productApi.getProductList(searchURL, currentStore);

      const { items: data }: iResponseListing<iProduct[]> = res.data;

      const result: iProduct[] = [];

      listSkuSortASC.forEach((sku) => {
        const productFound = data.find((item) => item.sku === sku);

        if (productFound) {
          result.push(productFound);
        }
      });

      dispatch(actions.getDataUpSellSelected(result));
    } catch (error: any) {}
  };
export const getCrossSellSelected =
  (searchURL: string, accessToken: string, currentStore: string, product_links: iProductLink[]) => async (dispatch: any) => {
    const productList = [...product_links];
    const listSkuSortASC = productList
      .filter((item) => item.link_type === 'crosssell')
      .sort((a, b) => a.position - b.position)
      .map((item) => item.linked_product_sku);

    try {
      const res = await productApi.getProductList(searchURL, currentStore);

      const { items: data }: iResponseListing<iProduct[]> = res.data;

      const result: iProduct[] = [];

      listSkuSortASC.forEach((sku) => {
        const productFound = data.find((item) => item.sku === sku);

        if (productFound) {
          result.push(productFound);
        }
      });
      dispatch(actions.getDataCrossSellSelected(result));
    } catch (error: any) {}
  };

export const getListProductAssociated = (searchURL: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  try {
    const res = await productApi.getProductList(searchURL, currentStore);

    const { items: data } = res.data;
    dispatch(actions.getListProductAssociated(data));
  } catch (error: any) {}
};

export const deleteProduct = (idProductDelete: number, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.deleteProductPending());

  try {
    const res = await productApi.deleteProduct(idProductDelete, currentStore);
    const { data } = res;
    !data ? dispatch(actions.deleteProductRejected(defaultMessageError)) : dispatch(actions.deleteProductFulfilled());
    setTimeout(() => {
      dispatch(actions.deleteProductInitState());
    }, 500);
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.deleteProductRejected(message || defaultMessageError));
    setTimeout(() => {
      dispatch(actions.deleteProductInitState());
    }, 500);
  }
};

export const actionCreateEditProduct =
  (payload: any, accessToken: string, currentStore: string, idProductEdit?: number, payloadEditAllStore?: any) => async (dispatch: any) => {
    dispatch(actions.createEditProductPending());

    try {
      if (idProductEdit) {
        // Payload need sku
        if (Object.keys(payloadEditAllStore).length > 1) {
          const newPayloadEditAllStore = {
            product: {
              ...payloadEditAllStore,
            },
          };

          try {
            await productApi.editProductAllStore(idProductEdit, newPayloadEditAllStore);
            const { data } = await productApi.editProduct(idProductEdit, payload, currentStore);
            dispatch(actions.createEditProductFulfilled(data.id));
          } catch (error: any) {
            const { message } = error?.response?.data || {};
            dispatch(actions.createEditProductRejected(message || defaultMessageError));
          }
        } else {
          const { data } = await productApi.editProduct(idProductEdit, payload, currentStore);
          dispatch(actions.createEditProductFulfilled(data.id));
        }
      } else {
        const { data } = await productApi.createProduct(payload, currentStore);
        dispatch(actions.createEditProductFulfilled(data.id));
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(actions.createEditProductRejected(message || defaultMessageError));
    }
  };

export const actionCreateEditConfigProduct =
  (
    listProductSimple: any,
    productConfig: any,
    accessToken: string,
    currentStore: string,
    idProductEdit?: number,
    payloadEditAllStore?: any,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.createEditProductPending());

    try {
      const newPayloadConfig = Object.assign({}, productConfig);

      let dataProductParent: iProduct = {} as iProduct;

      if (idProductEdit) {
        // Payload need sku
        if (Object.keys(payloadEditAllStore).length > 1) {
          const newPayloadEditAllStore = {
            product: {
              ...payloadEditAllStore,
            },
          };
          await productApi.editProductAllStore(idProductEdit, newPayloadEditAllStore);
          const { data } = await productApi.editProduct(idProductEdit, newPayloadConfig, currentStore);
          dataProductParent = { ...data };
        } else {
          const { data } = await productApi.editProduct(idProductEdit, newPayloadConfig, currentStore);
          dataProductParent = { ...data };
        }
      } else {
        const { data } = await productApi.createProduct(newPayloadConfig, currentStore);
        dataProductParent = { ...data };
      }

      // Await create sub product
      try {
        const { data } = await productApi.createListSubProduct(listProductSimple, currentStore);
        const { error, success }: { error: { sku: string; message: string }[]; success: string[] } = data;

        // When all sub product errors
        if (error.length > 0 && success.length === 0) {
          let message = '';
          error.forEach((error) => {
            message += `Sku "${error.sku}" got an error: ${error.message}${error.message.endsWith('.') ? '' : '.'}`;
          });

          // Update data to redirect edit page
          dispatch(actions.updateIdProduct(dataProductParent.id));
          dispatch(actions.createSubProductRejected(message));
        }

        if (success.length > 0) {
          let message = '';
          // const configurable_product_links = productConfig.product?.extension_attributes?.configurable_product_links;
          // const configurable_product_options = productConfig.product?.extension_attributes?.configurable_product_options;
          // const listIdSuccess = success.map((item) => item);
          const listSkuSuccess = [...success];

          // const newPayloadConfig = {
          //   product: {
          //     sku: dataProductParent.sku,
          //     extension_attributes: {
          //       configurable_product_links: [...configurable_product_links, ...listIdSuccess],
          //       configurable_product_options: configurable_product_options,
          //     },
          //   },
          // };

          // Await assignment product id of sub product
          try {
            // await Promise.all(
            //   listSkuSuccess.map((sku) =>
            //     productApi.assignProductChildToProductConfig(dataProductParent.sku, { childSku: sku }, accessToken, currentStore),
            //   ),
            // );

            const lengthSkuSuccess = listSkuSuccess.length;
            for (let i = 0; i < lengthSkuSuccess; i++) {
              await productApi.assignProductChildToProductConfig(dataProductParent.sku, { childSku: listSkuSuccess[i] }, accessToken);
            }

            error.length === 0
              ? dispatch(actions.createEditProductFulfilled(dataProductParent.id))
              : dispatch(actions.updateIdProduct(dataProductParent.id));

            // const { data } = await productApi.editProduct(dataProductParent.id, newPayloadConfig, accessToken, currentStore);
          } catch (err: any) {
            const messageErrorWhenAssignIdSubProduct = err?.response?.data?.message;

            error.length === 0
              ? dispatch(actions.createEditProductRejected(messageErrorWhenAssignIdSubProduct))
              : (message += `SKU ${dataProductParent.id} got an error: Something went wrong.`);
          } finally {
            if (error.length > 0) {
              error.forEach((error) => {
                message += `Sku "${error.sku}" got an error: ${error.message}${error.message.endsWith('.') ? '' : '.'}`;
              });

              dispatch(actions.createSubProductRejected(message));
            }
          }
        }
      } catch {
        dispatch(actions.createEditProductRejected(defaultMessageError));
      }
    } catch (error: any) {
      let message = error?.response?.data?.message;

      if (message === 'Sku already exists. Please try a new Sku') {
        message = `Sku "${productConfig.product.sku}" already exists. Please try a new Sku`;
      }
      dispatch(actions.createEditProductRejected(message || defaultMessageError));
    }
  };

export const actionClearMessage = () => (dispatch: any) => {
  dispatch(actions.clearMessage());
};

export const actionReset = () => (dispatch: any) => {
  dispatch(actions.reset());
};

export const onChangeAction = (action: any) => (dispatch: any) => {
  dispatch(actions.onChangeAction(action));
};
export const onChangeType = (type: any) => (dispatch: any) => {
  dispatch(actions.onChangeType(type));
};
export const onChangeDataHeader = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataHeader(newData));
};
export const onChangeListImage = (newData: any[]) => (dispatch: any) => {
  dispatch(actions.onChangeListImage(newData));
};
export const onChangeListRelatedSelected = (newData: any[]) => (dispatch: any) => {
  dispatch(actions.onChangeListRelatedSelected(newData));
};
export const onChangeListUpSellSelected = (newData: any[]) => (dispatch: any) => {
  dispatch(actions.onChangeListUpSellSelected(newData));
};
export const onChangeListCrossSellSelected = (newData: any[]) => (dispatch: any) => {
  dispatch(actions.onChangeListCrossSellSelected(newData));
};
export const onChangeIdCategoriesSelected = (newData: string[]) => (dispatch: any) => {
  dispatch(actions.onChangeIdCategoriesSelected(newData));
};
export const onChangeDataAdvInventory = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataAdvInventory(newData));
};
export const onChangeDataAdvPrice = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataAdvPrice(newData));
};
export const onChangeDataAttr = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataAttr(newData));
};
export const onChangeDataAttrConfigSelected = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeAttrSelect(newData));
};
export const onChangeDataValuesConfigSelected = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataValuesConfigSelected(newData));
};
export const onChangeProductGenerate = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeProductGenerate(newData));
};
export const onChangeListProductAssociated = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeListProductAssociated(newData));
};
export const onChangeListTierPrice = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeListTierPrice(newData));
};
export const onChangeDataVideo = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataVideo(newData));
};
export const onChangeSearchOptimize = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeSearchOptimize(newData));
};
export const onChangeListApplyAllStores = (newData: iApplyAllStore) => (dispatch: any) => {
  dispatch(actions.onChangeListApplyAllStores(newData));
};
export const onChangeProductInWebsites = (newData: number[]) => (dispatch: AppDispatch) => {
  dispatch(actions.onChangeProductInWebsites(newData));
};
export const onChangeCurrentWebsiteId = (currentWebsiteId: number) => (dispatch: AppDispatch) => {
  dispatch(actions.onChangeCurrentWebsiteId(currentWebsiteId));
};
