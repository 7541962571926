import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignInMethodSchema } from '../Schema';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { updatePasswordUser } from '../../redux/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '~/components/common/Button';

type Props = {};

type FormSignInValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const SignInMethod = (props: Props) => {
  const dispatch = useAppDispatch();

  const { accessToken } = useAppSelector((state) => state.authReducer);
  const { updatePasswordStatus, updateStatus, updateAvatarAndBannerStatus } = useAppSelector((state) => state.userReducer);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    register: registerSignInMethod,
    formState: { errors: errorsSignInMethod },
    handleSubmit: handleSubmitSignInMethod,
    reset,
  } = useForm<FormSignInValues>({
    resolver: yupResolver(SignInMethodSchema),
  });

  registerSignInMethod('currentPassword', {
    onChange: (e) => {
      setPassword(e.target.value);
    },
  });

  registerSignInMethod('newPassword', {
    onChange: (e) => {
      setNewPassword(e.target.value);
    },
  });

  registerSignInMethod('confirmNewPassword', {
    onChange: (e) => {
      setConfirmPassword(e.target.value);
    },
  });

  const onSubmitSignIn = (data: any) => {
    const dataPayload = {
      data: {
        current_password: data.currentPassword,
        password: data.newPassword,
        password_confirmation: data.confirmNewPassword,
      },
    };
    dispatch(updatePasswordUser(dataPayload, accessToken));
  };

  useEffect(() => {
    if (
      (updateStatus === 'fulfilled' && updatePasswordStatus !== 'fulfilled') ||
      (updateAvatarAndBannerStatus === 'fulfilled' && updatePasswordStatus !== 'fulfilled')
    ) {
      reset({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updatePasswordStatus, updateAvatarAndBannerStatus, password, newPassword, confirmPassword]);

  const resetUpdate = () => {
    reset({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <>
      <h5 className="mb-3 mt-32 fw-medium">Sign-in Method</h5>
      <div className="card bg-white justify-content-center shadow-sm border-0">
        <div className="card-body d-flex flex-wrap align-items-center p-4">
          <form onSubmit={handleSubmitSignInMethod(onSubmitSignIn)}>
            <div className={`flex-row-fluid`}>
              <div className="row">
                <div className="col-lg-7">
                  <div className="row mb-4 align-items-center">
                    <label htmlFor="" className="col-12 col-md-3 col-lg-5 fs-6 fw-medium text-dark">
                      Current Password
                    </label>
                    <div className="col-12 col-md-8 col-lg-7 position-relative px-3">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className={`form-control form-control-solid fs-6 w-100 ${errorsSignInMethod.currentPassword ? 'is-invalid' : ''}`}
                        {...registerSignInMethod('currentPassword')}
                      />
                      {password.length > 0 && !showPassword && (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="hide-pwd cursor-pointer text-muted fs-6"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                      {password.length > 0 && showPassword && (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="show-pwd cursor-pointer text-muted fs-6"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                      <div className="invalid-feedback">{errorsSignInMethod.currentPassword?.message}</div>
                    </div>
                  </div>
                  <div className="row mb-4 align-items-center">
                    <label htmlFor="" className="col-12 col-md-3 col-lg-5 fs-6 fw-medium text-dark">
                      New Password
                    </label>
                    <div className="col-12 col-md-8 col-lg-7 position-relative px-3">
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        className={`form-control form-control-solid fs-6 w-100 ${errorsSignInMethod.newPassword ? 'is-invalid' : ''}`}
                        {...registerSignInMethod('newPassword')}
                      />
                      {newPassword.length > 0 && !showNewPassword && (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="hide-pwd cursor-pointer text-muted fs-6"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      )}
                      {newPassword.length > 0 && showNewPassword && (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="show-pwd cursor-pointer text-muted fs-6"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      )}
                      <div className="invalid-feedback">{errorsSignInMethod.newPassword?.message}</div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <label htmlFor="" className="col-12 col-md-3 col-lg-5 fs-6 fw-medium text-dark">
                      Confirm New Password
                    </label>
                    <div className="col-12 col-md-8 col-lg-7 position-relative px-3">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className={`form-control form-control-solid fs-6 w-100 ${
                          errorsSignInMethod.confirmNewPassword ? 'is-invalid' : ''
                        }`}
                        {...registerSignInMethod('confirmNewPassword')}
                      />
                      {confirmPassword.length > 0 && !showConfirmPassword && (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="hide-pwd cursor-pointer text-muted fs-6"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      )}
                      {confirmPassword.length > 0 && showConfirmPassword && (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="show-pwd cursor-pointer text-muted fs-6"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      )}
                      <div className="invalid-feedback">{errorsSignInMethod.confirmNewPassword?.message}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="d-flex flex-column h-100">
                    <span className="fw-medium fs-7">Note:</span>
                    <div className="form-text fs-7 text-danger mt-1 mb-auto">
                      Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one
                      number, and one special character.
                    </div>
                    <div className="pt-1 d-flex justify-content-end mt-auto">
                      <button
                        id="password_cancel"
                        type="button"
                        className="btn btn-cancel-password px-1 px-xxl-2 py-1 me-1 me-xxl-2"
                        onClick={() => resetUpdate()}
                      >
                        Cancel
                      </button>
                      <Button className="px-4 py-12" type="submit" disabled={updatePasswordStatus === 'pending'}>
                        {updatePasswordStatus === 'pending' ? (
                          <>
                            Updating
                            <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
                          </>
                        ) : (
                          'Update Password'
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignInMethod;
