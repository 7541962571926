/* eslint-disable react-hooks/exhaustive-deps */
import closeImg from '~/app/images/close.png';
import closeCirImg from '~/app/images/closeCircle.svg';
import arrowImg from '~/app/images/arrow-down.png';
import CheckboxTree from 'react-checkbox-tree';
import './style.scss';
import { useRef, useState } from 'react';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Status } from '~/app/models';

interface Props {
  options: any[];
  status?: Status;
  listSelected: any;
  setListSelected: any;
  width?: string;
  check?: any;
  setCheck?: any;
  expanded?: any;
  setExpanded?: any;
  query?: string;
  setQuery?: any;
}

const MultiSelect = (props: Props) => {
  const { options, listSelected, status, width, check, setCheck, expanded, setExpanded, query, setQuery } = props;

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLInputElement>(null);

  useClickOutside(contentRef, () => {
    setShowMenu(false);
    setQuery('');
    setExpanded([]);
  });

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const renderListSelected = () => {
    if (listSelected.length === 0) {
      return;
    }

    return listSelected.map((item: any, index: number) => {
      const { id, name } = item;
      return (
        <div className="item-selected d-flex align-items-center" key={index}>
          <p className="name fw-normal mb-0">{name}</p>
          <img
            src={closeCirImg}
            alt="close"
            className="cursor-pointer"
            onClick={() => setCheck((prev: any) => prev.filter((item: any) => item !== id.toString()))}
          />
        </div>
      );
    });
  };

  const renderListItem = () => {
    if (status === 'pending') {
      return <p className="item no-item m-0 ms-2 fw-medium py-2 text-center">Loading data. Please wait...</p>;
    }

    if (options.length === 0) {
      return <p className="item no-item m-0 ms-2 fw-medium py-2 text-center">We couldn't find any records</p>;
    }

    if (query) {
      return options.map((item, index: number) => (
        <div
          key={index}
          className="item m-0 cursor-pointer py-2 ps-3 d-flex align-items-center"
          onClick={() => {
            setCheck((prev: any) => [...prev, item.id.toString()]);
            setQuery('');
          }}
        >
          <p className="m-0 fw-normal">{item.name}</p>
        </div>
      ));
    }

    return (
      <CheckboxTree
        nodes={options}
        checked={check}
        onCheck={(checked) => setCheck(checked)}
        expanded={expanded}
        onExpand={(expanded) => setExpanded(expanded)}
        icons={{
          expandClose: <FontAwesomeIcon icon={faChevronRight} />,
          expandOpen: <FontAwesomeIcon icon={faChevronDown} />,
        }}
        noCascade
      />
    );
  };

  return (
    <div className="custom-select d-flex align-items-center ps-4 flex-grow-1" style={{ width: width }}>
      <img src={closeImg} alt="close" className="cursor-pointer" onClick={() => setCheck([])} />

      <div className="d-flex align-items-stretch flex-grow-1 py-2" ref={contentRef}>
        <div className="list-selected d-flex align-items-center flex-wrap flex-grow-1 h-100 me-2 ms-4 ps-4">
          {renderListSelected()}
          <input
            ref={inputRef}
            onClick={toggleMenu}
            type="text"
            className="flex-grow-1 h-100"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setShowMenu(true);
            }}
            autoComplete="off"
            placeholder={listSelected.length > 0 ? '' : 'Search here...'}
          />
        </div>
        <div className="drop-menu cursor-pointer d-flex align-items-center" onClick={toggleMenu}>
          <img src={arrowImg} alt="drop-menu" />
        </div>

        {showMenu && <div className="list-item">{renderListItem()}</div>}
      </div>
    </div>
  );
};

export default MultiSelect;
